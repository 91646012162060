import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import debounce from 'lodash/debounce'
import {
  resultsProviderActions,
  selectChampionshipsMenuFilter,
  selectFilterIsLiveResults,
  selectResultsFilterChampionshipId,
  selectResultsFilterDate
} from 'astra-core/containers/ResultsProvider'

import { EInputSize, EInputView } from 'shared/ui/Input'
import { IconSearch } from 'shared/ui/Icon/General/IconSearch'
import {
  defaultSelectAllChampionships,
  getReactSelectValue
} from 'shared/lib/results'
import { ESelectView } from 'shared/ui/Select'
import { CustomButtonRange, DatePicker } from 'shared/ui/DatePicker'
import { Checkbox } from 'shared/ui/checkbox'

import { RESULTS_DATE_FORMAT } from '../constants'

import {
  StyledCheckboxText,
  StyledCheckboxWrapper,
  StyledHeaderWrapper,
  StyledLive,
  StyledSearchInput,
  StyledSelect,
  StyledSelectWrapper
} from './Header.styled'

export const Header = () => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const currentChampionshipId = useSelector(selectResultsFilterChampionshipId)
  const isLiveResults = useSelector(selectFilterIsLiveResults)
  const date = useSelector(selectResultsFilterDate)

  const setSearchResultsFilter = useCallback(
    (event) => {
      dispatch(
        resultsProviderActions.setSearchResultsFilter({
          text: event.target.value
        })
      )
    },
    [dispatch]
  )

  const handleSearchResults = useMemo(
    () =>
      debounce(setSearchResultsFilter, 500, { leading: true, trailing: true }),
    [setSearchResultsFilter]
  )

  // Stop the invocation of the debounced function
  // after unmounting
  useEffect(() => {
    return () => {
      handleSearchResults.cancel()
    }
  }, [handleSearchResults])

  const handleFilter = useCallback(
    (selectedOption) => {
      dispatch(
        resultsProviderActions.setChampionshipFilter({
          championshipId: selectedOption.value
        })
      )
    },
    [dispatch]
  )

  const handleChangeDateFilter = useCallback(
    (selectedDate) => {
      dispatch(resultsProviderActions.setDateFilter({ date: selectedDate }))
    },
    [dispatch]
  )

  const handleChangeIsLiveResultsFilter = useCallback(() => {
    dispatch(resultsProviderActions.toggleIsLiveEventsFilter())
  }, [dispatch])

  const championships = useSelector(selectChampionshipsMenuFilter)

  // TODO move it to useSelector
  const selectOptions = useMemo(() => {
    return [defaultSelectAllChampionships(t), ...championships]
  }, [championships, t])

  const selectValue = useMemo(
    () =>
      getReactSelectValue({
        championships,
        championshipId: currentChampionshipId,
        t
      }),
    [championships, t, currentChampionshipId]
  )

  const onResetDatePicker = useCallback(() => {
    dispatch(resultsProviderActions.resetDatePickerRangeValues())
    dispatch(resultsProviderActions.fetchResults())
  }, [dispatch])

  return (
    <StyledHeaderWrapper>
      <StyledSelectWrapper>
        <StyledSelect
          getOptionLabel={(e) => (
            <>
              {e.label}
              {e.isLive && <StyledLive>{t('Live')}</StyledLive>}
            </>
          )}
          isSearchable={false}
          options={selectOptions}
          value={selectValue}
          view={ESelectView.Results}
          onChange={handleFilter}
        />
      </StyledSelectWrapper>

      <DatePicker
        customInput={
          <CustomButtonRange
            singleDateFormat={RESULTS_DATE_FORMAT}
            startDate={date}
            isSingleDate
            onReset={onResetDatePicker}
          />
        }
        maxDate={new Date()}
        selected={date}
        onChange={handleChangeDateFilter}
      />
      <StyledCheckboxWrapper>
        <Checkbox
          checked={isLiveResults}
          onChange={handleChangeIsLiveResultsFilter}
        />
        <StyledCheckboxText>{t('current only')}</StyledCheckboxText>
      </StyledCheckboxWrapper>
      <StyledSearchInput
        customSize={EInputSize.M}
        icon={IconSearch}
        placeholder={t('search')}
        view={EInputView.SECONDARY}
        onChange={handleSearchResults}
      />
      {/* <StyledCollapseAllText onClick={handleToggleCollapseAllText}>
        {buttonToggleCollapseText}
      </StyledCollapseAllText> */}
    </StyledHeaderWrapper>
  )
}
