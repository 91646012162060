import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import {
  StyledEmptyPaymentListText,
  StyledEmptyPaymentListWrapper
} from './EmptyPaymentList.styled'
import { EmptyPaymentListProps } from './EmptyPaymentList.types'

export const EmptyPaymentList: FC<EmptyPaymentListProps> = ({ text }) => {
  const [t] = useTranslation()

  return (
    <StyledEmptyPaymentListWrapper>
      <StyledEmptyPaymentListText>{t(text)}</StyledEmptyPaymentListText>
    </StyledEmptyPaymentListWrapper>
  )
}
