import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { IconChevronLeft } from 'shared/ui/Icon/General/IconChevronLeft'
import { ERoutes } from 'shared/types/routes'
import { EPBTabs } from 'pages/page-promotion-and-bonuses/PromotionsAndBonuses/PromotionsAndBonuses.types'

import {
  StyledPromotion,
  StyledPromotionHead,
  StyledPromotionTitle
} from './Promotion.styled'
import { IUseLocationPromotionState } from './Promotion.types'
import { BonusCoupons, PromotionsAndBonuses } from './components'

const promotionBody = (state: IUseLocationPromotionState) => {
  if (state.currentTab === EPBTabs.PROMOTIONS) {
    return <PromotionsAndBonuses />
  } else if (state.currentTab === EPBTabs.BONUS_COUPONS) {
    return <BonusCoupons />
  } else {
    return <></>
  }
}

export const Promotion = memo(() => {
  const [t] = useTranslation()
  const location = useLocation()
  // TODO move currentTab to store redux or context
  const state = (location.state as IUseLocationPromotionState) || {}

  return (
    <StyledPromotion>
      <StyledPromotionHead to={ERoutes.Promotions}>
        <IconChevronLeft />
        <StyledPromotionTitle>{t('back to stock list')}</StyledPromotionTitle>
      </StyledPromotionHead>

      {promotionBody(state)}
    </StyledPromotion>
  )
})
