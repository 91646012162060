import React from 'react'

import { Layout } from 'widgets/Layout'
import { ELayoutsList } from 'widgets/Layout/Layout.types'

import { OfflinePage } from './OfflinePage/OfflinePage'

export const PageOffline = () => (
  <Layout type={ELayoutsList.ERROR}>
    <OfflinePage />
  </Layout>
)
