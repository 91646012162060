import {
  PaymentFormData,
  PAYMENT_METHODS,
  REG_PHONE_NUMBER
} from 'astra-core/containers/BalanceProvider'
import * as Yup from 'yup'
import { LayoutTheme } from 'betweb-openapi-axios'
import { EMAIL_VALIDATION_FORMAT_REGEX } from 'astra-core'

export const FORM_PAYMENT_FORMIK_SCHEMA = (
  min: number,
  max: number,
  type: PAYMENT_METHODS,
  minAmountError: string,
  maxAmountError: string,
  withInput: boolean
) =>
  Yup.object().shape({
    amount: withInput
      ? Yup.number()
          .min(min, minAmountError)
          .max(max, maxAmountError)
          .integer('accountBalance errors integer')
          .required('required')
      : Yup.number(),
    ...(type === PAYMENT_METHODS.MOBILE_PHONE && {
      [PAYMENT_METHODS.MOBILE_PHONE]: Yup.string()
        .matches(REG_PHONE_NUMBER, 'phone is not valid')
        .required('required')
    }),
    ...(type === PAYMENT_METHODS.WEB_MONEY && {
      [PAYMENT_METHODS.WEB_MONEY]: Yup.string().required('required')
    }),
    ...(type === PAYMENT_METHODS.CRYPTOCURRENCY && {
      [PAYMENT_METHODS.CRYPTOCURRENCY]: Yup.string().required('required')
    })
  })

export const FORM_WITHDRAW_FORMIK_SCHEMA = (
  min: number,
  max: number,
  methods: PAYMENT_METHODS[],
  minAmountError: string,
  maxAmountError: string
) =>
  Yup.object().shape({
    amount: Yup.number()
      .min(min, minAmountError)
      .max(max, maxAmountError)
      .integer('accountBalance errors integer')
      .required('required'),
    ...methods.reduce((acc, cur) => ({ ...acc, ...FORMIK_SCHEMAS[cur] }), {})
  })

const FORMIK_SCHEMAS = {
  [PAYMENT_METHODS.WEB_MONEY]: {
    [PAYMENT_METHODS.WEB_MONEY]: Yup.string().required('required')
  },
  [PAYMENT_METHODS.MOBILE_PHONE]: {
    [PAYMENT_METHODS.MOBILE_PHONE]: Yup.string()
      .matches(REG_PHONE_NUMBER, 'phone is not valid')
      .required('required')
  },
  [PAYMENT_METHODS.EMAIL]: {
    [PAYMENT_METHODS.EMAIL]: Yup.string()
      .matches(EMAIL_VALIDATION_FORMAT_REGEX, 'invalid email format')
      .required('required')
  },
  [PAYMENT_METHODS.CRYPTOCURRENCY]: {
    [PAYMENT_METHODS.CRYPTOCURRENCY]: Yup.string().required('required')
  }
}

export const PAYMENT_TITLE_TEXT = {
  [PAYMENT_METHODS.CARD]: 'payment amount',
  [PAYMENT_METHODS.CRYPTOCURRENCY]: 'settlement currency',
  [PAYMENT_METHODS.MOBILE_PHONE]: 'enter phone number',
  [PAYMENT_METHODS.WEB_MONEY]: 'enter your WMZ wallet number'
}

export const formikInitialValues = (
  formData: PaymentFormData
): PaymentFormData => ({
  amount: formData.amount,
  [PAYMENT_METHODS.MOBILE_PHONE]: formData.phone,
  [PAYMENT_METHODS.CARD]: formData.card,
  [PAYMENT_METHODS.EMAIL]: formData.email,
  [PAYMENT_METHODS.WEB_MONEY]: formData.webMoney,
  [PAYMENT_METHODS.CRYPTOCURRENCY]: formData.cryptocurrency
})

export const LOADER_POINTS_COLORS = {
  [LayoutTheme.Light]: { start: '#FFFFFF', end: '#000000' },
  [LayoutTheme.Dark]: { start: '#000000', end: '#FFFFFF' }
}
