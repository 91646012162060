import { useSelector } from 'react-redux'
import { selectConfig } from 'astra-core/containers/ConfigProvider'
import { selectUserSettingLayoutTheme } from 'astra-core/containers/SettingsProvider'

export const useAboutPageLogoUrl = () => {
  const config = useSelector(selectConfig)

  const typeTheme = useSelector(selectUserSettingLayoutTheme)

  return `${config.CDN_URL}/logo/${
    config.CLIENT_TYPE
  }/${typeTheme.toLowerCase()}/${config.LOGO_URL}`
}
