export interface IMark {
  type: 'live' | 'bonus'
  float?: string
}

export interface IStyledWrapper {
  float?: string
}

export enum EMarkComponents {
  live = 'live',
  bonus = 'bonus'
}
