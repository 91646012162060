import { getCurrentLng } from 'astra-core/utils'
import qs from 'qs'

export const getIframeData = ({
  srcData: { token, customerId, layoutTheme, launchUrl }
}) => {
  const langCode = getCurrentLng()

  const params = qs.stringify({
    token,
    customerId,
    layoutTheme,
    langCode
  })

  const src = `${launchUrl}?${params}`

  return {
    id: 'casino-iframe',
    styles: {
      width: '100%',
      height: '100%',
      border: 'none',
      outline: 'none',
      font: 'inherit',
      verticalAlign: 'baseline'
    },
    src
  }
}
