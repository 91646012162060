import { createSelector } from '@reduxjs/toolkit'

import { RootState } from 'shared/types/store'

import { initialState } from './slice'

export const getFavouriteEventsFilters = (state: RootState) =>
  state.favouriteEventsContainer.filters || initialState.filters

export const selectFavouriteEventsFilters = createSelector(
  [getFavouriteEventsFilters],
  (filters) => filters
)
