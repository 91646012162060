import { AppliedCouponStatus } from 'betweb-openapi-axios'
import { TFunction } from 'i18next'

export const getLocaleByStatus = (t: TFunction, couponDate: string) => ({
  [AppliedCouponStatus.Expired]: t('expired'),
  [AppliedCouponStatus.Unknown]: t('expired'),
  [AppliedCouponStatus.RestrictionFailed]: t('expired'),
  [AppliedCouponStatus.Used]: t('has been used //todo'),
  [AppliedCouponStatus.Created]: `${t('valid until')} ${couponDate}`
})
