import React, { memo, useMemo, useState } from 'react'

import { StyledPromotions } from './PromotionsAndBonuses.styled'
import { PromotionsAndBonusesBody, PromotionsTabs } from './components'
import { EPBTabs } from './PromotionsAndBonuses.types'
import { BonusCouponsBody } from './components/BonusCouponsBody'

const PromotionsAndBonusesList = {
  [EPBTabs.PROMOTIONS]: PromotionsAndBonusesBody,
  [EPBTabs.BONUSES]: PromotionsAndBonusesBody,
  [EPBTabs.BONUS_COUPONS]: BonusCouponsBody
}

export const PromotionsAndBonuses = memo(() => {
  const [currentTab, setCurrentTab] = useState(EPBTabs.PROMOTIONS)
  const Component = useMemo(
    () => PromotionsAndBonusesList[currentTab],
    [currentTab]
  )

  return (
    <StyledPromotions>
      <PromotionsTabs currentTab={currentTab} setCurrentTab={setCurrentTab} />
      <Component />
    </StyledPromotions>
  )
})
