import React from 'react'

import { BalanceReport, BetsHistorySources, History } from './components'
import { StyledWrapper } from './BetsHistory.styled'

export const BetsHistory = () => (
  <StyledWrapper>
    <BetsHistorySources />
    <BalanceReport />
    <History />
  </StyledWrapper>
)
