import React, { FC, memo, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  selectFetchItemsLoading,
  selectSearchEventsGroupedBySportId,
  selectSearchEventsItems,
  selectSearchEventsSportList
} from 'astra-core/containers/SearchProvider'
import {
  ESportsCodes,
  selectSport
} from 'astra-core/containers/CommonDataProvider'
import { useGroupedEvents } from 'astra-core/hooks/useEvents'
import groupBy from 'lodash/groupBy'

import { RootState } from 'shared/types/store'
import { SportIcon } from 'shared/ui/Icon/SportIcon'
import { LoaderPoints } from 'shared/ui/LoaderPoints'
import {
  ClearResultSearch,
  TableBetsHead,
  TableBetsRow
} from 'pages/page-live-events/Line/components/ui'

import { StyledSearchClearList } from '../SearchList/SearchList.styled'
import { SearchSportList } from '../SearchSportList'

import { EventsGroupProps, EventsTableProps } from './SearchEvents.types'
import {
  StyledSearchEventsGroups,
  StyledSearchEventsName,
  StyledSearchEventsWrapper,
  StyledSearchTableBetsHead
} from './SearchEvents.styled'

const EventsTable: FC<EventsTableProps> = ({ events, isFirst }) => {
  const eventsGroup = groupBy(events, 'live')

  const liveEvents = eventsGroup.true || []
  const lineEvents = eventsGroup.false || []

  return (
    <>
      <StyledSearchTableBetsHead>
        <TableBetsHead
          isShowOdds={isFirst}
          tournament={events[0]?.tournament}
        />
      </StyledSearchTableBetsHead>
      {liveEvents.map((event) => (
        <TableBetsRow event={event} key={event.id} />
      ))}
      {lineEvents.map((event) => (
        <TableBetsRow event={event} key={event.id} />
      ))}
    </>
  )
}

const SearchEventsGroups: FC<EventsGroupProps> = memo(
  ({ eventsGroup, isFirst }) => {
    const { sportId, events } = eventsGroup
    const { code: sportCode, name: sportName } =
      useSelector((state: RootState) => selectSport(state, +sportId)) || {}

    const Icon = useMemo(
      () => <SportIcon sport={sportCode as ESportsCodes} isActive />,
      [sportCode]
    )

    return (
      <StyledSearchEventsGroups>
        <StyledSearchEventsWrapper>
          {Icon}
          <StyledSearchEventsName> {sportName}</StyledSearchEventsName>
        </StyledSearchEventsWrapper>
        <EventsTable events={events} isFirst={isFirst} />
      </StyledSearchEventsGroups>
    )
  }
)

export const SearchEvents: FC = memo(() => {
  const [sportId, setSportId] = useState<number>(0)
  const items = useSelector(selectSearchEventsItems)
  const sportList = useSelector(selectSearchEventsSportList)
  const eventsGroups = useGroupedEvents(items)
  const loading = useSelector(selectFetchItemsLoading)

  const groupedBySportId = useSelector(() =>
    selectSearchEventsGroupedBySportId(eventsGroups)
  )

  const listEvents = sportId ? groupedBySportId[sportId] : eventsGroups

  if (loading) {
    return (
      <StyledSearchClearList>
        <LoaderPoints />
      </StyledSearchClearList>
    )
  }

  if (!items.length) {
    return (
      <StyledSearchClearList>
        <ClearResultSearch />
      </StyledSearchClearList>
    )
  }

  return (
    <>
      <SearchSportList
        setSportId={setSportId}
        sportId={sportId}
        sportList={sportList}
      />
      {listEvents?.map((eventsGroup, index) => (
        <SearchEventsGroups
          eventsGroup={eventsGroup}
          isFirst={!!index}
          key={eventsGroup.id}
        />
      ))}
    </>
  )
})
