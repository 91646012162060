import React from 'react'

import { Layout } from 'widgets/Layout'
import { ELayoutsList } from 'widgets/Layout/Layout.types'

import { Results } from './Results'

export const PageResults = () => (
  <Layout pageTitle={{ text: 'results' }} type={ELayoutsList.RESULTS}>
    <Results />
  </Layout>
)
