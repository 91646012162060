export const removeAllChildren = (domNode: HTMLElement) => {
  console.log('domNode.firstChild', domNode.firstChild)
  console.log('domNode.firstElementChild', domNode.firstElementChild)
  while (domNode.firstElementChild) {
    console.log('---------------removeChild', domNode.firstElementChild) // todo remove after debug
    domNode.removeChild(domNode.firstElementChild)
  }
  setTimeout(() => {
    // todo remove after debug
    console.log('domNode.firstChild', domNode.firstChild)
    console.log('domNode.firstElementChild', domNode.firstElementChild)
  }, 0)
}
