import React from 'react'

import { Layout } from 'widgets/Layout'
import { ELayoutsList } from 'widgets/Layout/Layout.types'

import { BetsHistoryPageContainer } from './BetsHistoryPageContainer'

export const PageBetsHistory = () => {
  return (
    <Layout pageTitle={{ text: 'history bets' }} type={ELayoutsList.PROFILE}>
      <BetsHistoryPageContainer />
    </Layout>
  )
}
