import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconShowEye: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M1.65676 7.11734C3.02876 5.15534 5.53327 2.44556 8.03711 2.44556C10.5458 2.44556 13.0307 5.16575 14.3834 7.12864C14.7749 7.69681 14.79 8.4386 14.4043 9.01076C13.1178 10.9191 10.6333 13.5332 8.03711 13.5332C5.44613 13.5332 2.94235 10.9295 1.63614 9.0222C1.24068 8.44476 1.25569 7.6909 1.65676 7.11734ZM9.76777 9.76777C10.7441 8.79146 10.7441 7.20854 9.76777 6.23223C8.79146 5.25592 7.20854 5.25592 6.23223 6.23223C5.25592 7.20854 5.25592 8.79146 6.23223 9.76777C7.20854 10.7441 8.79146 10.7441 9.76777 9.76777Z"
        fill="#B3B3B3"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
