import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  balanceProviderActions,
  selectPaymentHistory,
  selectWithdrawalHistory
} from 'astra-core/containers/BalanceProvider'

import { Tabs, TabsItem } from 'shared/ui/Tabs'
import { IconMore } from 'shared/ui/Icon/General/IconMore'
import { Button, EButtonViews } from 'shared/ui/Button'

import { StyledFilter, StyledHistoryWrapper } from './History.styled'
import { Table } from './components/Table'

enum HistoryType {
  Payment = 'payment',
  Withdrawal = 'withdrawal'
}

export const History = () => {
  const [t] = useTranslation()
  const dispatch = useDispatch()

  const [current, setCurrent] = useState<HistoryType>(HistoryType.Payment)

  const paymentHistory = useSelector(selectPaymentHistory)
  const withdrawalHistory = useSelector(selectWithdrawalHistory)

  useEffect(() => {
    if (current === HistoryType.Payment) {
      dispatch(balanceProviderActions.fetchPayments({}))
    } else if (current === HistoryType.Withdrawal) {
      dispatch(balanceProviderActions.fetchWithdrawals({}))
    }
  }, [dispatch, current])

  return (
    <StyledHistoryWrapper>
      <StyledFilter>
        <Tabs variant={2}>
          {/* <TabsItem active>{t('all')}</TabsItem> */}
          <TabsItem
            active={current === HistoryType.Payment}
            handleTabChange={() => setCurrent(HistoryType.Payment)}
          >
            {t('payment')}
          </TabsItem>
          <TabsItem
            active={current === HistoryType.Withdrawal}
            handleTabChange={() => setCurrent(HistoryType.Withdrawal)}
          >
            {t('withdrawal')}
          </TabsItem>
          {/* <TabsItem>{t('in processing')}</TabsItem> */}
        </Tabs>
        <Button icon={IconMore} view={EButtonViews.NONE} />
      </StyledFilter>
      <Table
        data={
          current === HistoryType.Payment
            ? paymentHistory.items
            : withdrawalHistory.items
        }
      />
    </StyledHistoryWrapper>
  )
}
