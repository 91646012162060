import styled from 'styled-components'

import { IconMoney } from 'shared/ui/Icon/General/IconMoney'

import { StyledCell } from '../Cell.styled'

export const CellEventBetCountIcon = styled(IconMoney)`
  margin-right: 8px;
`
export const StyledCellEventNum = styled(StyledCell)`
  width: 0;
`

export const StyledCellEventTitle = styled(StyledCell)`
  padding-left: 0;
  padding-right: 10px;
  width: 0;
`

export const StyledCellEventBetCount = styled(StyledCell)`
  align-items: center;
  box-sizing: initial;
  display: flex;
  padding-left: 0;
  white-space: nowrap;
  width: 100%;
`

export const StyledBetAmount = styled.div`
  align-items: center;
  display: flex;
  gap: 2px;
`
