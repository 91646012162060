import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { PROMOTIONS } from 'pages/page-promotion-and-bonuses/PromotionsAndBonuses/constants'

import { EPBTabs } from '../BonusCouponsBody/BonusCouponsBody.types'

import {
  StyledItem,
  StyledItemBanner,
  StyledItemBannerImg,
  StyledItemDescription,
  StyledItemDescriptionLabel,
  StyledItemDescriptionMore,
  StyledItemDescriptionText,
  StyledItemDescriptionValue,
  StyledPromotionsAndBonusesBody
} from './PromotionsAndBonusesBody.styled'

const Item = ({ item }) => {
  const [t] = useTranslation()
  const { pathname } = useLocation()

  return (
    <StyledItem>
      <StyledItemBanner>
        <StyledItemBannerImg alt={item.label} src={item.src.medium} />
      </StyledItemBanner>

      <StyledItemDescription>
        <StyledItemDescriptionText>
          <StyledItemDescriptionLabel>{item.label}</StyledItemDescriptionLabel>
          <StyledItemDescriptionValue>{item.value}</StyledItemDescriptionValue>
        </StyledItemDescriptionText>
        <StyledItemDescriptionMore
          to={{
            pathname: `${pathname}/${item.id}`,
            state: { currentTab: EPBTabs.PROMOTIONS }
          }}
        >
          {t('more')}
        </StyledItemDescriptionMore>
      </StyledItemDescription>
    </StyledItem>
  )
}

export const PromotionsAndBonusesBody = memo(() => (
  <StyledPromotionsAndBonusesBody>
    {PROMOTIONS.map((item) => (
      <Item item={item} key={item.id} />
    ))}
  </StyledPromotionsAndBonusesBody>
))
