import styled from 'styled-components'

export const StyledHistoryWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  padding-top: 16px;
`
export const StyledFilter = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr auto;
  margin-bottom: 16px;
`
