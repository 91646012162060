import React from 'react'

import { Mark } from '../../Mark'

import { StyledCellBonus, StyledCellBonusText } from './CellBonus.styled'

export const CellBonusMark = ({ value }) => (
  <StyledCellBonus alignData="end">
    {value && <Mark type="bonus" />}
  </StyledCellBonus>
)

export const CellBonusCount = ({ value }) => (
  <StyledCellBonus alignData="end" width={28}>
    {value && <StyledCellBonusText>{value.toFixed(2)}</StyledCellBonusText>}
  </StyledCellBonus>
)
