import React, { FC } from 'react'

import { Layout } from 'widgets/Layout'
import { ELayoutsList } from 'widgets/Layout/Layout.types'

import { Error404Page } from './Error404Page'

export const Page404: FC = () => (
  <Layout type={ELayoutsList.ERROR}>
    <Error404Page />
  </Layout>
)
