import React, { FC } from 'react'
import { BetType } from 'betweb-openapi-axios'
import { formatAmountLocaleString } from 'astra-core'

import { ITableCellBet } from '../../../Table.types'

import { StyledCellCoefficient } from './Coefficient.styled'

export const CellCoefficient: FC<ITableCellBet> = ({ bet }) => {
  const isHideCoefficientForTypes = {
    [BetType.System]: bet.betType === BetType.System
    // replace with that after fix coefficients for SYSTEM
    // (bet.betType === BetType.System && bet.coefficient === 0) ||
    // getIsBetWithCurrentSystem(bet)
  }

  return (
    <StyledCellCoefficient>
      {!isHideCoefficientForTypes[bet.betType] &&
        formatAmountLocaleString({ value: bet.coefficient?.toFixed(2) })}
    </StyledCellCoefficient>
  )
}
