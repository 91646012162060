import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  accountProviderActions,
  EAuthenticationSteps,
  selectAccountUser,
  selectIsAuthenticationModalShown
} from 'astra-core/containers/AccountProvider'
import { useTranslation } from 'react-i18next'
import { UserVerificationStatusEnum } from 'betweb-openapi-axios'

import { Notification } from 'widgets/notification'
import { EColorsNames } from 'shared/types/theme'
import { FullRegistration } from 'widgets/FullRegistration'
import { EButtonViews } from 'shared/ui/Button'
import { ModalFullRegistrationStep } from 'widgets/FullRegistration/ModalFullRegistrationStep/ModalFullRegistrationStep'
import { Modal, ViewComponentType } from 'shared/ui/modal'

import { SectionPassword, Section } from './components'
import { getSectionNameData, getSectionPersonalData } from './constants'
import {
  StyledChangePasswordButton,
  StyledNoticeProfile,
  StyledProfile
} from './Profile.styled'

export const Profile = memo(() => {
  const dispatch = useDispatch()
  const [t] = useTranslation()

  const [showIsFullRegistrationStepModal, setShowIsFullRegistrationStepModal] =
    useState(false)

  const [isShownChangePasswordModal, setIsShownChangePasswordModal] =
    useReducer((_, type) => type, false)

  const user = useSelector(selectAccountUser)
  const isAuthenticationModalShown = useSelector(
    selectIsAuthenticationModalShown
  )

  useEffect(() => {
    if (
      user?.verification_status === UserVerificationStatusEnum.Verified &&
      isAuthenticationModalShown
    ) {
      setShowIsFullRegistrationStepModal(true)
      dispatch(accountProviderActions.closeModalFullRegistration())
    }
  }, [user])

  const sectionData = useMemo(
    () =>
      user && {
        fio: getSectionNameData(user),
        personal: getSectionPersonalData(user)
      },
    [user]
  )

  const showAuthenticationModal = useCallback(() => {
    dispatch(
      accountProviderActions.changeAuthenticationCurrentStep({
        step: EAuthenticationSteps.ONE
      })
    )
  }, [dispatch])

  const closeAuthenticationModal = useCallback(() => {
    dispatch(
      accountProviderActions.changeAuthenticationCurrentStep({
        step: null
      })
    )
  }, [dispatch])

  return (
    <StyledProfile>
      {user?.verification_status !== UserVerificationStatusEnum.Verified && (
        <>
          <StyledNoticeProfile>
            <Notification
              colors={{
                iconTutorialColor: { name: EColorsNames.Blue, value: 1 },
                linkColor: { name: EColorsNames.Primary, value: 90 }
              }}
              linkText="go through full registration"
              modalWindowAction={showAuthenticationModal}
              text="full access"
            />
          </StyledNoticeProfile>
          <Modal
            isOpen={isAuthenticationModalShown}
            view={ViewComponentType.PAYMENT}
            onModalClose={closeAuthenticationModal}
          >
            <FullRegistration />
          </Modal>
        </>
      )}
      <Section data={sectionData?.fio} title="full name short" />
      <Section data={sectionData?.personal} title="data" />
      <SectionPassword
        isShownChangePasswordModal={isShownChangePasswordModal}
        setIsShownChangePasswordModal={setIsShownChangePasswordModal}
      />
      <Section title={t('user password')}>
        <StyledChangePasswordButton
          view={EButtonViews.SECONDARY}
          onClick={() => setIsShownChangePasswordModal(true)}
        >
          {t('change user password')}
        </StyledChangePasswordButton>
      </Section>
      <ModalFullRegistrationStep
        description="place your bet"
        open={showIsFullRegistrationStepModal}
        setOpen={setShowIsFullRegistrationStepModal}
        text="congratulations, you have passed the verification!"
      />
    </StyledProfile>
  )
})
