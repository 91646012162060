import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconHideEye: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="14"
      viewBox="0 0 14 14"
      width="14"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M13.0194 2.04253C13.3123 1.74964 13.3123 1.27477 13.0194 0.981875C12.7266 0.688983 12.2517 0.688985 11.9588 0.981879L10.224 2.71664C9.22822 1.96623 8.13496 1.44531 7.03716 1.44531C4.53333 1.44531 2.02881 4.15509 0.656816 6.1171C0.255741 6.69065 0.240738 7.44452 0.636193 8.02196C1.13697 8.75319 1.81377 9.58677 2.60401 10.3367L0.983339 11.9574C0.690448 12.2503 0.69045 12.7252 0.983344 13.0181C1.27624 13.311 1.75111 13.311 2.044 13.0181L13.0194 2.04253ZM4.78343 8.1573L8.15758 4.78312C7.21496 4.29054 6.02411 4.44016 5.23229 5.23199C4.44045 6.02382 4.29084 7.21467 4.78343 8.1573Z"
        fill="#B3B3B3"
        fillRule="evenodd"
      />
      <path
        d="M9.45897 7.45239L12.2522 4.65912C12.6826 5.16059 13.0645 5.66554 13.3834 6.12839C13.775 6.69657 13.7901 7.43836 13.4044 8.01052C12.1179 9.91885 9.63333 12.5329 7.03716 12.5329C6.31471 12.5329 5.59905 12.3305 4.91423 11.9972L7.45271 9.45867C7.93447 9.37043 8.3953 9.14004 8.76782 8.76752C9.14035 8.395 9.37073 7.93416 9.45897 7.45239Z"
        fill="#B3B3B3"
      />
    </svg>
  </Icon>
)
