import React, { FC, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { selectConfig } from 'astra-core/containers/ConfigProvider'
import { getCurrentLng } from 'astra-core/utils'

import { Layout } from 'widgets/Layout'
import { ELayoutsList } from 'widgets/Layout/Layout.types'

import { TERMS_AND_CONDITIONS_MAP } from './constants'
import {
  StyledTermsAndConditionsPageBlocks,
  StyledTermsAndConditionsPageWrapper
} from './TermsAndConditionsPage.styled'

export const TermsAndConditionsPage: FC = () => {
  const config = useSelector(selectConfig)
  const layoutRef = useRef<HTMLDivElement>(null)

  const scrollToTop = () => {
    if (layoutRef.current) {
      layoutRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }
  useEffect(() => {
    const linksTop = document.querySelectorAll('.scroll-up-button')

    linksTop?.forEach((linkTop) => {
      linkTop.addEventListener('click', () => {
        scrollToTop()
      })
    })

    return () => {
      linksTop?.forEach((linkTop) => {
        linkTop.removeEventListener('click', () => {
          scrollToTop()
        })
      })
    }
  }, [])

  const currentLng = getCurrentLng()

  console.log('currentLng', `${currentLng}----${config.RULES_CDN_FOLDER}`) // TODO remove

  if (!currentLng) return null

  const termsAndConditionsLang = currentLng === 'en' ? currentLng : 'ru'

  return (
    <Layout type={ELayoutsList.ABOUT}>
      <StyledTermsAndConditionsPageWrapper ref={layoutRef}>
        {TERMS_AND_CONDITIONS_MAP[
          `${config.RULES_CDN_FOLDER}__${termsAndConditionsLang}`
        ]?.map((termsAndConditionsBlock) => (
          <StyledTermsAndConditionsPageBlocks
            dangerouslySetInnerHTML={{
              __html: termsAndConditionsBlock
            }}
            key={`${config.RULES_CDN_FOLDER}__${termsAndConditionsLang}`}
          />
        ))}
      </StyledTermsAndConditionsPageWrapper>
    </Layout>
  )
}
