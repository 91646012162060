import React from 'react'
import { useGameVendorSettings } from 'astra-core'
import { VirtualGameVendor } from 'betweb-openapi-axios'

import { Layout } from 'widgets/Layout'
import { ELayoutsList } from 'widgets/Layout/Layout.types'
import { AvailablePanel } from 'shared/ui/AvailablePanel'
import { TurboGamesGame } from 'pages/page-games-turbo/TurboGames/components'

export const PageGamesTurboGame = () => {
  const isAvailable = useGameVendorSettings(VirtualGameVendor.TurboGames)

  return (
    <Layout type={ELayoutsList.GAME}>
      {isAvailable ? <TurboGamesGame /> : <AvailablePanel />}
    </Layout>
  )
}
