import styled from 'styled-components'

import { IconClose } from 'shared/ui/Icon/General/IconClose'

import { InputFormik } from '../Input/Input'
import { IStyledFullSignUpInput } from '../FullRegistration.types'

export const StyledFullRegistrationWrapper = styled.div`
  padding: 26px 32px;
`

export const StyledFullRegistrationHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 26px;
  width: 336px;
`

export const StyledFullRegistrationHeaderTitle = styled.div`
  line-height: 20px;
  ${({ theme }) =>
    `
    font-size: ${theme.fonts.size.l};
    font-weight: ${theme.fonts.weight.bold};
    color: ${theme.colors.default.primary[90]};
  `}
`

export const StyledFullRegistrationHeaderExit = styled(IconClose)`
  align-self: center;
  cursor: pointer;
`

export const StyledUserDataTitle = styled.div`
  line-height: 20px;
  padding-bottom: 8px;
  ${({ theme }) =>
    `
    font-size: ${theme.fonts.size.m};
    font-weight: ${theme.fonts.weight.normal};
    color: ${theme.colors.default.primary[90]};
  `}
`

export const StyledInputWrapper = styled.div`
  div {
    input {
      ${({ theme }) =>
        `
          color: ${theme.colors.default.primary[90]};
    `}
    }
  }
`

export const StyledInput = styled(InputFormik)<IStyledFullSignUpInput>`
  padding-bottom: 8px;
  ${(props) =>
    props.width
      ? `
          & > input {
            width: ${props.width};
            ${(props) =>
              props.$isError
                ? props.theme.colors.fixed.red[0]
                : props.theme.colors.custom.primary[8]};
          } 
        `
      : ''}

  ${(props) =>
    props.isError
      ? `
          & > input {
            border: 1px solid ${props.theme.colors.fixed.red[2]}
          } 
        `
      : ''}

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    display: none;
  }
`

export const StyledError = styled.div`
  ${({ theme }) =>
    `
  font-size: ${theme.fonts.size.xxs};
  font-weight: ${theme.fonts.weight.normal};
  color: ${theme.colors.fixed.red[2]};
  margin-bottom: 6px;
`}
`
