import styled, { css } from 'styled-components'

export const StyledParametersContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.custom.primary[31]};
  border-radius: 8px;
  display: grid;
  padding: 16px;
  row-gap: 16px;
  width: 656px;
`

export const StyledParametersTitle = styled.div`
  line-height: 16px;

  ${({ theme }) => css`
    color: ${theme.colors.default.primary[50]};
    font-size: ${theme.fonts.size.m};
    font-weight: ${theme.fonts.weight.normal};
  `};
`

export const StyledParameter = styled.div`
  display: grid;
  row-gap: 4px;
`

export const StyledAddressBlock = styled.div`
  display: flex;
  gap: 8px;
`

export const StyledParametersHelper = styled.div`
  color: ${({ theme }) => theme.colors.custom.primary[33]};
  display: grid;
  font-size: ${({ theme }) => theme.fonts.size.s};
`

export const StyledWarningMessage = styled.div`
  color: ${({ theme }) => theme.colors.custom.primary[33]};
  display: grid;
  font-size: ${({ theme }) => theme.fonts.size.xs};
  margin-top: 16px;
  width: 656px;
`

export const StyledImageFrame = styled.div`
  background-color: white;
  border-radius: 8px;
  height: 146px;
  padding: 5px;
  width: 146px;
`

export const StyledImage = styled.img`
  height: 100%;
  width: 100%;
`
