import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconCircleMinus: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg fill="none" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM6 8C5.44772 8 5 8.44771 5 9C5 9.55229 5.44772 10 6 10L12 10C12.5523 10 13 9.55228 13 9C13 8.44771 12.5523 8 12 8L6 8Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
