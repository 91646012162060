import React from 'react'
import {
  reducer,
  sliceKey
} from 'astra-core/containers/BetsHistoryProvider/slice'
import { betsHistoryProviderSaga } from 'astra-core/containers/BetsHistoryProvider/saga'

import { useInjectReducer, useInjectSaga } from 'shared/lib/@reduxjs'

import { BetsHistory } from './components'

export const BetsHistoryPageContainer = () => {
  useInjectReducer({ key: sliceKey, reducer })
  useInjectSaga({ key: sliceKey, saga: betsHistoryProviderSaga })

  return <BetsHistory />
}
