import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  accountProviderActions,
  selectIsAuthenticationModalShown
} from 'astra-core/containers/AccountProvider'

import { FullRegistration } from 'widgets/FullRegistration'
import { Modal, ViewComponentType } from 'shared/ui/modal'

export const ModalFullRegistration = () => {
  const dispatch = useDispatch()
  const isAuthenticationModalShown = useSelector(
    selectIsAuthenticationModalShown
  )

  const closeAuthenticationModal = useCallback(() => {
    dispatch(
      accountProviderActions.changeAuthenticationCurrentStep({
        step: null
      })
    )
  }, [dispatch])

  return (
    <Modal
      isOpen={isAuthenticationModalShown}
      view={ViewComponentType.PAYMENT}
      onModalClose={closeAuthenticationModal}
    >
      <FullRegistration />
    </Modal>
  )
}
