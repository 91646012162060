import React, { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  ENotificationLayouts,
  getMessagesPagePagination,
  messagesProviderActions
} from 'astra-core/containers/MessagesProvider'

import { Tabs, TabsItem } from 'shared/ui/Tabs'
import { ENotificationTabs } from 'pages/page-notification/NotificationsPage/NotificationsPage.types'

import { PARTICULAR_FETCH_MESSAGE_PROPS } from '../MessagesAccordion/constants'

export const NotificationsTabs: FC = () => {
  const dispatch = useDispatch()
  const [t] = useTranslation()
  const [currentTab, setCurrentTab] = useState(ENotificationTabs.ALL)
  const pagination = useSelector(getMessagesPagePagination)

  useEffect(() => {
    return () => {
      dispatch(
        messagesProviderActions.clearMessages({
          layoutType: ENotificationLayouts.NOTIFICATIONS_PAGE
        })
      )
    }
  }, [dispatch, currentTab])

  useEffect(() => {
    dispatch(
      messagesProviderActions.fetchMessages({
        req: {
          offset: pagination.offset,
          limit: pagination.limit,
          from: '',
          to: '',
          ...PARTICULAR_FETCH_MESSAGE_PROPS[currentTab]
        },
        layoutType: ENotificationLayouts.NOTIFICATIONS_PAGE
      })
    )
  }, [dispatch, pagination, currentTab])

  const handleNotificationsTabsClick = useCallback(
    (type) => () => {
      setCurrentTab(type)
    },
    [setCurrentTab]
  )

  return (
    <Tabs variant={1}>
      <TabsItem
        active={currentTab === ENotificationTabs.ALL}
        handleTabChange={handleNotificationsTabsClick(ENotificationTabs.ALL)}
      >
        {t('all')}
      </TabsItem>

      <TabsItem
        active={currentTab === ENotificationTabs.UNREAD}
        handleTabChange={handleNotificationsTabsClick(ENotificationTabs.UNREAD)}
      >
        {t('unread')}
      </TabsItem>
    </Tabs>
  )
}
