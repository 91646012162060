import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconClock: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg fill="none" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM10 5.5C10 4.94772 9.55229 4.5 9 4.5C8.44771 4.5 8 4.94772 8 5.5V9C8 9.26522 8.10536 9.51957 8.29289 9.70711L11.2929 12.7071C11.6834 13.0976 12.3166 13.0976 12.7071 12.7071C13.0976 12.3166 13.0976 11.6834 12.7071 11.2929L10 8.58579V5.5Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
