import { OptionalParameter } from 'betweb-openapi-axios'
import React, { FC, useEffect, useMemo, useRef } from 'react'

import { StyledPostForm } from './PostForm.styled'

interface Props {
  url: string
  params?: OptionalParameter[]
}

export const PostForm: FC<Props> = ({ url, params = [] }) => {
  const formRef = useRef<HTMLFormElement>(null)

  const formParameters = useMemo(
    () =>
      params
        ?.filter(
          (parameter) => !!parameter.name && parameter.value !== undefined
        )
        .map((parameter) => (
          <input
            key={parameter.name}
            name={parameter.name}
            type="hidden"
            value={String(parameter.value)}
          />
        )) ?? [],
    [params]
  )

  useEffect(() => {
    if (url) {
      formRef.current?.submit()
    }
  }, [url])

  return (
    <StyledPostForm action={url} method="POST" ref={formRef}>
      {formParameters}
    </StyledPostForm>
  )
}
