import styled, { css } from 'styled-components'

export const StyledTable = styled.table`
  width: 100%;
`

export const StyledTableWrapper = styled.div<{ isData: boolean }>`
  ${(props) => css`
    overflow-y: scroll;

    ${!props.isData &&
    css`
      align-items: center;
      display: flex;
      justify-content: center;
    `}
  `}
`

export const StyledTableBody = styled.tbody``
