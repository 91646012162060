import { TFilterVirtualGameVendor } from 'astra-core/containers/BetsHistoryProvider'
import { VirtualGameVendor } from 'betweb-openapi-axios'

export type TSectionVirtualGameVendor = {
  title: string
  value: TFilterVirtualGameVendor
}[]

export const BET_HISTORY_SOURCES: TSectionVirtualGameVendor = [
  { title: 'olimp games', value: VirtualGameVendor.Sportsbook },
  { title: 'bet games', value: VirtualGameVendor.BetGames },
  { title: 'live games', value: VirtualGameVendor.LiveGames },
  { title: 'sport games', value: VirtualGameVendor.SportGames },
  { title: 'turboGames', value: VirtualGameVendor.TurboGames },
  { title: 'Twain sport', value: VirtualGameVendor.TwainSport }
]
