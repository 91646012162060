import React, { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { selectChangePassword } from 'astra-core/containers/AccountProvider'
import { useSelector } from 'react-redux'

import { REG_PASSWORD_SEPARATED } from '../SectionPassword/constants'
import { StyledPasswordConditionalWrapper } from '../SectionPassword/SectionPassword.styled'

import {
  EPasswordConditional,
  PasswordConditionalProps
} from './PasswordConditional.types'
import { StyledPasswordConditional } from './PasswordConditional.styled'

export const PasswordConditional: FC<PasswordConditionalProps> = memo(
  ({ password, title, regExp, afterSubmit }) => {
    const isCorrectConditional = password.match(regExp)
    const [t] = useTranslation()

    let type = EPasswordConditional.DEFAULT

    if (isCorrectConditional && password.length) {
      type = EPasswordConditional.CORRECT
    }

    if (!isCorrectConditional && afterSubmit && password.length) {
      type = EPasswordConditional.INCORRECT
    }

    return (
      <StyledPasswordConditional type={type}>
        &bull; {t(title)}
      </StyledPasswordConditional>
    )
  }
)

export const PasswordConditionals = memo(() => {
  const { password, errors } = useSelector(selectChangePassword)
  return (
    <StyledPasswordConditionalWrapper>
      <PasswordConditional
        afterSubmit={!!errors.length}
        password={password.newPassword}
        regExp={REG_PASSWORD_SEPARATED.symbolsCounts}
        title="at least 6 characters"
      />

      <PasswordConditional
        afterSubmit={!!errors.length}
        password={password.newPassword}
        regExp={REG_PASSWORD_SEPARATED.latinLetter}
        title="mandatory entry in Latin"
      />
    </StyledPasswordConditionalWrapper>
  )
})
