import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  accountProviderActions,
  selectAccount,
  selectCurrentBetsAmount
} from 'astra-core/containers/AccountProvider'
import { formatAmount } from 'astra-core'
import { selectAccountCurrencyData } from 'astra-core/containers/CommonDataProvider'
import { useGetCurrencyIcon } from 'astra-core/hooks'

import {
  StyledItemWrapper,
  StyledWrapper,
  StyledItemTitle,
  StyledItemBottomWrapper,
  StyledItemValue
} from './BalanceReport.styled'

export const BalanceReportItem: any = ({ data }) => {
  const [t] = useTranslation()

  return (
    <StyledItemWrapper>
      <StyledItemTitle>{t(data.title)}</StyledItemTitle>

      <StyledItemBottomWrapper>
        <StyledItemValue>{data.value}</StyledItemValue>
      </StyledItemBottomWrapper>
    </StyledItemWrapper>
  )
}
export const BalanceReport = () => {
  const dispatch = useDispatch()
  const accountData = useSelector(selectAccount)
  const currentBetsAmount = useSelector(selectCurrentBetsAmount)

  useEffect(() => {
    dispatch(accountProviderActions.getCurrentBetsAmount())
  }, [dispatch])

  const reservedAmount = useMemo(() => {
    return accountData?.reserved
      ? (accountData?.reserved / 100).toLocaleString()
      : 0
  }, [accountData])

  const currencyData = useSelector(selectAccountCurrencyData)
  const currencyIcon = useGetCurrencyIcon()

  const data = useMemo(
    () => [
      {
        title: 'settlement currency',
        value: currencyData?.name
      },
      {
        title: 'account amount',
        value: formatAmount({
          value: accountData?.balance,
          currency: currencyIcon
        })
      },
      {
        title: 'current bets',
        value: formatAmount({
          value: currentBetsAmount,
          currency: currencyIcon
        })
      },
      {
        title: 'prepared for withdrawal',
        value: formatAmount({
          value: reservedAmount,
          currency: currencyIcon
        })
      }
    ],
    [
      accountData?.balance,
      currencyData?.name,
      reservedAmount,
      currentBetsAmount,
      currencyIcon
    ]
  )
  return (
    <StyledWrapper>
      {data.map((item) => (
        <BalanceReportItem data={item} key={item.title} />
      ))}
    </StyledWrapper>
  )
}
