import React, { useContext, useMemo } from 'react'

import { IconChevronDown } from 'shared/ui/Icon/General/IconChevronDown'

import { TableMethodsContext } from '../../../context'

import { StyledCellButtonToggle } from './CellButtonToggle.styled'

export const CellButtonToggle = ({ dataItem }) => {
  const { collapsedRows } = useContext(TableMethodsContext)

  const isOpened = useMemo(
    () => collapsedRows?.includes(dataItem.id),
    [collapsedRows, dataItem.id]
  )

  return (
    <StyledCellButtonToggle>
      <IconChevronDown size={9} twist={isOpened ? 0 : 180} />
    </StyledCellButtonToggle>
  )
}
