import React, { memo, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  resultsProviderActions,
  selectCurrentResultsLength,
  selectFetchItemsResults,
  selectResultsFilterDate
} from 'astra-core/containers/ResultsProvider'
import { dayjsCore as dayjs } from 'astra-core/utils/dayjs'
import { useTheme } from 'styled-components'

import { TitleLinePage } from 'shared/ui/TitleLinePage'
import { EColorsNames, EColorsTypes } from 'shared/types/theme'
import { getIsTodayValue } from 'shared/lib/todayDate'
import { IconPreloader } from 'shared/ui/Icon/General/IconPreloader'

import {
  StyledClearResult,
  StyledClearResultIcon,
  StyledClearResultIconDescription,
  StyledClearResultIconTitle,
  StyledIconPreloaderWrapper,
  StyledIconResults,
  StyledResultDate,
  StyledResultsWrapper
} from './Results.styled'
import { Header } from './Header'
import { RESULTS_DATE_FORMAT } from './constants'
import { Championships } from './Championships'

const IconCalendar = (
  <StyledIconResults
    colorProps={{
      name: EColorsNames.Green,
      type: EColorsTypes.DEFAULT,
      value: 50
    }}
    size={24}
  />
)

const ClearResult = memo(() => {
  const [t] = useTranslation()
  return (
    <StyledClearResult>
      <StyledClearResultIcon size={64} />
      <StyledClearResultIconTitle>{t('no results')}</StyledClearResultIconTitle>
      <StyledClearResultIconDescription
        dangerouslySetInnerHTML={{ __html: t('change filtering') }}
      />
    </StyledClearResult>
  )
})

export const Results = () => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const date = useSelector(selectResultsFilterDate)
  const isLoadingResults = useSelector(selectFetchItemsResults)
  const theme = useTheme()
  const resultsLength = useSelector(selectCurrentResultsLength)

  useEffect(() => {
    dispatch(resultsProviderActions.fetchResults())
  }, [dispatch])

  const currentDate = useMemo(
    () =>
      `${getIsTodayValue({ date, t })} ${dayjs(date).format(
        RESULTS_DATE_FORMAT
      )}`,
    [date, t]
  )

  return (
    <StyledResultsWrapper>
      <TitleLinePage iconComponent={IconCalendar} title={t('results')}>
        <Header />
      </TitleLinePage>
      <StyledResultDate>{currentDate}</StyledResultDate>
      {isLoadingResults ? (
        <StyledIconPreloaderWrapper>
          <IconPreloader color={theme.colors.default.green[50]} />
        </StyledIconPreloaderWrapper>
      ) : !resultsLength ? (
        <ClearResult />
      ) : (
        <Championships />
      )}
    </StyledResultsWrapper>
  )
}
