import {
  betsHistoryProviderActions,
  selectBetsHistoryGameVendor
} from 'astra-core/containers/BetsHistoryProvider'
import React, { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ETestIds } from 'astra-core/utils/testIds'

import { StyledTabs, StyledTabsItem } from './BetsHistorySources.styled'
import { BET_HISTORY_SOURCES } from './constants'

export const BetsHistorySources = memo(() => {
  const [t] = useTranslation()
  const gameVendor = useSelector(selectBetsHistoryGameVendor)
  const dispatch = useDispatch()

  const handleClick = useCallback(
    (value) => () =>
      dispatch(betsHistoryProviderActions.setFilterGameVendor(value)),
    [dispatch]
  )

  return (
    <StyledTabs variant={2}>
      {BET_HISTORY_SOURCES.map((item) => (
        <StyledTabsItem
          active={item.value === gameVendor}
          handleTabChange={handleClick(item.value)}
          key={item.title}
          testId={`${ETestIds.TestBetsHistoryTabs}-${item.value}`}
        >
          {t(item.title)}
        </StyledTabsItem>
      ))}
    </StyledTabs>
  )
})
