import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconBetResult: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg fill="none" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM11 5C10.4477 5 10 5.44772 10 6C10 6.55228 10.4477 7 11 7H11.5858L9 9.58579L7.70711 8.29289C7.31658 7.90237 6.68342 7.90237 6.29289 8.29289L3.29289 11.2929C2.90237 11.6834 2.90237 12.3166 3.29289 12.7071C3.68342 13.0976 4.31658 13.0976 4.70711 12.7071L7 10.4142L8.29289 11.7071C8.68342 12.0976 9.31658 12.0976 9.70711 11.7071L13 8.41421V9C13 9.55228 13.4477 10 14 10C14.5523 10 15 9.55228 15 9V6C15 5.44772 14.5523 5 14 5H11Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
