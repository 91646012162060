import React, { FC, memo, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { useGroupedEventsByGroupId } from 'astra-core'

import { LoaderSpinner } from 'shared/ui/LoaderSpinner'
import { RootState } from 'shared/types/store'
import { LineTournamentProps } from 'pages/page-live-events/Line/components/LineTournaments/LineTournaments.types'
import { useLiveData } from 'pages/page-live-events/LiveContainer/hooks'
import { useLineTournamentContainerData } from 'pages/page-line/LineContainer/hooks'
import { selectLineEvents } from 'pages/page-line-tournament/LineTournamentsContainer/selectors'
import { selectLiveEvents } from 'pages/page-live-events/LiveContainer/selectors'
import {
  TableBetsHead,
  TableBetsRow
} from 'pages/page-live-events/Line/components/ui'
import { ETableHeadVariant } from 'pages/page-live-events/Line/components/ui/TableBets/components/TableBetsHead/TableBetsHead.types'

import { StyledTournamentShow } from './LineTournament.styled'

const LineTournamentList: FC<LineTournamentProps> = memo(({ tournament }) => {
  useLiveData({ sportId: tournament.sportId.toString() })
  useLineTournamentContainerData({
    tournamentId: tournament.id.toString()
  })

  const events = useSelector((state: RootState) => {
    if (tournament.lineEventCount) {
      return selectLineEvents(state, {
        tournamentId: tournament.id.toString()
      })
    } else {
      return selectLiveEvents(state, { sportId: tournament.sportId.toString() })
    }
  })

  const eventsGroups = useGroupedEventsByGroupId(events).filter(
    (eventsGroups) => eventsGroups.id === tournament.id
  )

  return !eventsGroups.length ? (
    <LoaderSpinner />
  ) : (
    <>
      {eventsGroups.map((groups) =>
        groups.events.map((event) => (
          <TableBetsRow event={event} key={event.id} />
        ))
      )}
    </>
  )
})

export const LineTournament: FC<LineTournamentProps> = memo(
  ({ tournament }) => {
    const [isOutcomesOpened, setIsOutcomesOpened] = useState(false)

    const toggleOutcomesOpen = useCallback(
      (e) => {
        e.preventDefault()
        setIsOutcomesOpened(!isOutcomesOpened)
      },
      [isOutcomesOpened, setIsOutcomesOpened]
    )

    return (
      <>
        <StyledTournamentShow
          isCursor={!!tournament?.totalEventCount}
          onClick={toggleOutcomesOpen}
        >
          <TableBetsHead
            isOutcomesOpened={isOutcomesOpened}
            isShowCount={tournament?.totalEventCount}
            isShowOdds={!isOutcomesOpened}
            tournament={tournament}
            variant={ETableHeadVariant.TWO}
          />
        </StyledTournamentShow>
        {!!isOutcomesOpened && <LineTournamentList tournament={tournament} />}
      </>
    )
  }
)
