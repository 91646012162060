import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCreatedPayment,
  selectCurrentPaymentMethod,
  selectPaymentMethods,
  balanceProviderActions,
  selectPaymentMethodsLength,
  selectPaymentMethodsFetchItemsLoading,
  selectPaymentMethodsByGroupId
} from 'astra-core/containers/BalanceProvider'
import qs from 'qs'

import { LoaderSpinner } from 'shared/ui/LoaderSpinner'

import {
  LoadingIndicatorContainer,
  StyledFinanceContent,
  StyledFinanceTitle,
  StyledFinanceWrapper
} from '../../Balance.styled'
import { PaymentForm } from '../Form'
import { Menu } from '../Menu'

import { EmptyPaymentList } from './components/EmptyPaymentList'
import { FormParameters } from './components/PaymentParameters'
import { PostForm } from './components/PostForm'

export const Payment = () => {
  const dispatch = useDispatch()
  const methods = useSelector(selectPaymentMethods)
  const currentPayment = useSelector(selectCurrentPaymentMethod)
  const paymentMethodsByGroupId = useSelector(selectPaymentMethodsByGroupId)
  const createdPayment = useSelector(selectCreatedPayment)
  const paymentMethodsLength = useSelector(selectPaymentMethodsLength)
  const isLoadingPaymentMethods = useSelector(
    selectPaymentMethodsFetchItemsLoading
  )

  const handleChangeMethod = useCallback(
    (id) => {
      dispatch(balanceProviderActions.handleChangePaymentMethod(id))
    },
    [dispatch]
  )

  useEffect(() => {
    if (createdPayment.payment?.redirectUrl) {
      const params = qs.stringify(createdPayment.payment.parameters)
      const redirectUrl = createdPayment.payment?.redirectUrl
      window.location.href = `${redirectUrl}?${params}`
    }
  }, [createdPayment])

  useEffect(() => {
    dispatch(balanceProviderActions.resetPaymentData())
  }, [currentPayment, dispatch])

  const selectedMethod = useMemo(
    () => methods.items.find((item) => item.id === methods.selectedId),
    [methods.items, methods.selectedId]
  )

  const showParameters = useMemo(
    () =>
      !!createdPayment.payment?.parameters?.length &&
      !createdPayment.payment?.postUrl,
    [createdPayment.payment?.parameters, createdPayment.payment?.postUrl]
  )

  if (isLoadingPaymentMethods) {
    return (
      <LoadingIndicatorContainer>
        <LoaderSpinner />
      </LoadingIndicatorContainer>
    )
  }

  if (!paymentMethodsLength) {
    return <EmptyPaymentList text="there are no available payment" />
  }

  if (!selectedMethod) {
    return null
  }

  return (
    <StyledFinanceWrapper>
      <Menu
        groups={paymentMethodsByGroupId}
        selectedId={methods.selectedId}
        onChange={handleChangeMethod}
      />
      <StyledFinanceContent>
        <StyledFinanceTitle>{currentPayment?.name}</StyledFinanceTitle>
        {/* TODO handle it with method and grouping */}
        {/* <BankCardsMethod /> */}
        {(!createdPayment.payment || selectedMethod.require_transaction) && (
          <PaymentForm
            requireTransaction={selectedMethod.require_transaction}
          />
        )}
        {createdPayment.payment?.postUrl && (
          <PostForm
            params={createdPayment?.payment?.parameters}
            url={createdPayment.payment.postUrl}
          />
        )}
        {showParameters && (
          <FormParameters parameters={createdPayment!.payment!.parameters!} />
        )}
      </StyledFinanceContent>
    </StyledFinanceWrapper>
  )
}
