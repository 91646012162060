import React from 'react'

import { Layout } from 'widgets/Layout'
import { ELayoutsList } from 'widgets/Layout/Layout.types'

import { Promotion } from './Promotion'

export const PagePromotion = () => (
  <Layout type={ELayoutsList.PROFILE}>
    <Promotion />
  </Layout>
)
