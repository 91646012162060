import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Tabs, TabsItem } from 'shared/ui/Tabs'
import { selectFavouriteEventsFilters } from 'pages/page-favourite-events/FavouriteEventsContainer/selectors'
import { favouriteEventsContainer } from 'pages/page-favourite-events/FavouriteEventsContainer/slice'

import { FILTER_SPORTS_TYPES_TABS } from './constants'

export const SportsTypesTabs = () => {
  const dispatch = useDispatch()
  const [t] = useTranslation()
  const { sportsType } = useSelector(selectFavouriteEventsFilters)

  const handleSportTypeClick = useCallback(
    (type) => () =>
      dispatch(favouriteEventsContainer.setFilterSportsType(type)),
    [dispatch]
  )

  return (
    <Tabs variant={2} combined>
      {FILTER_SPORTS_TYPES_TABS.map((tab) => (
        <TabsItem
          active={tab.sportsType === sportsType}
          handleTabChange={handleSportTypeClick(tab.sportsType)}
          key={tab.value}
        >
          {t(tab.value)}
        </TabsItem>
      ))}
    </Tabs>
  )
}
