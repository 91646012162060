import React from 'react'

import { Layout } from 'widgets/Layout'
import { ELayoutsList } from 'widgets/Layout/Layout.types'

import { PromotionsAndBonuses } from './PromotionsAndBonuses'

export const PagePromotionAndBonuses = () => (
  <Layout
    pageTitle={{ text: 'promotions and bonuses' }}
    type={ELayoutsList.PROFILE}
  >
    <PromotionsAndBonuses />
  </Layout>
)
