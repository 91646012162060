import React, { FC } from 'react'
import { useTheme } from 'styled-components'
import { LayoutTheme } from 'betweb-openapi-axios'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconList: FC<IconProps> = (props) => {
  const theme = useTheme()

  let colorsIcon = {
    background: theme.colors.fixed.green[5],
    element: theme.colors.fixed.green[0]
  }

  if (theme.typeLayoutTheme === LayoutTheme.Dark) {
    colorsIcon = {
      background: theme.colors.fixed.primary[80],
      element: theme.colors.fixed.primary[70]
    }
  }

  return (
    <Icon {...props}>
      <svg fill="none" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 8C0 3.58172 3.58172 0 8 0H56C60.4183 0 64 3.58172 64 8V56C64 60.4183 60.4183 64 56 64H8C3.58172 64 0 60.4183 0 56V8Z"
          fill={colorsIcon.background}
        />
        <path
          d="M18 17C18 19.2091 16.2091 21 14 21C11.7909 21 10 19.2091 10 17C10 14.7909 11.7909 13 14 13C16.2091 13 18 14.7909 18 17Z"
          fill={colorsIcon.element}
        />
        <path
          d="M18 27C18 29.2091 16.2091 31 14 31C11.7909 31 10 29.2091 10 27C10 24.7909 11.7909 23 14 23C16.2091 23 18 24.7909 18 27Z"
          fill={colorsIcon.element}
        />
        <path
          d="M18 37C18 39.2091 16.2091 41 14 41C11.7909 41 10 39.2091 10 37C10 34.7909 11.7909 33 14 33C16.2091 33 18 34.7909 18 37Z"
          fill={colorsIcon.element}
        />
        <path
          d="M18 47C18 49.2091 16.2091 51 14 51C11.7909 51 10 49.2091 10 47C10 44.7909 11.7909 43 14 43C16.2091 43 18 44.7909 18 47Z"
          fill={colorsIcon.element}
        />
        <path
          d="M23 16C23 15.4477 23.4477 15 24 15H49C49.5523 15 50 15.4477 50 16V18C50 18.5523 49.5523 19 49 19H24C23.4477 19 23 18.5523 23 18V16Z"
          fill={colorsIcon.element}
        />
        <path
          d="M23 26C23 25.4477 23.4477 25 24 25H53C53.5523 25 54 25.4477 54 26V28C54 28.5523 53.5523 29 53 29H24C23.4477 29 23 28.5523 23 28V26Z"
          fill={colorsIcon.element}
        />
        <path
          d="M23 36C23 35.4477 23.4477 35 24 35H45C45.5523 35 46 35.4477 46 36V38C46 38.5523 45.5523 39 45 39H24C23.4477 39 23 38.5523 23 38V36Z"
          fill={colorsIcon.element}
        />
        <path
          d="M23 46C23 45.4477 23.4477 45 24 45H49C49.5523 45 50 45.4477 50 46V48C50 48.5523 49.5523 49 49 49H24C23.4477 49 23 48.5523 23 48V46Z"
          fill={colorsIcon.element}
        />
      </svg>
    </Icon>
  )
}
