import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  balanceProviderActions,
  EBalanceWithdrawModalWindowType,
  selectWithdrawalMethodError,
  selectWithdrawModalType
} from 'astra-core/containers/BalanceProvider'
import { useHistory } from 'react-router-dom'

import { IconRoundWarning } from 'shared/ui/Icon/General/IconRoundWarning'
import { IconSuccess } from 'shared/ui/Icon/General/IconSuccess'
import { EColorsNames } from 'shared/types/theme'
import { ERoutes } from 'shared/types/routes'
import { Modal, ViewComponentType } from 'shared/ui/modal'

import {
  StyledBalanceModalDescriptionAction,
  StyledBalanceModalDescriptionBackendError,
  StyledBalanceModalDescriptionReason,
  StyledBalanceModalDescriptionTitle,
  StyledBalanceModalDescriptionWrapper,
  StyledBalanceModalHeaderExit,
  StyledBalanceModalHeaderWrapper,
  StyledBalanceModalWrapper
} from './BalanceModal.styled'

export const BalanceWithdrawModal = () => {
  const dispatch = useDispatch()
  const withdrawModalType = useSelector(selectWithdrawModalType)
  const history = useHistory()

  const handleCloseModalWindow = useCallback(() => {
    dispatch(
      balanceProviderActions.changeWithdrawModal({
        modalType: EBalanceWithdrawModalWindowType.OFF
      })
    )

    if (withdrawModalType === EBalanceWithdrawModalWindowType.SUCCESS) {
      history.push(ERoutes.Root)
    }
  }, [dispatch, history, withdrawModalType])

  return (
    <Modal
      view={ViewComponentType.PAYMENT}
      isOpen
      onModalClose={handleCloseModalWindow}
    >
      <StyledBalanceModalWrapper>
        <StyledBalanceModalHeaderWrapper>
          <StyledBalanceModalHeaderExit
            colorProps={{ name: EColorsNames.Primary, value: 90 }}
            size={10}
            onClick={handleCloseModalWindow}
          />
        </StyledBalanceModalHeaderWrapper>

        {BalanceModalDescriptions()[withdrawModalType]}
      </StyledBalanceModalWrapper>
    </Modal>
  )
}

export const BalanceModalDescriptions = () => {
  const [t] = useTranslation()

  return {
    [EBalanceWithdrawModalWindowType.SUCCESS]: (
      <StyledBalanceModalDescriptionWrapper>
        <IconSuccess size={40} />
        <StyledBalanceModalDescriptionTitle>
          {t('request successfully accepted')}
        </StyledBalanceModalDescriptionTitle>
        <StyledBalanceModalDescriptionReason>
          {t('approximate waiting time')}
        </StyledBalanceModalDescriptionReason>
      </StyledBalanceModalDescriptionWrapper>
    ),
    [EBalanceWithdrawModalWindowType.ERROR]: (
      <StyledBalanceModalDescriptionWrapper>
        <IconRoundWarning size={40} />
        <StyledBalanceModalDescriptionTitle>
          {t('payment canceled')}
        </StyledBalanceModalDescriptionTitle>

        <BalanceModalErrorDescriptions />
      </StyledBalanceModalDescriptionWrapper>
    )
  }
}

const BalanceModalErrorDescriptions = () => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const withdrawalError = useSelector(selectWithdrawalMethodError)

  const handleCloseModalWindow = useCallback(() => {
    dispatch(
      balanceProviderActions.changeWithdrawModal({
        modalType: EBalanceWithdrawModalWindowType.OFF
      })
    )
  }, [dispatch])

  return withdrawalError ? (
    <StyledBalanceModalDescriptionBackendError>
      {t(withdrawalError.title)}
    </StyledBalanceModalDescriptionBackendError>
  ) : (
    <>
      <StyledBalanceModalDescriptionReason>
        {t('payment technical reasons')}
      </StyledBalanceModalDescriptionReason>
      <StyledBalanceModalDescriptionAction onClick={handleCloseModalWindow}>
        {t('payment try again')}
      </StyledBalanceModalDescriptionAction>
    </>
  )
}
