import styled from 'styled-components'

import { EColorsNames } from 'shared/types/theme'

import { IStyledWrapper } from './Mark.types'

export const StyledWrapper = styled.div<IStyledWrapper>`
  display: inline-block;
  width: fit-content;

  ${(props) => (props.float ? `float: ${props.float};` : '')}
`

export const StyledBonus = styled.span`
  background: ${(props) => props.theme.colors.fixed[EColorsNames.Yellow][40]};
  border-radius: 4px;
  color: ${(props) => props.theme.colors.default.primary[90]};
  display: inline-block;
  font-size: ${(props) => props.theme.fonts.size.xxxs};
  font-weight: ${(props) => props.theme.fonts.weight.semiBold};
  padding: 2px 4px;
  text-transform: uppercase;
  width: fit-content;
`

export const StyledLive = styled.span`
  color: ${(props) => props.theme.colors.default.red[50]};
  display: inline-block;
  font-size: ${(props) => props.theme.fonts.size.xs};
  font-weight: ${(props) => props.theme.fonts.weight.semiBold};
  text-transform: uppercase;
  width: fit-content;
`
