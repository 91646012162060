import React from 'react'

import { Layout } from 'widgets/Layout'
import { ELayoutsList } from 'widgets/Layout/Layout.types'

import { FavouriteEventsContainer } from './FavouriteEventsContainer'

export const PageFavouriteEvents = () => (
  <Layout type={ELayoutsList.SPORT}>
    <FavouriteEventsContainer />
  </Layout>
)
