import styled from 'styled-components'

export const StyledWrapper = styled.div`
  background: ${(props) => props.theme.colors.custom.primary[27]};
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
`

export const StyledItemWrapper = styled.div`
  padding: 16px 28px;
  position: relative;
  width: 100%;

  &:first-of-type {
    padding-left: 16px;

    &::after {
      background: ${(props) => props.theme.colors.custom.primary[8]};
      content: '';
      display: block;
      height: calc(100% - 32px);
      position: absolute;
      right: 20px;
      top: 16px;
      width: 1px;
    }
  }
`

export const StyledItemTitle = styled.h3`
  color: ${(props) => props.theme.colors.custom.primary[28]};
  font-size: ${(props) => props.theme.fonts.size.s};
  font-weight: ${(props) => props.theme.fonts.weight.normal};
  margin: 0;
  padding: 0 0 5px;
`

export const StyledItemBottomWrapper = styled.div`
  align-items: flex-end;
  color: ${(props) => props.theme.colors.fixed.primary[30]};
  display: flex;
  font-size: ${(props) => props.theme.fonts.size.s};
`

export const StyledItemPrice = styled.div`
  color: ${(props) => props.theme.colors.default.primary[90]};
  font-size: ${(props) => props.theme.fonts.size.l};
  font-weight: ${(props) => props.theme.fonts.weight.normal};
  padding-right: 10px;
`
