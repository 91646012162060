import React from 'react'

import { StyledBonusCouponsBodyWrapper } from './BonusCouponsBody.styled'
import { BonusCouponsBodyTabs } from './components/BonusCouponsBodyTabs'
import { Coupons } from './components/Coupons'

export const BonusCouponsBody = () => (
  <StyledBonusCouponsBodyWrapper>
    <BonusCouponsBodyTabs />

    <Coupons />
  </StyledBonusCouponsBodyWrapper>
)
