import { Form, Formik } from 'formik'
import React, { FC, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MethodParameterType } from 'betweb-openapi-axios'
import {
  balanceProviderActions,
  PAYMENT_METHODS,
  selectCurrentWithdrawalMethod,
  selectPaymentFormData
} from 'astra-core/containers/BalanceProvider'
import { useTheme } from 'styled-components'

import { findTransactionParam } from 'shared/lib/payment'
import { handleChangeTypeValue } from 'pages/page-balance/Balance/components/Form/Form.types'
import { formikInitialValues } from 'pages/page-balance/Balance/components/Form/constants'

import {
  StyledBankCardIconList,
  StyledBankCardNumberWrapper,
  StyledBankCardsMethodWrapper,
  StyledBankCardImg,
  StyledInput
} from './BankCardsMethod.styled'
import { BANK_CARD_IMG_LIST } from './constants'

export const BankCardsMethod: FC = () => {
  const dispatch = useDispatch()
  const currentWithdrawal = useSelector(selectCurrentWithdrawalMethod)
  const { typeLayoutTheme } = useTheme()
  const formData = useSelector(selectPaymentFormData)

  const getCurrentActiveWithdrawParamName = useCallback(
    (parameterType: MethodParameterType) =>
      (currentWithdrawal &&
        findTransactionParam(currentWithdrawal?.withdraw_params, parameterType)
          ?.displayed_name) ??
      '',
    [currentWithdrawal]
  )

  const handleChange = useCallback(
    (type: handleChangeTypeValue) =>
      ({ target }) => {
        dispatch(
          balanceProviderActions.setWithdrawalFormData({ [type]: target.value })
        )
      },
    [dispatch]
  )

  return (
    <StyledBankCardsMethodWrapper>
      <StyledBankCardIconList>
        {BANK_CARD_IMG_LIST(typeLayoutTheme).map(({ src, alt, size }) => (
          <StyledBankCardImg alt={alt} key={src} size={size} src={src} />
        ))}
      </StyledBankCardIconList>
      <StyledBankCardNumberWrapper>
        <Formik
          initialValues={formikInitialValues(formData)}
          onSubmit={() => {}}
        >
          <Form>
            <StyledInput
              label={getCurrentActiveWithdrawParamName(
                MethodParameterType.CardNumber
              )}
              name={PAYMENT_METHODS.CARD}
              placeholder="enter card number"
              type="text"
              width="224px"
              onChange={handleChange(PAYMENT_METHODS.CARD)}
            />
          </Form>
        </Formik>
      </StyledBankCardNumberWrapper>
    </StyledBankCardsMethodWrapper>
  )
}
