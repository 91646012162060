import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  favouritesProviderActions,
  selectFavouriteEvents
} from 'astra-core/containers/FavouritesProvider'
import { useGroupedEventsByGroupId } from 'astra-core/hooks/useEvents'

import { TitleLinePage } from 'shared/ui/TitleLinePage'
import { selectFavouriteEventsFilters } from 'pages/page-favourite-events/FavouriteEventsContainer/selectors'
import { TableBets } from 'pages/page-live-events/Line/components/ui'
import { ESportsMenuTabTypes } from 'widgets/sports-menu'
import { TABLE_BETS_HEADER_EVENT_PROPS } from 'pages/page-favourite-events/FavouriteEvents/constants'

import { Filters } from './components'
import {
  StyledCalendarEvents,
  TitleLinePageIcon
} from './FavouriteEvents.styled'

const filterEvents = (events, sportsType) => {
  let filtredEventsArr = events
  if (sportsType?.toLowerCase() === ESportsMenuTabTypes.LINE) {
    filtredEventsArr = events.filter((event) => !event.live)
  }
  if (sportsType?.toLowerCase() === ESportsMenuTabTypes.LIVE) {
    filtredEventsArr = events.filter((event) => event.live)
  }
  return filtredEventsArr
}

export const FavouriteEvents = () => {
  const dispatch = useDispatch()
  const [t] = useTranslation()
  const events = useSelector(selectFavouriteEvents)
  const { sportsType } = useSelector(selectFavouriteEventsFilters)

  const eventsGroup = useGroupedEventsByGroupId(
    filterEvents(events, sportsType)
  )

  useEffect(() => {
    dispatch(favouritesProviderActions.fetchFavouriteEventsIds())
  }, [dispatch])

  return (
    <StyledCalendarEvents>
      <TitleLinePage iconComponent={TitleLinePageIcon} title={t('favorite')}>
        <Filters />
      </TitleLinePage>

      <TableBets
        eventsGroups={eventsGroup}
        headerEventsProps={TABLE_BETS_HEADER_EVENT_PROPS}
      />
    </StyledCalendarEvents>
  )
}
