import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconWatch: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="12"
      viewBox="0 0 12 12"
      width="12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.40918 4.30078C6.40918 3.88657 6.07339 3.55078 5.65918 3.55078C5.24497 3.55078 4.90918 3.88657 4.90918 4.30078V6.11896C4.90918 6.42198 5.09153 6.69524 5.37135 6.81153L7.01198 7.49335C7.39448 7.65232 7.83342 7.4711 7.99238 7.08861C8.15134 6.70611 7.97013 6.26717 7.58763 6.10821L6.40918 5.61846V4.30078Z"
        fill="#808080"
      />
      <path
        clipRule="evenodd"
        d="M5.9998 0.800781C3.12792 0.800781 0.799805 3.1289 0.799805 6.00078C0.799805 8.87266 3.12792 11.2008 5.9998 11.2008C8.87169 11.2008 11.1998 8.87266 11.1998 6.00078C11.1998 3.1289 8.87169 0.800781 5.9998 0.800781ZM2.1998 6.00078C2.1998 3.9021 3.90112 2.20078 5.9998 2.20078C8.09849 2.20078 9.7998 3.9021 9.7998 6.00078C9.7998 8.09946 8.09849 9.80078 5.9998 9.80078C3.90112 9.80078 2.1998 8.09946 2.1998 6.00078Z"
        fill="#808080"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
