import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  betsHistoryProviderActions,
  selectBetsHistoryCollapsedBets,
  selectBetsHistoryDataBetsFetchItems,
  selectBetsHistoryPageValues
} from 'astra-core/containers/BetsHistoryProvider'

import { LoaderSpinner } from 'shared/ui/LoaderSpinner'
import { useFetchBetsHistoryPage } from 'pages/page-bets-history/BetsHistoryPageContainer/hooks'

import { Filter, SectionSelect, Table } from './components'
import { ITableData } from './History.types'
import { StyledHistory } from './History.styled'

export const History = () => {
  const dispatch = useDispatch()
  const { collapsedList } = useSelector(selectBetsHistoryCollapsedBets)
  const betsListTableData = useSelector(selectBetsHistoryPageValues)
  const betsFetchItems = useSelector(selectBetsHistoryDataBetsFetchItems)

  useFetchBetsHistoryPage()

  useEffect(() => {
    return () => {
      dispatch(betsHistoryProviderActions.resetBetsHistory())
    }
  }, [dispatch])

  const onClickRowCollapse = useCallback(
    (id) => dispatch(betsHistoryProviderActions.setCollapsedBets(id)),
    [dispatch]
  )

  return (
    <StyledHistory>
      <SectionSelect />

      <Filter />

      {betsFetchItems.loading ? (
        <LoaderSpinner />
      ) : (
        <Table
          collapsedRows={collapsedList}
          data={betsListTableData as ITableData[]}
          onRowCollapse={onClickRowCollapse}
        />
      )}
    </StyledHistory>
  )
}
