import React, { useState } from 'react'

import { AccordionProps } from './types'
import {
  StyledAccordionDiscription,
  StyledAccordionBlock,
  StyledAccordionTitle,
  StyledAccordionContainer,
  StyledAccordionDate,
  IconAccordion,
  StyledNotificationAccordion
} from './styled'

export const Accordion = ({
  accordionTitle,
  accordionDescr,
  accordionDate,
  children,
  padding,
  color,
  colorDescr,
  isRead,
  onClick,
  id
}: AccordionProps) => {
  const [text, setText] = useState(false)

  const onToggleDisplay = () => {
    setText(!text)
  }

  const handleClick = (id: string) => {
    onClick?.(id)
  }

  return (
    <StyledAccordionBlock onClick={() => handleClick(id)}>
      <StyledAccordionContainer padding={padding} onClick={onToggleDisplay}>
        <StyledAccordionTitle color={color}>
          {!isRead && <StyledNotificationAccordion size={8} />}
          {accordionTitle}
        </StyledAccordionTitle>

        <StyledAccordionDate>{accordionDate}</StyledAccordionDate>

        <IconAccordion size={10} twist={text ? 180 : 0}>
          {children}
        </IconAccordion>
      </StyledAccordionContainer>

      {text && <TextSection colorDescr={colorDescr} text={accordionDescr} />}
    </StyledAccordionBlock>
  )
}

const TextSection = ({ text, colorDescr }) => {
  return (
    <StyledAccordionDiscription colorDescr={colorDescr}>
      {text}
    </StyledAccordionDiscription>
  )
}
