export interface PasswordConditionalProps {
  password: string
  title: string
  regExp: RegExp
  afterSubmit?: boolean
}

export enum EPasswordConditional {
  DEFAULT = 'default',
  CORRECT = 'correct',
  INCORRECT = 'incorrect'
}
