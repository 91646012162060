import styled from 'styled-components'

import { StyledCell } from '../Cell.styled'

export const StyledCellBonusText = styled.span`
  padding-right: 0;
`

export const StyledCellBonus = styled(StyledCell)`
  padding-right: 0;
  width: 0;
`
