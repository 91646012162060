import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  ELoyaltyStatus,
  accountProviderActions,
  selectLoyaltyFilters
} from 'astra-core/containers/AccountProvider'
import { useDispatch, useSelector } from 'react-redux'

import { Tabs, TabsItem } from 'shared/ui/Tabs'

import { StyledBonusCouponsBodyTabsWrapper } from './BonusCouponsBodyTabs.styled'
import { BONUS_COUPONS_BODY_TABS_LIST, getIsActiveTab } from './constants'

export const BonusCouponsBodyTabs = memo(() => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const loyaltyFilters = useSelector(selectLoyaltyFilters)

  const handleSetCurrentTab = (status: ELoyaltyStatus) => () =>
    dispatch(accountProviderActions.setLoyaltyStatus(status))

  return (
    <StyledBonusCouponsBodyTabsWrapper>
      <Tabs variant={2}>
        {BONUS_COUPONS_BODY_TABS_LIST.map(({ name, locale }) => (
          <TabsItem
            active={getIsActiveTab(name, loyaltyFilters.currentStatus)}
            handleTabChange={handleSetCurrentTab(name)}
            key={name}
          >
            {t(locale)}
          </TabsItem>
        ))}
      </Tabs>
    </StyledBonusCouponsBodyTabsWrapper>
  )
})
