import React from 'react'
import { useTranslation } from 'react-i18next'

import { IconSettings } from '../Icon/General/IconSettings'

import {
  PanelContent,
  PanelTypography,
  PanelTypographyLink,
  PanelWrapper
} from './styled'

export const AvailablePanel = () => {
  const { t } = useTranslation()
  return (
    <PanelWrapper>
      <PanelContent>
        <IconSettings size={64} />
        <PanelTypography>{t('service is unavailable')}</PanelTypography>
        <PanelTypographyLink to="/">
          {t('go back to the main page')}
        </PanelTypographyLink>
      </PanelContent>
    </PanelWrapper>
  )
}
