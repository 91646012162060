import React from 'react'
import { useGameVendorSettings } from 'astra-core'
import { VirtualGameVendor } from 'betweb-openapi-axios'

import { Layout } from 'widgets/Layout'
import { ELayoutsList } from 'widgets/Layout/Layout.types'
import { AvailablePanel } from 'shared/ui/AvailablePanel'

import { TwainGames } from './TwainGames'

export const PageGamesTwain = () => {
  const isAvailable = useGameVendorSettings(VirtualGameVendor.TwainSport)

  return (
    <Layout type={ELayoutsList.GAME}>
      {isAvailable ? <TwainGames /> : <AvailablePanel />}
    </Layout>
  )
}
