import React, { FC, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  accountProviderActions,
  selectAccountId,
  selectAccountUser,
  selectFetchSportGamesTokenRequest,
  selectFetchUserRequest,
  selectSportGamesToken
} from 'astra-core/containers/AccountProvider'
import { selectConfig } from 'astra-core/containers/ConfigProvider'

import { LoaderPoints } from 'shared/ui/LoaderPoints'

import { getIframeData } from './utils'
import { StyledLoaderPointsWrapper } from './SportGames.styled'

export const SportGames: FC = () => {
  const dispatch = useDispatch()
  const user = useSelector(selectAccountUser)
  const accountId = useSelector(selectAccountId)
  const { loading: fetchUserLoading } = useSelector(selectFetchUserRequest)
  const { loading: fetchSportGamesTokenLoading } = useSelector(
    selectFetchSportGamesTokenRequest
  )
  const { token: sportGamesToken } = useSelector(selectSportGamesToken) || {}

  useEffect(() => {
    if (accountId && !sportGamesToken) {
      dispatch(
        accountProviderActions.getSportGamesToken({
          accountId
        })
      )
    }
  }, [accountId, dispatch, sportGamesToken])

  const loaded =
    fetchUserLoading === false &&
    (!user || fetchSportGamesTokenLoading === false)

  return loaded ? (
    <SportGamesIframe />
  ) : (
    <StyledLoaderPointsWrapper>
      <LoaderPoints />
    </StyledLoaderPointsWrapper>
  )
}

const SportGamesIframe = () => {
  const { token: sportGamesToken } = useSelector(selectSportGamesToken) || {}
  const { id: userId } = useSelector(selectAccountUser) || {}
  const config = useSelector(selectConfig)

  const iframeData = useMemo(
    () =>
      getIframeData({
        srcData: {
          userId,
          token: sportGamesToken,
          launchUrl: config.SPORTGAMES_PROVIDER_BASE_URL
        }
      }),
    [config.SPORTGAMES_PROVIDER_BASE_URL, sportGamesToken, userId]
  )

  console.log(iframeData.src) // TODO: will delete after tests

  return (
    <iframe
      src={iframeData.src}
      style={iframeData.styles}
      title={iframeData.id}
    />
  )
}
