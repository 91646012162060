import { MessagesApiGetMessageListRequest } from 'betweb-openapi-axios'

import { ENotificationTabs } from 'pages/page-notification/NotificationsPage/NotificationsPage.types'

export const PARTICULAR_FETCH_MESSAGE_PROPS: Record<
  ENotificationTabs,
  MessagesApiGetMessageListRequest
> = {
  [ENotificationTabs.ALL]: {},
  [ENotificationTabs.UNREAD]: {
    direct: true,
    read: false
  }
}
