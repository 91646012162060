import styled from 'styled-components'

import { Button } from 'shared/ui/Button'

import { IStyledSectionItem } from './SectionSelect.types'

export const StyledSectionItem = styled(Button)<IStyledSectionItem>`
  border-radius: 0;
  color: ${(props) =>
    props.isActiveItem
      ? props.theme.colors.default.primary[90]
      : props.theme.colors.custom.primary[17]};
  font-size: ${(props) => props.theme.fonts.size.m};
  font-weight: ${(props) => props.theme.fonts.weight.normal};
  height: auto;
  margin-right: 5px;
  padding: 10px 5px;
  position: relative;

  &::after {
    ${(props) => (props.isActiveItem ? `content: '';` : '')};
    background: ${(props) => props.theme.colors.default.primary[90]};
    border-radius: 1px;
    bottom: 0;
    height: 2px;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
  }
`

export const StyledSection = styled.nav`
  display: flex;
  position: relative;

  &::after {
    background: ${(props) => props.theme.colors.custom.primary[8]};
    border-radius: 1px;
    bottom: 0;
    content: '';
    height: 2px;
    left: 0;
    position: absolute;
    width: 100%;
  }
`
