import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconMagnifierWithList: FC<
  Omit<IconProps, 'colorProps'> & { isDark?: boolean }
> = ({ isDark, ...props }) => (
  <Icon {...props}>
    {isDark ? (
      <svg fill="none" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.5 4C8.5 6.20914 6.70914 8 4.5 8C2.29086 8 0.5 6.20914 0.5 4C0.5 1.79086 2.29086 0 4.5 0C6.70914 0 8.5 1.79086 8.5 4Z"
          fill="#6F6F6F"
        />
        <path
          d="M8.5 18C8.5 20.2091 6.70914 22 4.5 22C2.29086 22 0.5 20.2091 0.5 18C0.5 15.7909 2.29086 14 4.5 14C6.70914 14 8.5 15.7909 8.5 18Z"
          fill="#6F6F6F"
        />
        <path
          d="M8.5 32C8.5 34.2091 6.70914 36 4.5 36C2.29086 36 0.5 34.2091 0.5 32C0.5 29.7909 2.29086 28 4.5 28C6.70914 28 8.5 29.7909 8.5 32Z"
          fill="#6F6F6F"
        />
        <path
          d="M8.5 46C8.5 48.2091 6.70914 50 4.5 50C2.29086 50 0.5 48.2091 0.5 46C0.5 43.7909 2.29086 42 4.5 42C6.70914 42 8.5 43.7909 8.5 46Z"
          fill="#6F6F6F"
        />
        <path
          d="M11.5 4C11.5 3.44772 11.9477 3 12.5 3H44.5C45.0523 3 45.5 3.44772 45.5 4V5C45.5 5.55228 45.0523 6 44.5 6H12.5C11.9477 6 11.5 5.55228 11.5 5V4Z"
          fill="#6F6F6F"
        />
        <path
          d="M11.5 18C11.5 17.4477 11.9477 17 12.5 17H48.5C49.0523 17 49.5 17.4477 49.5 18V19C49.5 19.5523 49.0523 20 48.5 20H12.5C11.9477 20 11.5 19.5523 11.5 19V18Z"
          fill="#6F6F6F"
        />
        <path
          d="M11.5 32C11.5 31.4477 11.9477 31 12.5 31H40.5C41.0523 31 41.5 31.4477 41.5 32V33C41.5 33.5523 41.0523 34 40.5 34H12.5C11.9477 34 11.5 33.5523 11.5 33V32Z"
          fill="#6F6F6F"
        />
        <path
          d="M11.5 46C11.5 45.4477 11.9477 45 12.5 45H44.5C45.0523 45 45.5 45.4477 45.5 46V47C45.5 47.5523 45.0523 48 44.5 48H12.5C11.9477 48 11.5 47.5523 11.5 47V46Z"
          fill="#6F6F6F"
        />
        <path
          clipRule="evenodd"
          d="M32.2304 36.1446C29.5007 38.5446 25.9203 40 22 40C13.4396 40 6.5 33.0604 6.5 24.5C6.5 15.9396 13.4396 9 22 9C30.5604 9 37.5 15.9396 37.5 24.5C37.5 28.4203 36.0446 32.0007 33.6446 34.7304L42.2071 43.2929C42.5976 43.6834 42.5976 44.3166 42.2071 44.7071C41.8166 45.0976 41.1834 45.0976 40.7929 44.7071L32.2304 36.1446ZM35.5 24.5C35.5 31.9558 29.4558 38 22 38C14.5442 38 8.5 31.9558 8.5 24.5C8.5 17.0442 14.5442 11 22 11C29.4558 11 35.5 17.0442 35.5 24.5Z"
          fill="white"
          fillRule="evenodd"
        />
        <path
          d="M19.143 14.64C19.143 18.9635 15.6382 22.4683 11.3147 22.4683C10.8517 22.4683 10.398 22.4281 9.95703 22.351C10.7694 17.6356 14.3124 13.8545 18.8993 12.6943C19.0584 13.3164 19.143 13.9683 19.143 14.64Z"
          fill="#B3B3B3"
        />
        <path
          d="M19.1421 36.2017C19.0797 31.932 15.5992 28.49 11.3147 28.49C11.0391 28.49 10.7668 28.5043 10.4986 28.5321C11.8796 32.3505 15.1287 35.275 19.1421 36.2017Z"
          fill="#B3B3B3"
        />
      </svg>
    ) : (
      <svg fill="none" viewBox="0 0 50 51" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.5 4.5C8.5 6.70914 6.70914 8.5 4.5 8.5C2.29086 8.5 0.5 6.70914 0.5 4.5C0.5 2.29086 2.29086 0.5 4.5 0.5C6.70914 0.5 8.5 2.29086 8.5 4.5Z"
          fill="#FFDB66"
        />
        <path
          d="M8.5 18.5C8.5 20.7091 6.70914 22.5 4.5 22.5C2.29086 22.5 0.5 20.7091 0.5 18.5C0.5 16.2909 2.29086 14.5 4.5 14.5C6.70914 14.5 8.5 16.2909 8.5 18.5Z"
          fill="#FFDB66"
        />
        <path
          d="M8.5 32.5C8.5 34.7091 6.70914 36.5 4.5 36.5C2.29086 36.5 0.5 34.7091 0.5 32.5C0.5 30.2909 2.29086 28.5 4.5 28.5C6.70914 28.5 8.5 30.2909 8.5 32.5Z"
          fill="#FFDB66"
        />
        <path
          d="M8.5 46.5C8.5 48.7091 6.70914 50.5 4.5 50.5C2.29086 50.5 0.5 48.7091 0.5 46.5C0.5 44.2909 2.29086 42.5 4.5 42.5C6.70914 42.5 8.5 44.2909 8.5 46.5Z"
          fill="#FFDB66"
        />
        <path
          d="M11.5 4.5C11.5 3.94772 11.9477 3.5 12.5 3.5H44.5C45.0523 3.5 45.5 3.94772 45.5 4.5V5.5C45.5 6.05228 45.0523 6.5 44.5 6.5H12.5C11.9477 6.5 11.5 6.05228 11.5 5.5V4.5Z"
          fill="#FFDB66"
        />
        <path
          d="M11.5 18.5C11.5 17.9477 11.9477 17.5 12.5 17.5H48.5C49.0523 17.5 49.5 17.9477 49.5 18.5V19.5C49.5 20.0523 49.0523 20.5 48.5 20.5H12.5C11.9477 20.5 11.5 20.0523 11.5 19.5V18.5Z"
          fill="#FFDB66"
        />
        <path
          d="M11.5 32.5C11.5 31.9477 11.9477 31.5 12.5 31.5H40.5C41.0523 31.5 41.5 31.9477 41.5 32.5V33.5C41.5 34.0523 41.0523 34.5 40.5 34.5H12.5C11.9477 34.5 11.5 34.0523 11.5 33.5V32.5Z"
          fill="#FFDB66"
        />
        <path
          d="M11.5 46.5C11.5 45.9477 11.9477 45.5 12.5 45.5H44.5C45.0523 45.5 45.5 45.9477 45.5 46.5V47.5C45.5 48.0523 45.0523 48.5 44.5 48.5H12.5C11.9477 48.5 11.5 48.0523 11.5 47.5V46.5Z"
          fill="#FFDB66"
        />
        <path
          clipRule="evenodd"
          d="M32.2304 36.6446C29.5007 39.0446 25.9203 40.5 22 40.5C13.4396 40.5 6.5 33.5604 6.5 25C6.5 16.4396 13.4396 9.5 22 9.5C30.5604 9.5 37.5 16.4396 37.5 25C37.5 28.9203 36.0446 32.5007 33.6446 35.2304L42.2071 43.7929C42.5976 44.1834 42.5976 44.8166 42.2071 45.2071C41.8166 45.5976 41.1834 45.5976 40.7929 45.2071L32.2304 36.6446ZM35.5 25C35.5 32.4558 29.4558 38.5 22 38.5C14.5442 38.5 8.5 32.4558 8.5 25C8.5 17.5442 14.5442 11.5 22 11.5C29.4558 11.5 35.5 17.5442 35.5 25Z"
          fill="#4D4D4D"
          fillRule="evenodd"
        />
        <path
          d="M19.1391 15.14C19.1391 19.4635 15.6343 22.9683 11.3108 22.9683C10.8478 22.9683 10.3941 22.9281 9.95312 22.851C10.7655 18.1356 14.3085 14.3545 18.8954 13.1943C19.0545 13.8164 19.1391 14.4683 19.1391 15.14Z"
          fill="#FFC400"
        />
        <path
          d="M19.1382 36.7017C19.0758 32.432 15.5953 28.99 11.3108 28.99C11.0352 28.99 10.7629 29.0043 10.4946 29.0321C11.8757 32.8505 15.1248 35.775 19.1382 36.7017Z"
          fill="#FFC400"
        />
      </svg>
    )}
  </Icon>
)
