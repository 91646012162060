import React, { FC, memo, useCallback, useMemo } from 'react'
import { useField } from 'formik'
import { useTranslation } from 'react-i18next'

import { ICustomInput, IInputFormik } from './types'
import {
  StyledCustomInputSubtext,
  StyledInput,
  StyledInputWrapper,
  StyledLabel
} from './styled'

const CustomInput: FC<ICustomInput> = ({
  onBlur,
  onChange,
  errorMessage,
  ...props
}) => {
  const [t] = useTranslation()
  const [meta, helpers] = useField(props.name || '')
  const { error, touched } = helpers

  const errorMessages = useMemo(
    () => (error && touched ? error : '' || errorMessage),
    [error, errorMessage, touched]
  )

  const handleBlur = useCallback(
    (e) => {
      meta.onBlur(e)
      onBlur?.(e)
    },
    [meta, onBlur]
  )

  const handleChange = useCallback(
    (e) => {
      meta.onChange(e)
      onChange?.(e)
    },
    [meta, onChange]
  )

  return (
    <>
      <StyledInput
        {...props}
        $isError={!!errorMessages}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      {!!errorMessages && (
        <StyledCustomInputSubtext>{t(errorMessages)}</StyledCustomInputSubtext>
      )}
    </>
  )
}

export const InputForm: FC<IInputFormik> = memo(
  ({ className, label, ...props }) => {
    const [t] = useTranslation()

    const componentProps = {
      ...props,
      ...(props.placeholder && { placeholder: t(props.placeholder) })
    }

    return label ? (
      <StyledInputWrapper className={className}>
        <StyledLabel htmlFor={props.name}>{t(label)}</StyledLabel>
        <CustomInput {...componentProps} />
      </StyledInputWrapper>
    ) : (
      <StyledInputWrapper className={className}>
        <CustomInput {...componentProps} />
      </StyledInputWrapper>
    )
  }
)
