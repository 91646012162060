import styled from 'styled-components'

export const StyledTermsAndConditionsPageWrapper = styled.main`
  display: grid;
  grid-gap: 16px;
  grid-template-rows: minmax(0, 1fr);
  max-width: 960px;
  padding: 8px 16px 24px;
  width: 100%;
`

export const StyledTermsAndConditionsPageBlocks = styled.div`
  color: ${(props) => props.theme.colors.default.primary[90]};

  h1 {
    margin: 10px 0;
    font-size: ${(props) => props.theme.fonts.size.xl};
    font-weight: ${(props) => props.theme.fonts.weight.bold};
  }

  h3 {
    margin: 10px 0;
    font-size: ${(props) => props.theme.fonts.size.xl};
    font-weight: ${(props) => props.theme.fonts.weight.bold};
  }

  h4 {
    margin: 10px 0;
    font-size: ${(props) => props.theme.fonts.size.l};
    font-weight: ${(props) => props.theme.fonts.weight.bold};
  }

  p {
    margin: 5px 0;
    font-size: ${(props) => props.theme.fonts.size.m};
    font-weight: ${(props) => props.theme.fonts.weight.regular};
    line-height: 20px;
  }

  li {
    list-style-type: disc;
    margin: 5px 0;
    font-size: ${(props) => props.theme.fonts.size.m};
    font-weight: ${(props) => props.theme.fonts.weight.regular};
    line-height: 20px;
  }

  a {
    color: ${(props) => props.theme.colors.default.blue[0]};
  }

  ol {
    li {
      list-style: decimal;
    }

    padding-left: 1em;
  }
`
