import { useField } from 'formik'
import React, { FC, memo, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import {
  StyledCustomInputSubtext,
  StyledInput,
  StyledInputWrapper,
  StyledLabel
} from './Input.styled'
import { ICustomInput, IInputFormik } from './Input.types'

const CustomInput: FC<ICustomInput> = ({ onBlur, ...props }) => {
  const [t] = useTranslation()
  const [meta, helpers, field] = useField(props.name || '')
  const { error, touched } = helpers

  const errorMessage = useMemo(
    () => (error && touched ? error : ''),
    [error, touched]
  )

  const handleBlur = useCallback(
    (e) => {
      field.setValue(e.target.value.trim())
      meta.onBlur(e)
      onBlur?.(e)
    },
    [field, meta, onBlur]
  )

  return (
    <>
      <StyledInput {...props} $isError={!!errorMessage} onBlur={handleBlur} />

      {!!errorMessage && (
        <StyledCustomInputSubtext>{t(errorMessage)}</StyledCustomInputSubtext>
      )}
    </>
  )
}

export const InputFormik: FC<IInputFormik> = memo(
  ({ className, label, ...props }) => {
    const [t] = useTranslation()

    const componentProps = {
      ...props,
      ...(props.placeholder && { placeholder: t(props.placeholder) })
    }

    return label ? (
      <StyledInputWrapper className={className}>
        <StyledLabel htmlFor={props.name}>{t(label)}</StyledLabel>
        <CustomInput {...componentProps} />
      </StyledInputWrapper>
    ) : (
      <CustomInput {...componentProps} />
    )
  }
)
