import React, { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  selectAccount,
  selectAccountUser
} from 'astra-core/containers/AccountProvider'
import { useSelector } from 'react-redux'

import { Tabs, TabsItem } from 'shared/ui/Tabs'
import { EColorsNames } from 'shared/types/theme'
import { EPBTabs } from 'pages/page-promotion-and-bonuses/PromotionsAndBonuses/PromotionsAndBonuses.types'

import { PromotionsTabsProps } from './PromotionsTabs.types'
import { GET_TAB_ITEM_LIST, getIsActiveTab } from './constants'

export const PromotionsTabs: FC<PromotionsTabsProps> = memo(
  ({ currentTab, setCurrentTab }) => {
    const [t] = useTranslation()
    const user = useSelector(selectAccountUser)
    const account = useSelector(selectAccount)

    const handlePromotionsTabsClick = (type: EPBTabs) => () =>
      setCurrentTab(type)

    const isUserAuthorized = !!user && !!account

    return (
      <Tabs variant={1}>
        {GET_TAB_ITEM_LIST(isUserAuthorized).map(({ name, icon, locale }) => (
          <TabsItem
            iconProps={{
              colorProps: {
                name: EColorsNames.Primary,
                value: 17
              }
            }}
            active={getIsActiveTab(name, currentTab)}
            handleTabChange={handlePromotionsTabsClick(name)}
            icon={icon}
            key={name}
          >
            {t(locale)}
          </TabsItem>
        ))}
      </Tabs>
    )
  }
)
