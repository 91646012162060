import styled, { css } from 'styled-components'

import { EColorsNames, EColorsTypes } from 'shared/types/theme'

import { EPasswordConditional } from './PasswordConditional.types'

export const StyledPasswordConditional = styled.span<{
  type: EPasswordConditional
}>`
  ${({ theme, type }) =>
    css`
      color: ${theme.colors[EColorsTypes.CUSTOM][EColorsNames.Primary][9]};
      font-size: ${theme.fonts.size.s};
      font-weight: ${theme.fonts.weight.regular};
      line-height: 16px;

      ${type === EPasswordConditional.CORRECT &&
      css`
        color: ${theme.colors[EColorsTypes.DEFAULT][EColorsNames.Green][50]};
      `}

      ${type === EPasswordConditional.INCORRECT &&
      css`
        color: ${theme.colors[EColorsTypes.DEFAULT][EColorsNames.Red][50]};
      `}
    `}
`
