import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { dayjsCore as dayjs } from 'astra-core/utils/dayjs'
import { selectOutcomeType } from 'astra-core/containers/BasketProvider'
import { selectOutcomeCategoryById } from 'astra-core/containers/CommonDataProvider'
import { getBasketOutcomeName } from 'astra-core/utils/outcomes'
import { getBetOutcomeId, getEventTitle } from 'astra-core'

import { getHintAttrNameFormatted } from 'constants/styles/components/hintAttr/utils'
import { RootState } from 'shared/types/store'
import {
  getEventStatisticScoreByPeriod,
  getEventStatisticScoreCurrent
} from 'shared/lib/statistics'
import { IconEventInfo } from 'shared/ui/Icon/Event'
import { EColorsNames, EColorsTypes } from 'shared/types/theme'

import { Mark } from '../Mark'

import { BetHistoryStatistic } from './components'
import { OUTCOME_EVENT_DATE_FORMAT } from './constants'
import { IOutcomeItem, IOutcomes } from './Outcome.types'
import {
  StyledBetContentItem,
  StyledCellOutcome,
  StyledIconInfoCircle,
  StyledIconWrapper,
  StyledInfoIconWrapper,
  StyledInfoIconWrapperContainer,
  StyledOutcomeResultIndicator
} from './Outcome.styled'

export const Outcomes: FC<IOutcomes> = ({ outcomes }) => (
  <>
    {outcomes.map((outcome, index) => {
      const { event, outcomeTypeId, parameters, coefficient } = outcome

      const eventProbability = {
        eventId: event?.id || coefficient,
        outcomeTypeId,
        parameters
      }

      return (
        <OutcomeItem
          isLastItem={index === outcomes.length - 1}
          key={getBetOutcomeId(eventProbability)}
          outcome={outcome}
        />
      )
    })}
  </>
)

export const OutcomeItem: FC<IOutcomeItem> = ({ outcome, isLastItem }) => {
  const outcomeTypeData = useSelector((state: RootState) =>
    selectOutcomeType(state, outcome.outcomeTypeId)
  )
  const outcomeCategory = useSelector((state: RootState) =>
    selectOutcomeCategoryById(state, outcome.outcomeCategoryId)
  )

  const outcomeType = useMemo(
    () =>
      getBasketOutcomeName({
        probWithOutcome: { ...outcome, outcomeTypeData: outcomeTypeData! },
        event: outcome.event!,
        isBetOutcome: true,
        outcomeCategory
      }),
    [outcome, outcomeCategory, outcomeTypeData]
  )

  const date = useMemo(
    () =>
      outcome.event?.eventDate &&
      dayjs(outcome.event?.eventDate).format(OUTCOME_EVENT_DATE_FORMAT),
    [outcome.event?.eventDate]
  )
  const hintAttr = useMemo(
    () => getHintAttrNameFormatted(outcome.event?.tournamentName || '') || {},
    [outcome.event?.tournamentName]
  )

  const hintAttrDescription = useMemo(
    () =>
      getHintAttrNameFormatted(outcome.recalculationReason?.message || '') ||
      {},
    [outcome.recalculationReason?.message]
  )

  const currentScore = getEventStatisticScoreCurrent(
    outcome.event?.basicStatistics?.currentScore
  )

  const scoresByPeriod = getEventStatisticScoreByPeriod(
    outcome.event?.basicStatistics?.scoresByPeriod
  )

  return (
    <StyledBetContentItem isLastItem={isLastItem}>
      <StyledCellOutcome />

      <StyledCellOutcome>{date}</StyledCellOutcome>

      <StyledCellOutcome colSpan={4} {...hintAttr}>
        <StyledIconWrapper>
          <IconEventInfo
            colorProps={{
              name: EColorsNames.Primary,
              type: EColorsTypes.CUSTOM,
              value: 9
            }}
          />
        </StyledIconWrapper>
        {outcome.event && getEventTitle(outcome.event, true)}

        {outcome.event?.live && <Mark float="right" type="live" />}
      </StyledCellOutcome>

      <StyledCellOutcome colSpan={2} style={{ borderRight: 'none' }}>
        {outcomeType || ''}
      </StyledCellOutcome>

      <StyledInfoIconWrapper colSpan={1}>
        <StyledInfoIconWrapperContainer>
          {outcome.recalculationReason?.id && (
            <div {...hintAttrDescription}>
              <StyledIconInfoCircle
                colorProps={{
                  type: EColorsTypes.FIXED,
                  name: EColorsNames.Yellow,
                  value: 49
                }}
              />
            </div>
          )}
          {outcome.coefficient}
        </StyledInfoIconWrapperContainer>
      </StyledInfoIconWrapper>

      <StyledCellOutcome colSpan={3}>
        <StyledOutcomeResultIndicator outcomeStatus={outcome.status} />
        <BetHistoryStatistic
          currentScore={currentScore}
          scoresByPeriod={scoresByPeriod}
        />
      </StyledCellOutcome>
    </StyledBetContentItem>
  )
}
