import React from 'react'

import { DocumentsGeneralDisplay } from 'widgets/documents-general-display'

export const PageRefundPolicy = () => {
  return (
    <DocumentsGeneralDisplay
      contentLocale="refund policy text"
      titleLocale="refund policy title"
    />
  )
}
