import React, { FC, memo, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import groupBy from 'lodash/groupBy'
import {
  ESportsCodes,
  selectSport,
  selectSports
} from 'astra-core/containers/CommonDataProvider'
import {
  selectFetchItemsLoading,
  selectSearchTournamentsGroupedBySportId,
  selectSearchTournamentsItems,
  selectSearchTournamentsSportList
} from 'astra-core/containers/SearchProvider'
import { useGroupedTournaments } from 'astra-core/hooks/useEvents'

import { RootState } from 'shared/types/store'
import { LoaderPoints } from 'shared/ui/LoaderPoints'
import { SportIcon } from 'shared/ui/Icon/SportIcon'
import { ClearResultSearch } from 'pages/page-live-events/Line/components/ui'
import { StyledLineWrapper } from 'pages/page-live-events/Line/components/LineTournaments/LineTournaments.styled'

import { StyledSearchClearList } from '../SearchList/SearchList.styled'
import { SearchSportList } from '../SearchSportList'
import {
  StyledSearchEventsWrapper,
  StyledSearchEventsName,
  StyledSearchEventsGroups
} from '../SearchEvents/SearchEvents.styled'

import { LineTournament } from './components/LineTournament'
import {
  SearchListSportTournamentsProps,
  SearchListTournamentsProps
} from './SearchTournaments.types'

export const SearchListSportTournaments: FC<SearchListSportTournamentsProps> =
  memo(({ tournamentsGroup }) => {
    const { id } = tournamentsGroup
    const tournaments = groupBy(tournamentsGroup.tournaments, 'top')
    const { code: sportCode } =
      useSelector((state: RootState) => selectSport(state, +id)) || {}

    const Icon = useMemo(
      () => <SportIcon sport={sportCode as ESportsCodes} isActive />,
      [sportCode]
    )

    const topTournaments = tournaments.true || []
    const otherTournaments = tournaments.false || []

    return (
      <StyledSearchEventsGroups>
        <StyledSearchEventsWrapper>
          {Icon}
          <StyledSearchEventsName>
            {tournamentsGroup.name}
          </StyledSearchEventsName>
        </StyledSearchEventsWrapper>
        <SearchListTournaments tournaments={topTournaments} />
        <SearchListTournaments tournaments={otherTournaments} />
      </StyledSearchEventsGroups>
    )
  })

export const SearchListTournaments: FC<SearchListTournamentsProps> = memo(
  ({ tournaments }) => (
    <>
      {tournaments.map((tournament) => (
        <LineTournament key={tournament.id} tournament={tournament} />
      ))}
    </>
  )
)

export const SearchTournaments: FC = memo(() => {
  const [sportId, setSportId] = useState<number>(0)
  const items = useSelector(selectSearchTournamentsItems)
  const sports = useSelector(selectSports)
  const loading = useSelector(selectFetchItemsLoading)
  const sportList = useSelector(selectSearchTournamentsSportList)
  const groupedBySportId = useSelector(selectSearchTournamentsGroupedBySportId)

  const groupedTournaments = useGroupedTournaments(items, sports)

  if (loading) {
    return (
      <StyledSearchClearList>
        <LoaderPoints />
      </StyledSearchClearList>
    )
  }

  if (!groupedTournaments.length) {
    return (
      <StyledSearchClearList>
        <ClearResultSearch />
      </StyledSearchClearList>
    )
  }

  return (
    <StyledLineWrapper>
      <SearchSportList
        setSportId={setSportId}
        sportId={sportId}
        sportList={sportList}
      />
      {sportId ? (
        <SearchListTournaments tournaments={groupedBySportId[sportId]} />
      ) : (
        groupedTournaments.map((tournamentsGroup) => (
          <SearchListSportTournaments
            key={tournamentsGroup.id}
            tournamentsGroup={tournamentsGroup}
          />
        ))
      )}
    </StyledLineWrapper>
  )
})
