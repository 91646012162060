import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconCircleArrowToCenter: FC<IconProps> = (props) => {
  return (
    <Icon {...props}>
      <svg fill="none" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C11.125 0 13.078 0.736473 14.6177 1.9681L8 8.58579V6C8 5.44772 7.55228 5 7 5C6.44772 5 6 5.44772 6 6V11C6 11.1356 6.02699 11.2649 6.07588 11.3828C6.12432 11.4999 6.19595 11.6096 6.29078 11.705C6.29219 11.7064 6.2936 11.7078 6.29502 11.7092C6.48924 11.9023 6.74301 11.9992 6.997 12C6.998 12 6.999 12 7 12H12C12.5523 12 13 11.5523 13 11C13 10.4477 12.5523 10 12 10H9.41421L16.0319 3.38231C17.2635 4.92199 18 6.87499 18 9Z"
          fill="currentColor"
        />
      </svg>
    </Icon>
  )
}
