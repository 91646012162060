import React from 'react'

import { DocumentsGeneralDisplay } from 'widgets/documents-general-display'

export const PagePolicyPrivacy = () => (
  <DocumentsGeneralDisplay
    contentLocale="privacy policy text"
    titleLocale="privacy policy title"
  />
)
