import React, { FC, memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { formatAmount } from 'astra-core'
import {
  betsHistoryProviderActions,
  selectBetsStatisticsList
} from 'astra-core/containers/BetsHistoryProvider'

import { BalanceReportItemProps } from './BalanceReport.types'
import { BETS_STATISTICS_LIST_ITEMS } from './constants'
import {
  StyledItemWrapper,
  StyledWrapper,
  StyledItemTitle,
  StyledItemBottomWrapper,
  StyledItemPrice
} from './BalanceReport.styled'

export const BalanceReportItem: FC<BalanceReportItemProps> = ({
  currencyIcon,
  title,
  statisticsItemData
}) => {
  const [t] = useTranslation()

  return (
    <StyledItemWrapper>
      <StyledItemTitle>{t(title)}</StyledItemTitle>

      <StyledItemBottomWrapper>
        <StyledItemPrice>
          {formatAmount({
            value:
              statisticsItemData?.amount && statisticsItemData.amount / 100,
            placeholder: '...',
            currency: currencyIcon
          })}
        </StyledItemPrice>
        {formatAmount({ value: statisticsItemData?.count, placeholder: '...' })}
        &nbsp;
        {t('bets')}
      </StyledItemBottomWrapper>
    </StyledItemWrapper>
  )
}

export const BalanceReport = memo(() => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(betsHistoryProviderActions.fetchBetsStatistics())
  }, [dispatch])

  const { currencyIcon, betsStatisticsEntries } = useSelector(
    selectBetsStatisticsList
  )

  return (
    <StyledWrapper>
      {BETS_STATISTICS_LIST_ITEMS.map((betsStatisticsListItem) => (
        <BalanceReportItem
          currencyIcon={currencyIcon || ''}
          key={betsStatisticsListItem.key}
          statisticsItemData={betsStatisticsEntries[betsStatisticsListItem.key]}
          title={betsStatisticsListItem.title}
        />
      ))}
    </StyledWrapper>
  )
})
