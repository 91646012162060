import React, { FC, memo, useCallback, useEffect, useState } from 'react'
import { GoogleReCaptcha } from 'react-google-recaptcha-v3'

import { IReCAPTCHA } from './types'

export const Captcha: FC<IReCAPTCHA> = memo(({ onChange, value }) => {
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false)
  useEffect(() => {
    if (!value) {
      setRefreshReCaptcha((r) => !r)
    }
  }, [value])

  const verifyRecaptchaCallback = useCallback(
    (token) => {
      onChange(token)
    },
    [onChange]
  )

  return (
    <GoogleReCaptcha
      refreshReCaptcha={refreshReCaptcha}
      onVerify={verifyRecaptchaCallback}
    />
  )
})
