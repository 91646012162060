import styled from 'styled-components'

export const StyledWrapper = styled.main`
  display: flex;
  flex-direction: column;
  padding: 16px 13px;
`

export const StyledFinanceWrapper = styled.main`
  display: grid;
  flex: 1;
  grid-column-gap: 32px;
  grid-template-columns: 220px 1fr;
  overflow-y: auto;
  padding-top: 12px;
`

export const StyledFinanceContent = styled.div``

export const StyledFinanceTitle = styled.h2`
  color: ${(props) => props.theme.colors.default.primary[90]};
  font-size: ${({ theme }) => theme.fonts.size.xl};
  font-weight: ${({ theme }) => theme.fonts.weight.bold};
  line-height: 28px;
  margin-bottom: 16px;
  margin-top: 0;
  padding-top: 4px;
`
export const LoadingIndicatorContainer = styled.div`
  align-items: center;
  display: flex;
  height: 462px;
  justify-content: center;
`
