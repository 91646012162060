import React from 'react'

import { Layout } from 'widgets/Layout'
import { ELayoutsList } from 'widgets/Layout/Layout.types'

import { Casino } from './Casino'

export const PageCasino = () => (
  <Layout type={ELayoutsList.DEFAULT}>
    <Casino />
  </Layout>
)
