import styled from 'styled-components'

export const StyledCellIcon = styled.td`
  width: 0;

  & > * {
    position: relative;
    top: 2px;
  }
`
