import styled from 'styled-components'
import { Field } from 'formik'

import { IStyledInput } from './Input.types'

export const StyledInputWrapper = styled.div`
  & > input {
    width: 100%;
  }
`

export const StyledInput = styled(Field)<IStyledInput>`
  background: ${(props) => props.theme.colors.default.primary[0]};
  border: 1px solid
    ${(props) =>
      props.$isError
        ? props.theme.colors.fixed.red[0]
        : props.theme.colors.default.primary[20]};
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.custom.primary[54]};
  font-size: ${(props) => props.theme.fonts.size.s};
  font-weight: ${(props) => props.theme.fonts.weight.regular};
  height: 32px;
  line-height: 16px;
  outline: none;
  padding: 8px;
  transition: 0.05s border-color;

  &::placeholder {
    color: ${({ theme }) => theme.colors.custom.primary[54]};
  }

  ${(props) =>
    props.$isError
      ? ''
      : `
          &:focus {
            border-color: ${props.theme.colors.default.primary[20]};
          }
    `}

  &:focus, &:hover, &:active {
    border: 1px solid
      ${(props) =>
        props.$isError
          ? props.theme.colors.fixed.red[0]
          : props.theme.colors.default.primary[90]};
    color: ${(props) => props.theme.colors.default.primary[90]};
  }
`

export const StyledLabel = styled.label`
  display: block;
  line-height: 16px;
  padding-bottom: 4px;

  ${({ theme }) =>
    `
    font-size: ${theme.fonts.size.s};
    font-weight: ${theme.fonts.weight.normal};
    color: ${theme.colors.custom.primary[39]};
  `}
`

export const StyledCustomInputSubtext = styled.span`
  color: ${(props) => props.theme.colors.fixed.red[0]};
  display: block;
  font-size: ${(props) => props.theme.fonts.size.xxs};
  font-weight: ${(props) => props.theme.fonts.weight.normal};
  line-height: 16px;
`
