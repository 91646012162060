import React from 'react'

import { Layout } from 'widgets/Layout'
import { ELayoutsList } from 'widgets/Layout/Layout.types'

import { Statistics } from './Statistics/Statistics'

export const PageStatistics = () => (
  <Layout type={ELayoutsList.SPORT}>
    <Statistics />
  </Layout>
)
