import { LayoutTheme } from 'betweb-openapi-axios'

const DEFAULT_VISA_IMG = require('assets/images/finance/visa.png')

const VISA_ING = {
  [LayoutTheme.Dark]: require('assets/images/finance/visa_dark.png'),
  [LayoutTheme.Light]: DEFAULT_VISA_IMG,
  [LayoutTheme.Unknown]: DEFAULT_VISA_IMG
}

// TODO hard code based on id will be removed after backend changes

enum EIconIDProd {
  EXPRESS_VISA_MC = '3922',
  KWITUM = '3941',
  PHONE = '4028',
  ETHEREUM = '3951',
  TETHER_TRC20 = '3955',
  DOGECOIN = '3956',
  USDT_TRC20 = '3963',
  BITCOIN = '3966',
  USDT_ERC20 = '3967',
  LITECOIN = '3973',
  EXPRESS_VISA_MC_WITHDRAWAL = '3931',
  LITECOIN_WITHDRAWAL = '4006'
}

export const PAYMENT_ID_IMG = {
  card: require('assets/images/finance/card.svg'),
  [EIconIDProd.EXPRESS_VISA_MC]: require('assets/images/finance/card.svg'),
  [EIconIDProd.KWITUM]: require('assets/images/finance/kwitum.svg'),
  [EIconIDProd.PHONE]: require('assets/images/finance/mobile.svg'),
  [EIconIDProd.ETHEREUM]: require('assets/images/finance/ethereum.svg'),
  [EIconIDProd.TETHER_TRC20]: require('assets/images/finance/USDTTrc-20.svg'),
  [EIconIDProd.DOGECOIN]: require('assets/images/finance/dogecoin.svg'),
  [EIconIDProd.USDT_TRC20]: require('assets/images/finance/USDTTrc-20.svg'),
  [EIconIDProd.BITCOIN]: require('assets/images/finance/bitcoin.svg'),
  [EIconIDProd.USDT_ERC20]: require('assets/images/finance/USDTErc-20.svg'),
  [EIconIDProd.LITECOIN]: require('assets/images/finance/ethereum.svg'),
  [EIconIDProd.EXPRESS_VISA_MC_WITHDRAWAL]: require('assets/images/finance/card.svg'),
  [EIconIDProd.LITECOIN_WITHDRAWAL]: require('assets/images/finance/litecoin.svg')
}

export const PAYMENT_IMG = (typeLayoutTheme?: LayoutTheme) => ({
  card: require('assets/images/finance/card.png'),
  qiwi: require('assets/images/finance/qiwi.png'),
  'webmoney-light': require('assets/images/finance/webmoney.png'),
  'card-partner': require('assets/images/finance/card.png'),
  mobile: require('assets/images/finance/mobile.png'),
  sber: require('assets/images/finance/sber.png'),
  yoomoney: require('assets/images/finance/yoomoney.png'),
  mastercard: require('assets/images/finance/mastercard.png'),
  visa: typeLayoutTheme ? VISA_ING[typeLayoutTheme] : DEFAULT_VISA_IMG,
  mir: require('assets/images/finance/mir.png')
})
