import React, { FC } from 'react'

import {
  EEventStatisticScoreTypes,
  EventStatisticScore
} from 'widgets/event-statistics'

import { StyledEventStatisticScore } from './BetHistoryStatistic.styled'
import { IBetHistoryStatisticProps } from './BetHistoryStatistic.types'

export const BetHistoryStatistic: FC<IBetHistoryStatisticProps> = ({
  currentScore,
  scoresByPeriod
}) => (
  <>
    {currentScore && (
      <StyledEventStatisticScore
        type={EEventStatisticScoreTypes.DEFAULT}
        value={currentScore}
      />
    )}

    {scoresByPeriod && (
      <EventStatisticScore
        separator="&nbsp;&nbsp;"
        type={EEventStatisticScoreTypes.BY_PERIOD}
        value={scoresByPeriod}
        wrapSymbol={{ start: '', end: '' }}
      />
    )}
  </>
)
