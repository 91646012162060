import React from 'react'

import { Layout } from 'widgets/Layout'
import { ELayoutsList } from 'widgets/Layout/Layout.types'

import { AboutPage as AboutPageL } from './AboutPage'

export const PageAbout = () => (
  <Layout pageTitle={{ text: 'about company' }} type={ELayoutsList.ABOUT}>
    <AboutPageL />
  </Layout>
)
