import { AppliedCouponStatus } from 'betweb-openapi-axios'
import styled, { css } from 'styled-components'

export const StyledBonusCouponsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 0 0 16px;
  max-width: 769px;
  overflow-y: auto;
`

export const StyledBonusCouponsTitle = styled.div`
  line-height: 32px;
  margin-bottom: 24px;

  ${({ theme }) =>
    css`
      color: ${theme.colors.default.primary[90]};
      font-size: ${theme.fonts.size.xxl};
      font-weight: ${theme.fonts.weight.bold};
    `}
`

export const StyledBonusCouponsImgWrapper = styled.div`
  background-color: black;
  border-radius: 8px;
  height: 164px;
  width: 100%;
`

export const StyledCouponDateWrapper = styled.div`
  display: flex;
  line-height: 16px;
  margin-top: 16px;
  ${({ theme }) =>
    css`
      font-size: ${theme.fonts.size.xs};
      font-weight: ${theme.fonts.weight.normal};
    `}
`

export const StyledCouponDateTill = styled.div<{
  status?: AppliedCouponStatus
}>`
  border-radius: 20px;
  padding: 2px 8px;

  ${({ theme, status }) =>
    status === AppliedCouponStatus.Created
      ? css`
          background-color: ${theme.colors.custom.green[4]};
          color: ${theme.colors.custom.green[6]};
        `
      : css`
          background-color: ${theme.colors.custom.primary[4]};
          color: ${theme.colors.default.primary[90]};
        `}
`

export const StyledCouponDateLeftWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 2.8px;
  padding-left: 10.8px;
`

export const StyledCouponDateLeft = styled.div`
  ${({ theme }) =>
    css`
      color: ${theme.colors.default.primary[50]};
    `}
`

export const StyledCouponBodyDescription = styled.div`
  line-height: 20px;
  margin-top: 16px;

  ${({ theme }) =>
    css`
      color: ${theme.colors.default.primary[50]};
      font-size: ${theme.fonts.size.m};
      font-weight: ${theme.fonts.weight.regular};
    `}
`
