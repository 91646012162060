import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const StyledPromotion = styled.main`
  color: ${({ theme }) => theme.colors.default.primary[90]};
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 10px 0 0 16px;
`

export const StyledPromotionHead = styled(Link)`
  align-items: center;
  color: ${({ theme }) => theme.colors.default.primary[90]};
  cursor: pointer;
  display: grid;
  grid-column-gap: 4px;
  grid-template-columns: 16px 1fr max-content;
  padding: 4px 8px 16px 16px;
`

export const StyledPromotionTitle = styled.span`
  line-height: 16px;
  ${({ theme }) => `
    font-size: ${theme.fonts.size.s};
    font-weight: ${theme.fonts.weight.normal};
  `};
`
