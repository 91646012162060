import { FC } from 'react'
import { BetStatus } from 'betweb-openapi-axios'

import { IconClock } from 'shared/ui/Icon/General/IconClock'
import { IconCheckCircleFilled } from 'shared/ui/Icon/General/IconCheckCircleFilled'
import { IconCircleArrowToCenter } from 'shared/ui/Icon/General/IconCircleArrowToCenter'
import { IconCircleMinus } from 'shared/ui/Icon/General/IconCircleMinus'
import { EColorsNames, EColorsTypes } from 'shared/types/theme'
import { IconBetResult } from 'shared/ui/Icon/General/IconBetResult'
import { ButtonIconProps, EButtonIconPositionSides } from 'shared/ui/Button'

export type TSectionSelectOptions = {
  title: string
  value: BetStatus | null
  icon?: {
    component: FC
    iconProps: ButtonIconProps
  }
}[]

export const SECTION_SELECT_OPTIONS: TSectionSelectOptions = [
  { title: 'all', value: null },
  {
    title: 'current',
    icon: {
      component: IconClock,
      iconProps: {
        positionSide: EButtonIconPositionSides.Left,
        colorProps: {
          name: EColorsNames.Primary,
          type: EColorsTypes.FIXED,
          value: 30
        }
      }
    },
    value: BetStatus.Current
  },
  {
    title: 'history computed',
    icon: {
      component: IconBetResult,
      iconProps: {
        positionSide: EButtonIconPositionSides.Left,
        colorProps: {
          name: EColorsNames.Primary,
          type: EColorsTypes.FIXED,
          value: 60
        }
      }
    },
    value: BetStatus.Computed
  },
  {
    title: 'history won',
    icon: {
      component: IconCheckCircleFilled,
      iconProps: {
        positionSide: EButtonIconPositionSides.Left,
        colorProps: {
          name: EColorsNames.Green,
          type: EColorsTypes.FIXED,
          value: 4
        }
      }
    },
    value: BetStatus.Won
  },
  {
    title: 'history returned',
    icon: {
      component: IconCircleArrowToCenter,
      iconProps: {
        positionSide: EButtonIconPositionSides.Left,
        colorProps: {
          name: EColorsNames.Yellow,
          type: EColorsTypes.FIXED,
          value: 49
        }
      }
    },
    value: BetStatus.Returned
  },
  {
    title: 'history lost',
    icon: {
      component: IconCircleMinus,
      iconProps: {
        positionSide: EButtonIconPositionSides.Left,
        colorProps: {
          name: EColorsNames.Primary,
          type: EColorsTypes.FIXED,
          value: 60
        }
      }
    },
    value: BetStatus.Lost
  }
]
