import { IUseTimerProps } from 'astra-core/hooks/useTimer'
import * as Yup from 'yup'

export enum EFormikFields {
  SMS_CODE = 'smsCode'
}

export const FORMIK_SCHEMA_VALIDATION = Yup.object().shape({
  [EFormikFields.SMS_CODE]: Yup.string().required('required')
})

export const GET_TIMER_PROPS = (value?: number): IUseTimerProps => ({
  name: 'SmsConfirmationModal_date_time_end1',
  value: value || 60,
  formatValue: 's',
  formatResult: 'mm:ss'
})
