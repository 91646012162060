import React, { FC, memo } from 'react'
import { dayjsCore as dayjs } from 'astra-core/utils/dayjs'

import { EFontsWeight } from 'shared/types/theme'

import { DATE_CELL } from './constants'
import { ICellDate } from './CellDate.types'
import { StyledCellDate } from './CellDate.styled'

export const CellDate: FC<ICellDate> = memo(({ value }) => {
  const item = dayjs(value).format(DATE_CELL)

  return (
    <StyledCellDate fontWeight={EFontsWeight.NORMAL}>{item}</StyledCellDate>
  )
})
