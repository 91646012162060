import { LayoutTheme } from 'betweb-openapi-axios'
import styled from 'styled-components'

import { IconErrorOffline } from 'shared/ui/Icon/General/IconErrorOffline'

export const StyledErrorContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  width: 100vw;
  ${({ theme }) => `
    ${
      theme.typeLayoutTheme === LayoutTheme.Dark
        ? 'background: #1A1A1A;'
        : 'background: #fff;'
    }
    `};
`

export const StyledIconError = styled(IconErrorOffline)`
  & > svg:nth-child(1) {
    ${({ theme }) => `
    ${
      theme.typeLayoutTheme === LayoutTheme.Dark
        ? 'fill: #333;'
        : 'fill: #E6E6E6;'
    }
    `};
  }
`
export const StyledErrorTitle = styled.h1`
  ${({ theme }) => `
color: ${theme.colors.default.primary[90]};
font-size: ${theme.fonts.size.l};
font-weight: ${theme.fonts.weight.normal};
`};
  margin-top: 8px;
`
export const StyledErrorDescr = styled.p`
  max-width: 300px;
  text-align: center;
  ${({ theme }) => `
  ${
    theme.typeLayoutTheme === LayoutTheme.Dark
      ? 'color: #B3B3B3;'
      : 'color: #808080;'
  }
font-size: ${theme.fonts.size.s};
font-weight: ${theme.fonts.weight.regular};
`};
`
export const StyledReloadBtn = styled.button`
  border: none;
  border-radius: 2px;
  cursor: pointer;
  padding: 4px 8px;
  ${({ theme }) => `
  ${
    theme.typeLayoutTheme === LayoutTheme.Dark
      ? 'color: #B3B3B3; background: #333333;'
      : 'color: #1A1A1A; background: #F2F2F2;'
  }
  font-size: ${theme.fonts.size.s};
  font-weight: ${theme.fonts.weight.normal};
  line-height: 16px;
`};
`
