import styled from 'styled-components'

import { IStyledCell } from './Cell.types'

export const StyledCell = styled.td<IStyledCell>`
  color: ${(props) => props.theme.colors.default.primary[90]};
  font-size: ${(props) => props.theme.fonts.size.s};
  font-weight: ${(props) => props.theme.fonts.weight.semiBold};
  padding: 12px 8px;
  white-space: nowrap;

  ${(props) => `  
    ${props.alignData ? `text-align: ${props.alignData};` : ''}
    
    ${props.width ? `width: ${props.width};` : ''}
    
    ${
      props.fontWeight
        ? `font-weight: ${props.theme.fonts.weight[props.fontWeight]};`
        : ''
    }
  `}
`
