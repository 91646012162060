import React from 'react'

import { Layout, ELayoutsList } from 'widgets/Layout'

import { NotificationsPage } from './NotificationsPage'

export const PageNotification = () => (
  <Layout pageTitle={{ text: 'notifications' }} type={ELayoutsList.PROFILE}>
    <NotificationsPage />
  </Layout>
)
