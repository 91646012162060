import styled, { css } from 'styled-components'

import { Input } from 'shared/ui/Input'
import { Button } from 'shared/ui/Button'

export const StyledProfile = styled.main`
  padding: 10px 0 0 16px;
`

export const ProfileInput = styled(Input)`
  width: 188px;
`

export const StyledNoticeProfile = styled.div`
  margin-bottom: 2rem;
`

export const StyledNoticeProfileButton = styled(Button)`
  color: ${(p) => p.theme.colors.default.primary[70]};
  display: inline;
  font-size: ${(p) => p.theme.fonts.size.s};
  font-weight: ${(props) => props.theme.fonts.weight.regular};
  text-decoration: underline;
`

export const StyledChangePasswordButton = styled(Button)`
  line-height: 20px;

  ${({ theme }) =>
    css`
      font-size: ${theme.fonts.size.m};
      font-weight: ${theme.fonts.weight.normal};
    `}
`
