import styled, { css } from 'styled-components'

import { EventStatisticScore } from 'widgets/event-statistics'

export const StyledEventStatisticScore = styled(EventStatisticScore)`
  ${(props) => css`
    color: ${props.theme.colors.default.primary[90]};
    font-weight: ${props.theme.fonts.weight.normal};

    && {
      margin-left: 4px;
      margin-right: 8px;
    }
  `}
`
