import {
  EPBTabs,
  PBTabsType
} from 'pages/page-promotion-and-bonuses/PromotionsAndBonuses/PromotionsAndBonuses.types'

import {
  StyledIconBonusCoupons,
  StyledIconPromotions
} from './PromotionsTabs.styled'

export const getIsActiveTab = (tab: PBTabsType, currentTab: PBTabsType) =>
  tab === currentTab

export const GET_TAB_ITEM_LIST = (isUserAuthorized: boolean) => [
  {
    name: EPBTabs.PROMOTIONS,
    icon: StyledIconPromotions,
    locale: 'promotions'
  },
  // {
  //   name: EPBTabs.BONUSES,
  //   icon: StyledIconBonuses,
  //   locale: 'bonuses'
  // },
  ...(isUserAuthorized
    ? [
        {
          name: EPBTabs.BONUS_COUPONS,
          icon: StyledIconBonusCoupons,
          locale: 'bonus coupons'
        }
      ]
    : [])
]
