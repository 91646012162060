import React, { FC, useEffect, useMemo, useState } from 'react'
import { OptionalParameter, OptionalParameterType } from 'betweb-openapi-axios'
import { useTranslation } from 'react-i18next'

import { Input } from 'shared/ui/Input'
import { Button } from 'shared/ui/Button'

import {
  StyledAddressBlock,
  StyledImage,
  StyledImageFrame,
  StyledParameter,
  StyledParametersContainer,
  StyledParametersHelper,
  StyledParametersTitle,
  StyledWarningMessage
} from './PaymentParameters.styled'

interface Props {
  parameters: OptionalParameter[]
}

interface ImageParameterProps {
  value?: string
}

const ImageParameter: FC<ImageParameterProps> = ({ value }) => {
  return (
    <StyledImageFrame>
      <StyledImage alt="qr code" src={`data:image/png;base64,${value}`} />
    </StyledImageFrame>
  )
}

const COPY_RESET_TIMEOUT = 5 * 1000

export const FormParameters: FC<Props> = ({ parameters }) => {
  const [isCopied, setIsCopied] = useState(false)
  const { t } = useTranslation()

  const minimumAmountParameter = parameters.find(
    (p) => p.parameter_type === OptionalParameterType.DoubleParameter
  )

  const addressParameter = parameters.find((p) => p.name === 'Address')

  const qrParameter = parameters.find(
    (p) => p.parameter_type === OptionalParameterType.ImageParameter
  )

  const handleCopyAddress = () => {
    if (typeof addressParameter?.value === 'string') {
      window.navigator.clipboard.writeText(addressParameter.value)
      setIsCopied(true)
    }
  }

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => setIsCopied(false), COPY_RESET_TIMEOUT)
    }
  }, [isCopied])

  const buttonText = useMemo(
    () => t(isCopied ? 'copied' : 'copy'),
    [isCopied, t]
  )

  return (
    <>
      <StyledParametersContainer>
        {!!minimumAmountParameter && (
          <StyledParametersTitle
            dangerouslySetInnerHTML={{
              __html: t('can send coin to the next wallet', {
                amount: minimumAmountParameter.value,
                currency: minimumAmountParameter.name
              })
            }}
          />
        )}
        {!!addressParameter && (
          <StyledParameter>
            <StyledParametersHelper>
              {t('wallet link', { currency: minimumAmountParameter?.name })}
            </StyledParametersHelper>
            <StyledAddressBlock>
              <Input
                size={String(addressParameter.value).length}
                value={String(addressParameter.value)}
                readOnly
              />
              <Button disabled={isCopied} onClick={handleCopyAddress}>
                {buttonText}
              </Button>
            </StyledAddressBlock>
          </StyledParameter>
        )}
        {!!qrParameter && (
          <StyledParameter>
            <StyledParametersHelper>{t('qr code')}</StyledParametersHelper>
            <ImageParameter value={String(qrParameter?.value)} />
          </StyledParameter>
        )}
      </StyledParametersContainer>
      <StyledWarningMessage
        dangerouslySetInnerHTML={{ __html: t('crypto warning message') }}
      />
    </>
  )
}
