import React, { FC } from 'react'

import { IconSelect } from '../../../IconSelect'

import { ICellIcon } from './CellIcon.types'
import { StyledCellIcon } from './CellIcon.styled'

export const CellIcon: FC<ICellIcon> = ({ betStatus }) => (
  <StyledCellIcon>
    <IconSelect betStatus={betStatus} />
  </StyledCellIcon>
)
