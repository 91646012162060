import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconError404: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="64"
      viewBox="0 0 64 64"
      width="64"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 8C0 3.58172 3.58172 0 8 0H56C60.4183 0 64 3.58172 64 8V56C64 60.4183 60.4183 64 56 64H8C3.58172 64 0 60.4183 0 56V8Z" />
      <path
        d="M50.0278 18.8C50.0278 17.5 49.1278 16.5 47.8278 16.5L34.5278 16L33.9278 23.5L33.5278 28.3L36.5278 31.7L34.9278 42.5L34.7278 44L46.5278 44.4H46.9278C48.2278 44.4 49.2278 43.5 49.2278 42.2L49.9278 22.5L50.0278 18.8ZM47.4278 19.1C48.0278 19.1 48.4278 19.6 48.4278 20.2C48.4278 20.8 47.9278 21.2 47.3278 21.2C46.7278 21.2 46.3278 20.7 46.3278 20.1C46.3278 19.6 46.8278 19.1 47.4278 19.1ZM44.3278 19C44.9278 19 45.3278 19.5 45.3278 20.1C45.3278 20.7 44.8278 21.1 44.2278 21.1C43.6278 21.1 43.2278 20.6 43.2278 20C43.2278 19.4 43.7278 19 44.3278 19ZM41.2278 18.9C41.8278 18.9 42.2278 19.4 42.2278 20C42.2278 20.6 41.7278 21 41.1278 21C40.5278 21 40.1278 20.5 40.1278 19.9C40.1278 19.3 40.6278 18.9 41.2278 18.9Z"
        fill="currentColor"
      />
      <path
        d="M33.6278 45V43.5L33.4278 32.6L29.9278 29.8L29.4278 25L28.7278 17.5L16.0278 19.2C14.7278 19.4 13.8278 20.6 14.0278 21.8L14.5278 25.5L17.2278 45C17.2278 46.2901 18.7524 47 19.8278 47L33.6278 45Z"
        fill="currentColor"
      />
    </svg>
  </Icon>
)
