import { getCurrentLng } from 'astra-core/utils'

export const getIframeData = ({ srcData: { token, userId, launchUrl } }) => {
  const langName = getCurrentLng()
  const tokenParam = userId && token ? `&sessionToken=${token}` : ''

  const src = `${launchUrl}?cid=astrabetcom&productId=sport-games-lobby${tokenParam}&lang=${langName}`

  return {
    id: 'sportgames-iframe',
    styles: {
      width: '100%',
      height: '100vh',
      border: 'none',
      outline: 'none',
      font: 'inherit',
      verticalAlign: 'baseline'
    },
    src
  }
}
