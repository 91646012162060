import { Field } from 'formik'
import styled from 'styled-components'

import { IStyledInput } from './types'

export const StyledInputWrapper = styled.div`
  & > input {
    width: 100%;
  }
`

export const StyledInput = styled(Field)<IStyledInput>`
  background: ${(props) => props.theme.colors.default.primary[0]};
  border: 1px solid
    ${(props) =>
      props.$isError
        ? props.theme.colors.default.red[50]
        : props.theme.colors.default.primary[10]};
  border-radius: 4px;
  color: ${(props) => props.theme.colors.default.primary[90]};
  font-size: ${(props) => props.theme.fonts.size.s};
  height: 32px;
  outline: none;
  padding: 8px;
  transition: 0.05s border-color;

  &::placeholder {
    color: ${(props) => props.theme.colors.default.primary[30]};
    font-weight: ${(props) => props.theme.fonts.weight.regular};
    line-height: 16px;
  }

  &:hover {
    border-color: ${(props) => props.theme.colors.default.primary[40]};
    transition: 0.05s border-color;
  }

  ${(props) =>
    props.$isError
      ? ''
      : `
          &:focus {
            border-color: ${props.theme.colors.default.primary[90]};
          }
    `}
`

export const StyledLabel = styled.label`
  color: ${(props) => props.theme.colors.default.primary[70]};
  display: block;
  font-size: ${(props) => props.theme.fonts.size.s};
  font-weight: ${(props) => props.theme.fonts.weight.normal};
  line-height: 16px;
  padding-bottom: 4px;
`

export const StyledCustomInputSubtext = styled.span`
  color: ${(props) => props.theme.colors.default.red[50]};
  display: block;
  font-size: ${(props) => props.theme.fonts.size.xxs};
  font-weight: ${(props) => props.theme.fonts.weight.normal};
  line-height: 16px;
`
