import styled, { css } from 'styled-components'

import { IStyledSlideControlItem } from './SlideControl.types'

export const StyledSlideControl = styled.div`
  display: flex;
  height: 10px;
  justify-content: center;
  width: 100%;
`

export const StyledSlideControlItem = styled.div<IStyledSlideControlItem>`
  background: ${(props) =>
    props.theme.colors.default.primary[props.$isActiveItem ? 90 : 10]};
  border-radius: 50%;
  cursor: pointer;
  height: 5px;
  width: 5px;

  &:not(:last-of-type) {
    margin-right: 8px;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `};
`
