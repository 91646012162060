import styled from 'styled-components'

export const StyledMenu = styled.div`
  display: flex;
  flex-direction: column;
  grid-row-gap: 8px;
  width: 220px;
`

export const StyledMenuWrapper = styled.div``

export const StyledMenuGroupTitleWrapper = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  line-height: 20px;
  padding: 4px 0;
  text-transform: capitalize;
  ${({ theme }) =>
    `
    font-size: ${theme.fonts.size.m};
    font-weight: ${theme.fonts.weight.normal};
    color: ${theme.colors.default.primary[90]};
  `}
`
