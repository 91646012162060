import React from 'react'

import { Layout } from 'widgets/Layout'
import { ELayoutsList } from 'widgets/Layout/Layout.types'

import { Profile } from './Profile'

export const PageProfile = () => (
  <Layout pageTitle={{ text: 'profile of user' }} type={ELayoutsList.PROFILE}>
    <Profile />
  </Layout>
)
