import styled from 'styled-components'

import { Select } from 'shared/ui/Select'

export const StyledFilters = styled.div`
  align-items: center;
  column-gap: 15px;
  display: flex;
`

export const StyledSelectPeriod = styled(Select)`
  width: 95px;
`

export const StyledTypesTabsWrapper = styled.div`
  border: 1px solid ${(props) => props.theme.colors.custom.primary[4]};
  border-radius: 4px;
`
