import styled from 'styled-components'

export const StyledEmptyPaymentListWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
`

export const StyledEmptyPaymentListText = styled.div`
  line-height: 20px;
  ${({ theme }) =>
    `
    font-size: ${theme.fonts.size.l};
    font-weight: ${theme.fonts.weight.normal};
    color: ${theme.colors.default.primary[90]};
  `}
`
