/* eslint-disable react/no-danger */
import React, { FC } from 'react'

import { Layout } from 'widgets/Layout'
import { ELayoutsList } from 'widgets/Layout/Layout.types'

import { SearchContainer } from './SearchContainer'

export const PageSearch: FC = () => (
  <Layout type={ELayoutsList.SPORT}>
    <SearchContainer />
  </Layout>
)
