import React from 'react'

import { Layout } from 'widgets/Layout'
import { ELayoutsList } from 'widgets/Layout/Layout.types'

import { Balance } from './Balance'

export const PageBalance = () => {
  return (
    <Layout pageTitle={{ text: 'balance' }} type={ELayoutsList.PROFILE}>
      <Balance />
    </Layout>
  )
}
