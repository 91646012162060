import { PayloadAction } from '@reduxjs/toolkit'

import { createSlice } from 'shared/lib/@reduxjs/toolkit'

import { ContainerState, SetFilterSportsTypePayload } from './types'

const REDUCER_KEY = 'favouriteEventsContainer'

export const initialState: ContainerState = {
  filters: {
    sportsType: null
  }
}

const favouriteEventsContainerSlice = createSlice({
  name: REDUCER_KEY,
  initialState,
  reducers: {
    setFilterSportsType(
      state,
      { payload }: PayloadAction<SetFilterSportsTypePayload>
    ) {
      state.filters.sportsType = payload
    }
  }
})

export const {
  actions: favouriteEventsContainer,
  reducer,
  name: sliceKey
} = favouriteEventsContainerSlice
