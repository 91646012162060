import styled from 'styled-components'

export const StyledChampionshipsWrapper = styled.div``

export const StyledChampionshipWrapper = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.colors.custom.primary[31]};
  display: flex;
  height: 24px;
  padding: 0 16px;
  width: 100%;
`

export const StyledChampionshipName = styled.div`
  line-height: 16px;
  margin-left: 4px;
  margin-right: auto;

  ${({ theme }) =>
    `
      font-size: ${theme.fonts.size.s};
      font-weight: ${theme.fonts.weight.bold};
      color: ${theme.colors.default.primary[90]};
    `}
`
