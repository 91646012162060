import React, { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  balanceProviderActions,
  EBalanceWithdrawModalWindowType,
  selectCreatedWithdrawalError,
  selectWithdrawalFetchResetSms
} from 'astra-core/containers/BalanceProvider'
import { selectAccountUser } from 'astra-core/containers/AccountProvider'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useResetTimer, useTimer } from 'astra-core/hooks'

import { EColorsNames } from 'shared/types/theme'
import { Modal, ViewComponentType } from 'shared/ui/modal'

import {
  StyledSMSCodeInput,
  StyledSmsConfirmationButton,
  StyledSmsConfirmationCodeNotReceivedLink,
  StyledSmsConfirmationCodeNotReceivedText,
  StyledSmsConfirmationCodeTimer,
  StyledSmsConfirmationModalHeaderExit,
  StyledSmsConfirmationModalHeaderTitle,
  StyledSmsConfirmationModalHeaderWrapper,
  StyledSmsConfirmationModalPhone,
  StyledSmsConfirmationModalWrapper,
  StyledSmsConfirmationReRequestWrapper
} from './SmsConfirmationModal.styled'
import {
  EFormikFields,
  FORMIK_SCHEMA_VALIDATION,
  GET_TIMER_PROPS
} from './constants'

export const SmsConfirmationModal = () => {
  const dispatch = useDispatch()
  const [t] = useTranslation()
  const user = useSelector(selectAccountUser)
  const paymentError = useSelector(selectCreatedWithdrawalError)
  const resetTimer = useSelector(selectWithdrawalFetchResetSms)
  const { timer, restart, isTimerFinish } = useTimer(
    GET_TIMER_PROPS(resetTimer)
  )

  useResetTimer(timer, resetTimer, restart)

  console.log('timer', timer)
  console.log('isTimerFinish', isTimerFinish)
  console.log('111111-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-1111111')

  const handleCloseModalWindow = useCallback(() => {
    dispatch(
      balanceProviderActions.changeWithdrawModal({
        modalType: EBalanceWithdrawModalWindowType.OFF
      })
    )
  }, [dispatch])

  const handleReRequestSmsCode = () => {
    dispatch(balanceProviderActions.fetchVerificationIdentifier())
    restart()
  }

  const handleCreateWithdrawal = useCallback(() => {
    dispatch(balanceProviderActions.createWithdrawal())
  }, [dispatch])

  const handleChangeInput = useCallback(
    ({ target }) => {
      dispatch(balanceProviderActions.setWithdrawalSmsCode(target.value))
    },
    [dispatch]
  )

  const paymentErrorTitle = useMemo(
    () => (paymentError?.title ? paymentError?.title : null),
    [paymentError?.title]
  )

  return (
    <Modal
      view={ViewComponentType.PAYMENT}
      isOpen
      onModalClose={handleCloseModalWindow}
    >
      <StyledSmsConfirmationModalWrapper>
        <StyledSmsConfirmationModalHeaderWrapper>
          <StyledSmsConfirmationModalHeaderTitle>
            {t('operation confirmation')}
          </StyledSmsConfirmationModalHeaderTitle>
          <StyledSmsConfirmationModalHeaderExit
            colorProps={{ name: EColorsNames.Primary, value: 90 }}
            size={10}
            onClick={handleCloseModalWindow}
          />
        </StyledSmsConfirmationModalHeaderWrapper>
        <StyledSmsConfirmationModalPhone>
          {t('we sent sms with code')} {user?.phone}
        </StyledSmsConfirmationModalPhone>

        <Formik
          initialValues={{ [EFormikFields.SMS_CODE]: '' }}
          validationSchema={FORMIK_SCHEMA_VALIDATION}
          onSubmit={handleCreateWithdrawal}
        >
          <Form>
            <StyledSMSCodeInput
              errorMessage={paymentErrorTitle}
              label={t('code from sms')}
              maxLength={4}
              name={EFormikFields.SMS_CODE}
              placeholder="••••"
              type="number"
              onChange={handleChangeInput}
            />
            {isTimerFinish && (
              <StyledSmsConfirmationReRequestWrapper>
                <StyledSmsConfirmationCodeNotReceivedText>
                  {t('code did not come')}
                </StyledSmsConfirmationCodeNotReceivedText>
                <StyledSmsConfirmationCodeNotReceivedLink
                  timer={timer}
                  onClick={handleReRequestSmsCode}
                >
                  {t('send code again')}
                </StyledSmsConfirmationCodeNotReceivedLink>
              </StyledSmsConfirmationReRequestWrapper>
            )}
            {!isTimerFinish && (
              <StyledSmsConfirmationCodeTimer>
                {t('can request the code again')} {timer}
              </StyledSmsConfirmationCodeTimer>
            )}
            <StyledSmsConfirmationButton type="submit">
              {t('send')}
            </StyledSmsConfirmationButton>
          </Form>
        </Formik>
      </StyledSmsConfirmationModalWrapper>
    </Modal>
  )
}
