import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  EAuthenticationSteps,
  selectAuthenticationModalCurrentStep
} from 'astra-core/containers/AccountProvider'

import { FullRegistrationStepOne } from './FullRegistrationStepOne'
import { FullRegistrationStepTwo } from './FullRegistrationStepTwo'

const fullRegistrationSteps = {
  [EAuthenticationSteps.ONE]: <FullRegistrationStepOne />,
  [EAuthenticationSteps.TWO]: <FullRegistrationStepTwo />
}

export const FullRegistration: FC = () => {
  const authenticationModalCurrentStep = useSelector(
    selectAuthenticationModalCurrentStep
  )

  return useMemo(() => {
    return authenticationModalCurrentStep
      ? fullRegistrationSteps[authenticationModalCurrentStep]
      : null
  }, [authenticationModalCurrentStep])
}
