import React from 'react'

import { DocumentsGeneralDisplay } from 'widgets/documents-general-display'

export const PageResponsibleGambling = () => (
  <DocumentsGeneralDisplay
    contentLocale="responsible gambling text"
    titleLocale="responsible gambling title"
  />
)
