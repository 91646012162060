import React, { FC, memo, useMemo } from 'react'
import { Column, useTable } from 'react-table'
import { useTranslation } from 'react-i18next'

import { TABLE_COLUMNS } from './constants'
import {
  StyledHeaderCell,
  StyledRow,
  StyledRowCell,
  StyledTable
} from './Table.styled'
import { TableData, TableProps } from './Table.types'

export const Table: FC<TableProps> = memo(({ data }) => {
  const [t] = useTranslation()
  const columns = useMemo(() => TABLE_COLUMNS as Column<TableData>[], [])

  const { headerGroups, getTableProps, getTableBodyProps, rows, prepareRow } =
    useTable<TableData>({ columns, data })

  return (
    <StyledTable {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <StyledHeaderCell {...column.getHeaderProps()}>
                {t(column.render('Header') as string)}
              </StyledHeaderCell>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row)
          return (
            <StyledRow {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <StyledRowCell {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </StyledRowCell>
                )
              })}
            </StyledRow>
          )
        })}
      </tbody>
    </StyledTable>
  )
})
