/* eslint-disable react/no-danger */
import React, { FC, useEffect } from 'react'
import { useParams } from 'react-router-dom'

interface Params {
  sourceEventId: string
}

export const PageHeadToHead: FC = () => {
  const { sourceEventId } = useParams<Params>()

  useEffect(() => {
    window.SIR('addWidget', '#head-to-head-widget', 'headToHead.standalone', {
      matchId: sourceEventId
    })
  }, [sourceEventId])

  return <div id="head-to-head-widget" />
}
