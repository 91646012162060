import React from 'react'
import { useSelector } from 'react-redux'
import {
  EBalanceWithdrawModalWindowType,
  selectWithdrawModalType
} from 'astra-core/containers/BalanceProvider'

import { SmsConfirmationModal } from 'pages/page-balance/Balance/components/SmsConfirmationModal'

export const ModalSmsConfirmation = () => {
  const withdrawModalType = useSelector(selectWithdrawModalType)

  return withdrawModalType === EBalanceWithdrawModalWindowType.SMS ? (
    <SmsConfirmationModal />
  ) : null
}
