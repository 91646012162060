import styled from 'styled-components'

import { InputForm } from 'shared/ui/InputForm'

export const StyledFormContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.custom.primary[31]};
  border-radius: 8px;
  padding: 16px;
  width: 462px;
`

export const StyledFormTitle = styled.div`
  line-height: 16px;
  margin-bottom: 4px;

  ${({ theme }) => `
    color: ${theme.colors.default.primary[50]};
    font-size: ${theme.fonts.size.s};
    font-weight: ${theme.fonts.weight.normal};
  `};
`

export const StyledFormGroup = styled.div`
  display: grid;
  grid-column-gap: 8px;
  grid-template-columns: 157px auto;
  margin-bottom: 4px;
`

export const StyledFormHelper = styled.div`
  color: ${({ theme }) => theme.colors.custom.primary[33]};
  display: grid;
  font-size: ${({ theme }) => theme.fonts.size.xs};
`

export const StyledInputPhone = styled(InputForm)`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    display: none;
  }

  margin-bottom: 16px;
  width: 164px;
`

export const StyledInput = styled(InputForm)`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    display: none;
  }
`

export const StyledLoaderPointsWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 72px;
`

export const StyledWithdrawalFormMethodsTitle = styled.div`
  line-height: 20px;
  margin-bottom: 16px;

  ${({ theme }) => `
    color: ${theme.colors.default.primary[70]};
    font-size: ${theme.fonts.size.m};
    font-weight: ${theme.fonts.weight.normal};
  `};
`
