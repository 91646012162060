import { AppliedCouponStatus } from 'betweb-openapi-axios'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const StyledCouponsWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 16px;
  overflow-y: auto;
`

export const StyledCouponWrapper = styled.div`
  width: 284px;
`

export const StyledCouponImageWrapper = styled.div`
  background-color: black;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: 110px;
`

export const StyledCouponBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;

  ${({ theme }) =>
    css`
      background-color: ${theme.colors.custom.primary[2]};
      border-left: 1px solid ${theme.colors.custom.primary[8]};
      border-right: 1px solid ${theme.colors.custom.primary[8]};
    `}

  &:hover {
    box-shadow: 0 0 11px rgb(33 33 33 / 20%);
  }
`

export const StyledCouponDateWrapper = styled.div`
  display: flex;
  line-height: 16px;
  ${({ theme }) =>
    css`
      font-size: ${theme.fonts.size.xs};
      font-weight: ${theme.fonts.weight.normal};
    `}
`

export const StyledCouponDateTill = styled.div<{
  status?: AppliedCouponStatus
}>`
  border-radius: 20px;
  padding: 2px 8px;

  ${({ theme, status }) =>
    status === AppliedCouponStatus.Created
      ? css`
          background-color: ${theme.colors.custom.green[4]};
          color: ${theme.colors.custom.green[6]};
        `
      : css`
          background-color: ${theme.colors.custom.primary[4]};
          color: ${theme.colors.default.primary[90]};
        `}
`

export const StyledCouponDateLeftWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 2.8px;
  padding-left: 10.8px;
`

export const StyledCouponDateLeft = styled.span`
  ${({ theme }) =>
    css`
      color: ${theme.colors.default.primary[50]};
    `}
`

export const StyledCouponBodyTitle = styled.div`
  height: 20px;
  line-height: 20px;
  margin: 8px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ theme }) =>
    css`
      color: ${theme.colors.default.primary[90]};
      font-size: ${theme.fonts.size.l};
      font-weight: ${theme.fonts.weight.normal};
    `}
`

export const StyledCouponBodyDescription = styled.div`
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: block;
  height: 60px;
  line-height: 20px;
  overflow: hidden;

  ${({ theme }) =>
    css`
      color: ${theme.colors.default.primary[50]};
      font-size: ${theme.fonts.size.m};
      font-weight: ${theme.fonts.weight.regular};
    `}
`

export const StyledCouponLeftEllipse = styled.div`
  border-radius: 50%;
  height: 9px;
  left: -6.5px;
  position: absolute;
  top: -6px;
  width: 9px;

  ${({ theme }) =>
    css`
      background-color: ${theme.colors.custom.primary[2]};
      border-right: 1px solid ${theme.colors.custom.primary[8]};
    `}
`

export const StyledCouponRightEllipse = styled.div`
  border-radius: 50%;
  height: 9px;
  position: absolute;
  right: -6.5px;
  top: -6px;
  width: 9px;

  ${({ theme }) =>
    css`
      background-color: ${theme.colors.custom.primary[2]};
      border-left: 1px solid ${theme.colors.custom.primary[8]};
    `}
`

export const StyledCouponBottom = styled(Link)`
  align-items: center;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
  height: 41px;
  justify-content: center;
  position: relative;

  ${({ theme }) =>
    css`
      background-color: ${theme.colors.custom.primary[2]};
      border-bottom: 1px solid ${theme.colors.custom.primary[8]};
      border-left: 1px solid ${theme.colors.custom.primary[8]};
      border-right: 1px solid ${theme.colors.custom.primary[8]};
      border-top: 1.9px dashed ${theme.colors.custom.primary[8]};
    `}
`

export const StyledCouponBottomText = styled.p`
  ${({ theme }) =>
    css`
      color: ${theme.colors.default.primary[70]};
    `}
`
