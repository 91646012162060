import styled, { css } from 'styled-components'

import { IconClose } from 'shared/ui/Icon/General/IconClose'

export const StyledBalanceModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 312px;
  width: 400px;
`

export const StyledBalanceModalHeaderWrapper = styled.div`
  align-items: flex-end;
  display: flex;
  height: 40px;
  justify-content: flex-end;
`

export const StyledBalanceModalHeaderExit = styled(IconClose)`
  cursor: pointer;
  margin-right: 20px;
`

export const StyledBalanceModalHeaderWrapper2 = styled.div``

export const StyledBalanceModalDescriptionWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 56px;
`

export const StyledBalanceModalDescriptionTitle = styled.div`
  line-height: 20px;
  margin: 16px 0 4px 0;

  ${({ theme }) =>
    css`
      color: ${theme.colors.default.primary[90]};
      font-size: ${theme.fonts.size.m};
      font-weight: ${theme.fonts.weight.bold};
    `}
`

export const StyledBalanceModalDescriptionBackendError = styled.div`
  line-height: 20px;
  margin-bottom: 4px;
  text-align: center;

  ${({ theme }) =>
    css`
      color: ${theme.colors.default.primary[50]};
      font-size: ${theme.fonts.size.m};
      font-weight: ${theme.fonts.weight.regular};
    `}
`

export const StyledBalanceModalDescriptionReason = styled.div`
  line-height: 20px;
  margin-bottom: 4px;

  ${({ theme }) =>
    css`
      color: ${theme.colors.default.primary[50]};
      font-size: ${theme.fonts.size.m};
      font-weight: ${theme.fonts.weight.regular};
    `}
`

export const StyledBalanceModalDescriptionAction = styled.div`
  cursor: pointer;
  line-height: 20px;

  ${({ theme }) =>
    css`
      color: ${theme.colors.custom.primary[40]};
      font-size: ${theme.fonts.size.m};
      font-weight: ${theme.fonts.weight.regular};
    `}
`
