import React, { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  accountProviderActions,
  selectAccountId,
  selectAccountUser,
  selectFetchLiveGamesTokenRequest,
  selectFetchUserRequest,
  selectLiveGamesToken,
  selectTwainGamesToken
} from 'astra-core/containers/AccountProvider'
import { useTranslation } from 'react-i18next'
import { selectSettingsDataProp } from 'astra-core/containers/SettingsProvider'
import { selectConfig } from 'astra-core/containers/ConfigProvider'
import dayjs from 'dayjs'

import { RootState } from 'shared/types/store'
import { LoaderPoints } from 'shared/ui/LoaderPoints'
import { removeAllChildren } from 'shared/lib/dom'

import {
  StyledTwainGames,
  StyledLoaderPointsWrapper
} from './TwainGames.styled'

export const TwainGames: FC = () => {
  const dispatch = useDispatch()
  const user = useSelector(selectAccountUser)
  const accountId = useSelector(selectAccountId)
  const { loading: fetchUserLoading } = useSelector(selectFetchUserRequest)
  const { loading: fetchLiveGamesTokenLoading } = useSelector(
    selectFetchLiveGamesTokenRequest
  )
  const { token: liveGamesToken } = useSelector(selectLiveGamesToken) || {}

  useEffect(() => {
    if (accountId && !liveGamesToken) {
      dispatch(
        accountProviderActions.getTwainGamesToken({
          accountId
        })
      )
    }
  }, [accountId, dispatch, liveGamesToken])

  const loaded =
    fetchUserLoading === false &&
    (!user || fetchLiveGamesTokenLoading === false)

  return loaded ? (
    <LiveGamesContainer />
  ) : (
    <StyledLoaderPointsWrapper>
      <LoaderPoints />
    </StyledLoaderPointsWrapper>
  )
}

const TWAIN_GAMES_CONTAINER_ID = 'twain_sport_container'

const removeTwainGames = (twainGamesScript?: HTMLScriptElement) => {
  if (twainGamesScript) {
    document.head.removeChild(twainGamesScript)
  }
  const twainGamesContainer = document.getElementById(TWAIN_GAMES_CONTAINER_ID)
  console.log('---------twainGamesContainer', twainGamesContainer) // todo remove after debug
  if (twainGamesContainer) {
    removeAllChildren(twainGamesContainer) // To remove the iFrame added by TwainGames script
  }
}

const removeTwainGamesIframe = (twainGamesScript: HTMLScriptElement) => {
  // Use a MutationObserver to listen for changes to the DOM
  const observer = new MutationObserver(function (mutations) {
    for (let mutation of mutations) {
      for (let removedNode of Array.from(mutation.removedNodes)) {
        console.log('removedNode', removedNode) // todo remove after debug
        if (removedNode === twainGamesScript) {
          console.log('Twain Games script has been removed!') // todo remove after debug
          const twainGamesContainer = document.getElementById(
            TWAIN_GAMES_CONTAINER_ID
          )
          console.log(
            '---------twainGamesContainer inside OBSERVER',
            twainGamesContainer
          ) // todo remove after debug
          if (twainGamesContainer) {
            removeAllChildren(twainGamesContainer) // To remove the iFrame added by TwainGames script
          }
          // Remove Twain Games iframe
          observer.disconnect() // Stop observing
        }
      }
    }
  })
}

const LiveGamesContainer = () => {
  const { token: twainGamesToken } = useSelector(selectTwainGamesToken) || {}
  const { i18n } = useTranslation()
  const config = useSelector(selectConfig)
  const isBalanceHidden = useSelector((state: RootState) =>
    selectSettingsDataProp(state, 'balanceHidden')
  )

  useEffect(() => {
    console.log('---BEFORE')
    removeTwainGames()

    const script = document.createElement('script')

    script.src = `${
      config.TWAIN_GAMES_CLIENT_URL
    }/public/ts-loader.js?${Date.now()}`
    script.onload = () => {
      console.log('---------REMOVE while script.onload')
      removeTwainGames()

      window.TwainSports.setup({
        containerElement: document.getElementById(TWAIN_GAMES_CONTAINER_ID),
        clientUrl: config.TWAIN_GAMES_CLIENT_URL,
        apiUrl: config.TWAIN_GAMES_API_URL,
        wsUrl: config.TWAIN_GAMES_WS_URL,
        partnerCode: config.TWAIN_GAMES_PARTNER_CODE,
        token: twainGamesToken ?? '-',
        locale: i18n.languages[0] || 'en',
        showBalance: isBalanceHidden ? 0 : 1,
        theme: 'dark',
        timezone: dayjs().format('Z')
      })
    }

    document.head.appendChild(script)
    removeTwainGamesIframe(script)

    return () => {
      console.log('---AFTER')
      removeTwainGames(script)
    }
  }, [
    config.TWAIN_GAMES_API_URL,
    config.TWAIN_GAMES_CLIENT_URL,
    config.TWAIN_GAMES_PARTNER_CODE,
    config.TWAIN_GAMES_WS_URL,
    i18n.languages,
    isBalanceHidden,
    twainGamesToken
  ])

  return <StyledTwainGames id={TWAIN_GAMES_CONTAINER_ID} />
}
