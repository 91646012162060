import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconMoney: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg fill="none" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M8.73413 4.93043C8.96146 4.82939 9.17703 4.71603 9.375 4.58993V5H9.29688C9.29688 5.09867 9.25449 5.23972 9.08234 5.42061C8.90713 5.60472 8.62406 5.79895 8.22645 5.97566C7.43282 6.32839 6.29262 6.5625 5 6.5625C3.70738 6.5625 2.56718 6.32839 1.77355 5.97566C1.37594 5.79895 1.09287 5.60472 0.917657 5.42061C0.745512 5.23972 0.703125 5.09867 0.703125 5H0.625V4.58993C0.822972 4.71603 1.03854 4.82939 1.26587 4.93043C2.25361 5.36942 3.57435 5.625 5 5.625C6.42565 5.625 7.74639 5.36942 8.73413 4.93043ZM0.625 6.77743V7.1875C0.625 8.39562 2.58375 9.375 5 9.375C7.41625 9.375 9.375 8.39562 9.375 7.1875V6.77743C9.17703 6.90353 8.96146 7.01689 8.73413 7.11793C7.74639 7.55692 6.42565 7.8125 5 7.8125C3.57435 7.8125 2.25361 7.55692 1.26587 7.11793C1.03854 7.01689 0.822972 6.90353 0.625 6.77743ZM5 0.625C7.41625 0.625 9.375 1.60438 9.375 2.8125H9.29688C9.29688 2.91117 9.25449 3.05222 9.08234 3.23311C8.90713 3.41722 8.62406 3.61145 8.22645 3.78816C7.43282 4.14089 6.29262 4.375 5 4.375C3.70738 4.375 2.56718 4.14089 1.77355 3.78816C1.37594 3.61145 1.09287 3.41722 0.917657 3.23311C0.745512 3.05222 0.703125 2.91117 0.703125 2.8125H0.625C0.625 1.60438 2.58375 0.625 5 0.625Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
