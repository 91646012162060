import { searchProviderActions } from 'astra-core/containers/SearchProvider'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { StyledSearchInputHeaders, StyledSearchWrapper } from './Search.styled'
import { useLocationState } from './Search.types'
import { SearchInput } from './SearchInput'
import { SearchList } from './SearchList'
import { SearchTabs } from './SearchTabs'

export const Search = () => {
  const location = useLocation()
  const state = (location.state as useLocationState) || {}
  const dispatch = useDispatch()

  useEffect(() => {
    if (state.valueSearch) {
      dispatch(
        searchProviderActions.setSearch({ valueSearch: state.valueSearch })
      )
    }
  }, [dispatch, location.state, state.valueSearch])

  return (
    <StyledSearchWrapper>
      <StyledSearchInputHeaders>
        <SearchInput />
        <SearchTabs />
      </StyledSearchInputHeaders>
      <SearchList />
    </StyledSearchWrapper>
  )
}
