import React, { memo } from 'react'

import {
  PickerPeriod,
  SelectBetTypes,
  ButtonToggleCollapse,
  ButtonClearHistory
} from './components'
import {
  StyledFilter,
  StyledFilterList,
  StyledFilterWrap
} from './Filter.styled'

export const Filter = memo(() => (
  <StyledFilter>
    <StyledFilterList>
      <SelectBetTypes />

      <PickerPeriod />
    </StyledFilterList>

    <StyledFilterWrap>
      <ButtonToggleCollapse />

      <ButtonClearHistory />
    </StyledFilterWrap>
  </StyledFilter>
))
