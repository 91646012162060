import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectConfig } from 'astra-core/containers/ConfigProvider'

import { ABOUT_PAGE_BLOCKS, getAboutPageDocuments } from './constants'
import {
  StyledAboutPageBlock,
  StyledAboutPageBlocks,
  StyledAboutPageDocument,
  StyledAboutPageDocuments,
  StyledAboutPageDocumentsWrapper,
  StyledAboutPageLogo,
  StyledAboutPageSubtitle,
  StyledAboutPageWrapper
} from './AboutPage.styled'
import { useAboutPageLogoUrl } from './hooks'

export const AboutPage: FC = () => {
  const { t } = useTranslation()

  const config = useSelector(selectConfig)

  const logoUrl = useAboutPageLogoUrl()

  return (
    <StyledAboutPageWrapper>
      <StyledAboutPageSubtitle>{t('common info')}</StyledAboutPageSubtitle>
      <StyledAboutPageLogo logoUrl={logoUrl} />
      <StyledAboutPageBlocks>
        {ABOUT_PAGE_BLOCKS.map((aboutPageBlock) => (
          <StyledAboutPageBlock key={aboutPageBlock.key}>
            {t(aboutPageBlock.locale)}
          </StyledAboutPageBlock>
        ))}
      </StyledAboutPageBlocks>
      <StyledAboutPageDocumentsWrapper>
        <StyledAboutPageSubtitle>{t('documents')}</StyledAboutPageSubtitle>
        <StyledAboutPageDocuments>
          {getAboutPageDocuments().map((aboutPageDocument) =>
            aboutPageDocument.to ? (
              <StyledAboutPageDocument
                as={Link}
                key={aboutPageDocument.link}
                to={aboutPageDocument.to}
              >
                <>{t(aboutPageDocument.locale)}</>
              </StyledAboutPageDocument>
            ) : (
              <StyledAboutPageDocument
                href={`${config.CDN_URL}/${config.RULES_CDN_FOLDER}/${aboutPageDocument.link}`}
                key={aboutPageDocument.link}
                target="_blank"
              >
                {t(aboutPageDocument.locale)}
              </StyledAboutPageDocument>
            )
          )}
        </StyledAboutPageDocuments>
      </StyledAboutPageDocumentsWrapper>
    </StyledAboutPageWrapper>
  )
}
