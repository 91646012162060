import React, { Fragment } from 'react'
import { dayjsCore as dayjs } from 'astra-core/utils/dayjs'
import { Column } from 'react-table'
import { adjustAmountForFrontend, formatAmount } from 'astra-core'

import { IconBonus } from 'shared/ui/Icon/General/IconBonus'

import { TableData } from './Table.types'
import { StyledGapRow } from './Table.styled'
import { useStyledTypeBonus } from './utils'

export const TABLE_COLUMNS: Column<TableData>[] = [
  {
    Header: 'date',
    accessor: 'created_at',
    Cell: ({ value }) => <>{dayjs(value).format(DATE_CELL)}</>
  },
  {
    Header: 'type of transaction',
    accessor: 'promotion_description'
  },
  {
    Header: 'amount',
    accessor: 'bonus',
    Cell: ({ value }) => {
      return (
        <StyledGapRow gap={2}>
          {formatAmount({ value: adjustAmountForFrontend(value!) })}
          <IconBonus />
        </StyledGapRow>
      )
    }
  },
  {
    Header: 'status',
    accessor: 'apply_status',
    Cell: ({ row }) => useStyledTypeBonus(row)
  }
]

export const DATE_CELL = 'DD.MM.YYYY HH:mm'
