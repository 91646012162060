import { ELoyaltyStatus } from 'astra-core/containers/AccountProvider'

export const getIsActiveTab = (
  tab: ELoyaltyStatus,
  currentTab: ELoyaltyStatus
) => tab === currentTab

export const BONUS_COUPONS_BODY_TABS_LIST = [
  {
    name: ELoyaltyStatus.ALL,
    locale: 'all'
  },
  {
    name: ELoyaltyStatus.ACTIVE,
    locale: 'active'
  },
  {
    name: ELoyaltyStatus.ARCHIVE,
    locale: 'archive'
  }
]
