import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Field } from 'formik'

import { IconClose } from 'shared/ui/Icon/General/IconClose'
import { Select } from 'shared/ui/Select'

import { InputFormik } from '../Input/Input'
import { IStyledFullSignUpInput } from '../FullRegistration.types'

export const StyledFullRegistrationWrapper = styled.div`
  padding: 26px 32px;
`

export const StyledFullRegistrationHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 26px;
  width: 336px;
`

export const StyledFullRegistrationHeaderTitle = styled.div`
  line-height: 20px;
  ${({ theme }) =>
    `
    font-size: ${theme.fonts.size.l};
    font-weight: ${theme.fonts.weight.bold};
    color: ${theme.colors.default.primary[90]};
  `}
`

export const StyledFullRegistrationHeaderExit = styled(IconClose)`
  align-self: center;
  cursor: pointer;
`

export const StyledUserDataTitle = styled.div`
  line-height: 20px;
  padding-bottom: 8px;
  ${({ theme }) =>
    `
    font-size: ${theme.fonts.size.m};
    font-weight: ${theme.fonts.weight.normal};
    color: ${theme.colors.default.primary[90]};
  `}
`

export const StyledInput = styled(InputFormik)<IStyledFullSignUpInput>`
  padding-bottom: 16px;

  ${(props) =>
    props.width
      ? `
          & > input {
            width: ${props.width};
          } 
        `
      : ''}

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    display: none;
  }
`

export const StyledFullRegistrationSelect = styled(Select)`
  padding-bottom: 16px;
`

export const StyledFullRegistrationConfirmRulesWrapper = styled.label`
  align-items: flex-start;
  display: flex;
  margin-bottom: 50px;
  margin-top: 16px;
  max-width: 336px;
  position: relative;
`

export const StyledFullRegistrationConfirmRulesText = styled.p`
  line-height: 16px;
  margin-left: 24px;
  ${({ theme }) =>
    `
    font-size: ${theme.fonts.size.s};
    font-weight: ${theme.fonts.weight.regular};
    color: ${theme.colors.default.primary[50]};
    `}
`

export const StyledFullRegistrationRefreshPicture = styled.div`
  line-height: 14px;
  ${({ theme }) =>
    `
    font-size: ${theme.fonts.size.s};
    font-weight: ${theme.fonts.weight.regular};
    color: ${theme.colors.custom.primary[39]};
    `}
`

export const StyledFullRegistrationLocaleLink = styled(Link)`
  ${({ theme }) =>
    `
    color: ${theme.colors.default.primary[90]};
    `}
`

export const StyledFullRegistrationConfirmRulesField = styled(Field)`
  opacity: 0;
  position: absolute;
  z-index: -1;

  &:checked ~ div {
    ${({ theme }) =>
      `
      background: ${theme.colors.custom.primary[10]};
      border: 1px solid ${theme.colors.custom.primary[29]};
  `}
  }

  &:checked ~ div::after {
    display: block;
  }
`

export const StyledFullRegistrationConfirmRulesControl = styled.div`
  border-radius: 2px;
  height: 14px;
  left: 0;
  position: absolute;
  top: 2px;
  width: 14px;

  ${({ theme }) =>
    `
      background: ${theme.colors.custom.primary[10]};
      border: 1px solid ${theme.colors.custom.primary[29]};
  `}
  &::after {
    border: solid ${({ theme }) => `${theme.colors.custom.primary[29]}`};
    border-width: 0 2px 2px 0;
    box-sizing: unset;
    content: '';
    display: none;
    height: 8px;
    left: 4px;
    position: absolute;
    top: 0;
    transform: rotate(45deg);
    width: 3px;
  }
`
