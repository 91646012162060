import styled, { css } from 'styled-components'

import { IconClose } from 'shared/ui/Icon/General/IconClose'
import { InputForm } from 'shared/ui/InputForm'
import { Button } from 'shared/ui/Button'

import { TStyledSmsConfirmationCodeNotReceivedLink } from './SmsConfirmationModal.types'

export const StyledSmsConfirmationModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
  width: 400px;
`

export const StyledSmsConfirmationModalHeaderWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 26px;
`

export const StyledSmsConfirmationModalHeaderTitle = styled.p`
  line-height: 20px;

  ${({ theme }) => css`
    color: ${theme.colors.default.primary[90]};
    font-size: ${theme.fonts.size.l};
    font-weight: ${theme.fonts.weight.bold};
  `};
`

export const StyledSmsConfirmationModalHeaderExit = styled(IconClose)`
  cursor: pointer;
`

export const StyledSmsConfirmationModalPhone = styled.div`
  align-items: center;
  border-radius: 8px;
  display: flex;
  height: 56px;
  justify-content: center;
  line-height: 16px;
  margin-bottom: 16px;
  padding: 12px 16px;
  width: 336px;

  ${({ theme }) => `
    background-color: ${theme.colors.custom.blue[6]};
    color: ${theme.colors.custom.blue[15]};
    font-size: ${theme.fonts.size.s};
    font-weight: ${theme.fonts.weight.regular};
  `};
`

export const StyledSMSCodeInput = styled(InputForm)`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    display: none;
  }

  input {
    width: 120px;
  }
`

export const StyledSmsConfirmationButton = styled(Button)`
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
  width: 100%;
`

export const StyledSmsConfirmationReRequestWrapper = styled.div`
  display: flex;
  line-height: 16px;
  margin: 4px 0 24px;

  ${({ theme }) => css`
    color: ${theme.colors.default.primary[50]};
    font-size: ${theme.fonts.size.s};
    font-weight: ${theme.fonts.weight.normal};
  `};
`

export const StyledSmsConfirmationCodeNotReceivedText = styled.p`
  margin-right: 4px;

  ${({ theme }) => `
    color: ${theme.colors.default.primary[50]};
  `};
`

export const StyledSmsConfirmationCodeNotReceivedLink = styled.p<TStyledSmsConfirmationCodeNotReceivedLink>`
  ${({ timer, theme }) => css`
    color: ${theme.colors.default.primary[90]};
    cursor: pointer;
    font-size: ${theme.fonts.size.s};
    font-weight: ${theme.fonts.weight.normal};
    line-height: 16px;

    ${!!timer &&
    css`
      pointer-events: none;
    `}
  `}
`

export const StyledSmsConfirmationCodeTimer = styled.p`
  line-height: 16px;
  margin: 4px 0 24px;

  ${({ theme }) => css`
    color: ${theme.colors.custom.primary[26]};
    font-size: ${theme.fonts.size.s};
    font-weight: ${theme.fonts.weight.regular};
  `};
`
