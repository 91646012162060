import React, { FC } from 'react'

import { IconProps } from '../types'
import { Icon } from '../Icon'

export const IconPromotions: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg fill="none" height="18" viewBox="0 0 19 18" width="19">
      <path
        clipRule="evenodd"
        d="M10.4248 0.335358C9.91304 -0.111786 9.13055 -0.111786 8.61879 0.335358L7.41414 1.38799C7.12288 1.64252 6.72967 1.76125 6.33893 1.7127L4.68757 1.50753C3.98358 1.42012 3.33052 1.87406 3.19801 2.54299L2.92423 3.925C2.84692 4.31522 2.58601 4.64928 2.2173 4.83014L0.861249 5.49516C0.203502 5.81774 -0.0583427 6.58987 0.275654 7.22193L0.907965 8.41848C1.10117 8.78398 1.10117 9.21602 0.907965 9.58152L0.275654 10.7781C-0.0583427 11.4101 0.203502 12.1823 0.861249 12.5048L2.2173 13.1699C2.58601 13.3507 2.84692 13.6848 2.92423 14.075L3.19801 15.457C3.33052 16.1259 3.98358 16.5799 4.68757 16.4925L6.33893 16.2873C6.72967 16.2388 7.12288 16.3575 7.41414 16.612L8.61879 17.6646C9.13055 18.1118 9.91304 18.1118 10.4248 17.6646L11.6295 16.612C11.9207 16.3575 12.3139 16.2388 12.7047 16.2873L14.356 16.4925C15.06 16.5799 15.7131 16.1259 15.8456 15.457L16.1194 14.075C16.1967 13.6848 16.4576 13.3507 16.8263 13.1699L18.1823 12.5048C18.8401 12.1823 19.1019 11.4101 18.7679 10.7781L18.1356 9.58152C17.9424 9.21602 17.9424 8.78398 18.1356 8.41848L18.7679 7.22193C19.1019 6.58987 18.8401 5.81774 18.1823 5.49516L16.8263 4.83014C16.4576 4.64928 16.1967 4.31522 16.1194 3.925L15.8456 2.54299C15.7131 1.87406 15.06 1.42012 14.356 1.50753L12.7047 1.7127C12.3139 1.76125 11.9207 1.64252 11.6295 1.38799L10.4248 0.335358ZM12.5209 4.68896C13.0603 5.03359 13.2061 5.73205 12.8465 6.24902L8.15085 12.999C7.7912 13.516 7.06234 13.6557 6.52292 13.311C5.98347 12.9664 5.8377 12.268 6.19731 11.751L10.893 5.00098C11.2526 4.484 11.9815 4.34427 12.5209 4.68896ZM8.34799 5.625C8.34799 6.24635 7.8224 6.75 7.17408 6.75C6.52575 6.75 6.00017 6.24635 6.00017 5.625C6.00017 5.00365 6.52575 4.5 7.17408 4.5C7.8224 4.5 8.34799 5.00365 8.34799 5.625ZM13.0436 12.375C13.0436 12.9963 12.5181 13.5 11.8697 13.5C11.2214 13.5 10.6958 12.9963 10.6958 12.375C10.6958 11.7537 11.2214 11.25 11.8697 11.25C12.5181 11.25 13.0436 11.7537 13.0436 12.375Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
