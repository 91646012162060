import React, { FC, useMemo } from 'react'
import { dayjsCore as dayjs } from 'astra-core/utils/dayjs'
import { useTranslation } from 'react-i18next'
import { Row } from 'react-table'

import {
  StyledApplied,
  StyledExpired,
  StyledRemaining,
  StyledUsed
} from './Table.styled'
import { TableData } from './Table.types'

export const useStyledTypeBonus: FC<Row<TableData>> = (row) => {
  const [t] = useTranslation()
  const { original } = row

  const remaining = useMemo(
    // @ts-ignore
    () => dayjs().to(dayjs(original.expired_at), true),
    [original.expired_at]
  )

  switch (original.apply_status) {
    case 'APPLIED':
      return (
        <StyledApplied gap={4}>
          {t('applied')}
          <StyledRemaining>
            {t('remaining', {
              days: remaining
            })}
          </StyledRemaining>
        </StyledApplied>
      )
    case 'EXPIRED':
      return <StyledExpired>{t('expired')}</StyledExpired>
    case 'PARTIAL_USED':
      return (
        <StyledUsed gap={4}>
          {t('applied')}
          <StyledRemaining>
            {t('remaining', {
              days: remaining
            })}
          </StyledRemaining>
        </StyledUsed>
      )
    case 'USED':
      return <StyledUsed>{t('used')}</StyledUsed>
    default:
      return <></>
  }
}
