import React, { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'

import { EMarkComponents, IMark } from './Mark.types'
import { StyledBonus, StyledLive, StyledWrapper } from './Mark.styled'

const Bonus = () => {
  const [t] = useTranslation()

  return <StyledBonus>{t('bonus')}</StyledBonus>
}
const Live = () => {
  const [t] = useTranslation()

  return <StyledLive>{t('live')}</StyledLive>
}

const markComponents = {
  [EMarkComponents.live]: <Live />,
  [EMarkComponents.bonus]: <Bonus />
}

export const Mark: FC<IMark> = memo(({ type, float }) => (
  <StyledWrapper float={float}>{markComponents[type]}</StyledWrapper>
))
