import React from 'react'

import { MessagesAccordion } from './components/MessagesAccordion'
import { StyledNotifications, StyledDataList } from './NotificationsPage.styled'
import { NotificationsTabs } from './components'
import { PAGINATION_SETTINGS } from './constants'

export const NotificationsPage = () => (
  <StyledNotifications>
    <NotificationsTabs />

    <StyledDataList pagination={PAGINATION_SETTINGS} isHidePreloader>
      <MessagesAccordion />
    </StyledDataList>
  </StyledNotifications>
)
