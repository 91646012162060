import styled from 'styled-components'

import { InputFormik } from 'widgets/FullRegistration/Input/Input'

import {
  IStyledBankCardImg,
  IStyledBankCardsMethodInput
} from './BankCardsMethod.types'

export const StyledBankCardsMethodWrapper = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 164px;
  margin: 16px 0;
  width: 304px;
  ${({ theme }) => `
    border: 1px solid ${theme.colors.default.primary[10]};
    background-color: ${theme.colors.custom.primary[10]};
  `};
`

export const StyledBankCardIconList = styled.div`
  align-items: center;
  display: flex;
  padding: 20px 0 24px 17px;
  width: 135px;
`

export const StyledBankCardNumberWrapper = styled.div`
  padding-left: 17px;
`

export const StyledInput = styled(InputFormik)<IStyledBankCardsMethodInput>`
  padding-bottom: 16px;

  ${(props) =>
    props.width
      ? `
          & > input {
            width: ${props.width};
          } 
        `
      : ''}

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    display: none;
  }
`

export const StyledBankCardImg = styled.img<IStyledBankCardImg>`
  height: ${({ size }) => `${size}px`};
  padding-right: 8px;
`
