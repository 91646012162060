import styled, { css } from 'styled-components'
import { LayoutTheme } from 'betweb-openapi-axios'

export const EmptyHistoryErrorStyled = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const EmptyHistoryErrorIconStyled = styled.div`
  ${(props) => css`
    align-items: center;
    background-color: ${props.theme.typeLayoutTheme === LayoutTheme.Dark
      ? props.theme.colors.fixed.primary[80]
      : props.theme.colors.fixed.yellow[10]};
    border-radius: 8px;
    display: flex;
    justify-content: center;
    padding: 7px;
  `}
`

export const EHENoBetsStyled = styled.h3`
  ${(props) => css`
    color: ${props.theme.colors.default.primary[90]};
    font-size: ${(props) => props.theme.fonts.size.m};
    font-weight: ${(props) => props.theme.fonts.weight.bold};
    line-height: 20px;
    margin-top: 16px;
  `}
`
export const EHEEmptyHistoryStyled = styled.span`
  ${(props) => css`
    color: ${props.theme.colors.fixed.primary[50]};
    font-size: ${(props) => props.theme.fonts.size.s};
    font-weight: ${(props) => props.theme.fonts.weight.regular};
    line-height: 20px;
    margin-top: 8px;
  `}
`
