import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetCurrencyIcon } from 'astra-core/hooks'
import { Bet } from 'betweb-openapi-axios'
import { formatAmountLocaleString } from 'astra-core'

import { EColorsNames } from 'shared/types/theme'
import { IconBonus } from 'shared/ui/Icon/General/IconBonus'
import { BasketHistoryBonusCoupon } from 'widgets/right-container/Basket/BasketHistoryBody/components'

import { ECellEventTitle } from './CellEvent.types'
import {
  CellEventBetCountIcon,
  StyledBetAmount,
  StyledCellEventBetCount,
  StyledCellEventNum,
  StyledCellEventTitle
} from './CellEvent.styled'

export const CellEventNum = () => <StyledCellEventNum />

export const CellEventTitle = ({ value, systemType }) => {
  const [t] = useTranslation()

  return (
    <StyledCellEventTitle>
      {ECellEventTitle[value]
        ? `${t(ECellEventTitle[value])} ${systemType}`
        : ECellEventTitle.default}
    </StyledCellEventTitle>
  )
}

export const CellEventBetCount: FC<{ value: Bet }> = ({ value }) => {
  const currencyIcon = useGetCurrencyIcon()
  const isBonus = value.bonusWallet
  const { bet, appliedCouponId } = value

  const additionBonusBlock = isBonus ? (
    <IconBonus />
  ) : appliedCouponId ? (
    <BasketHistoryBonusCoupon appliedCouponId={appliedCouponId} />
  ) : null

  return (
    <StyledCellEventBetCount>
      <CellEventBetCountIcon
        colorProps={{ name: EColorsNames.Primary, value: 50 }}
        size={9}
      />
      <StyledBetAmount>
        {formatAmountLocaleString({
          value: bet,
          currency: !isBonus ? currencyIcon : undefined
        })}
        {additionBonusBlock}
      </StyledBetAmount>
    </StyledCellEventBetCount>
  )
}
