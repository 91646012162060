import styled, { css } from 'styled-components'

import { Button } from 'shared/ui/Button'
import { Input } from 'shared/ui/Input'
import { IconClose } from 'shared/ui/Icon/General/IconClose'

export const StyledTitle = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 4px;
  white-space: nowrap;

  ${({ theme }) =>
    css`
      color: ${theme.colors.custom.primary[39]};
    `}
`

export const StyledButton = styled(Button)`
  align-items: center;
  display: flex;
  grid-column-start: 2;
  justify-content: center;
  margin-top: 12px;
  width: 100%;
`

export const StyledInputWrappers = styled.div`
  margin-bottom: 8px;
  width: 100%;
`

export const StyledInputWrapper = styled.div`
  position: relative;
`

export const StyledInputTextError = styled.div`
  color: ${({ theme }) => theme.colors.fixed.red[0]};
  font-size: ${(props) => props.theme.fonts.size.xxs};
  font-weight: ${(props) => props.theme.fonts.weight.normal};
  line-height: ${(props) => props.theme.fonts.size.l};
  max-width: 250px;
`
export const StyledIconRightButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  height: 100%;
  outline: none;
  padding: 0 9px;
  position: absolute;
  right: 9px;
  top: 2px;
`

export const StyledPasswordInput = styled(Input)`
  width: 100%;
`

export const StyledModalSectionPassword = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 56px;
`

export const StyledModalChangePasswordHeaderWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 20px;
  justify-content: space-between;
  padding: 32px 0 26px 16px;
`

export const StyledModalChangePasswordHeaderTitle = styled.div`
  line-height: 20px;

  ${({ theme }) =>
    css`
      color: ${theme.colors.default.primary[90]};
      font-size: ${theme.fonts.size.l};
      font-weight: ${theme.fonts.weight.bold};
    `}
`

export const StyledModalSectionPasswordHeader = styled.div`
  align-items: flex-end;
  display: flex;
  height: 40px;
  justify-content: flex-end;
`
export const StyledModalSectionPasswordHeaderExit = styled(IconClose)`
  cursor: pointer;
  margin-right: 20px;
`
export const StyledModalSuccessText = styled.div`
  line-height: 20px;
  margin: 16px 0 4px;
  ${({ theme }) =>
    css`
      color: ${theme.colors.default.primary[90]};
      font-size: ${theme.fonts.size.m};
      font-weight: ${theme.fonts.weight.bold};
    `}
`
export const StyledModalSuccessTextClose = styled.div`
  cursor: pointer;
  line-height: 20px;
  ${({ theme }) =>
    `
    font-size: ${theme.fonts.size.m};
    font-weight: ${theme.fonts.weight.regular};
    color: ${theme.colors.custom.primary[40]};
  `}
`

export const StyledPasswordConditionalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -4px;
`
