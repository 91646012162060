import { MethodParameterType } from 'betweb-openapi-axios'
import { useSelector } from 'react-redux'
import { selectCurrentWithdrawalMethod } from 'astra-core/containers/BalanceProvider'

import { findTransactionParam } from 'shared/lib/payment'

export const useCheckActiveWithdrawalParam = (
  parameterType: MethodParameterType
) => {
  const currentWithdrawal = useSelector(selectCurrentWithdrawalMethod)

  return (
    currentWithdrawal &&
    !!findTransactionParam(currentWithdrawal?.withdraw_params, parameterType)
  )
}
