import { BetOutcomeStatus } from 'betweb-openapi-axios'
import styled, { css } from 'styled-components'

import { IconInfoCircle } from 'shared/ui/Icon/General/IconInfoCircle'

import { StyledCell } from '../Cell/Cell.styled'

import { IStyledBetContentItem } from './Outcome.types'
import { getOutcomeResultIndicatorColors } from './constants'

export const StyledBetContentItem = styled.tr<IStyledBetContentItem>`
  ${(props) =>
    props.isLastItem &&
    css`
      td {
        border-bottom: 4px solid ${props.theme.colors.custom.primary[8]};
      }
    `}

  td:not(:last-of-type, :first-of-type) {
    border-right: 1px solid ${(props) => props.theme.colors.custom.primary[4]};
  }

  td:last-child {
    width: 0;
  }

  td:nth-last-child(2) {
    text-align: right;
  }

  td:nth-last-child(3) {
    width: 0;
  }

  td:nth-last-child(4) {
    width: 0;
  }
`

export const StyledCellOutcome = styled(StyledCell)`
  color: ${(props) => props.theme.colors.custom.primary[32]};
  font-size: ${(props) => props.theme.fonts.size.s};
  font-weight: ${(props) => props.theme.fonts.weight.regular};
  padding: 6px 8px;
  white-space: normal;

  & > * {
    display: inline-block;
  }

  & > *:not(:last-child) {
    margin-right: 4px;
  }
`

type StyledOutcomeResultIndicatorProps = {
  outcomeStatus: BetOutcomeStatus
}

export const StyledOutcomeResultIndicator = styled.div<StyledOutcomeResultIndicatorProps>`
  background-color: ${(p) =>
    getOutcomeResultIndicatorColors(p.theme)[p.outcomeStatus] || 'transparent'};
  border-radius: 50%;
  height: 6px;
  width: 6px;
  ${(p) => (p.outcomeStatus === BetOutcomeStatus.Na ? `display: none` : ``)}
`

export const StyledIconWrapper = styled.div`
  margin-right: 4px;
`

export const StyledInfoIconWrapper = styled.td`
  color: ${(props) => props.theme.colors.custom.primary[32]};
  font-size: ${(props) => props.theme.fonts.size.s};
  font-weight: ${(props) => props.theme.fonts.weight.regular};
  padding: 14px 8px;
  white-space: normal;
`

export const StyledInfoIconWrapperContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const StyledIconInfoCircle = styled(IconInfoCircle)`
  margin-right: 4px;
`
