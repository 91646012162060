import React from 'react'
import { useTheme } from 'styled-components'
import { LayoutTheme } from 'betweb-openapi-axios'
import { useTranslation } from 'react-i18next'

import { IconMagnifierWithList } from 'shared/ui/Icon/General/IconMagnifierWithList'

import {
  EHEEmptyHistoryStyled,
  EHENoBetsStyled,
  EmptyHistoryErrorIconStyled,
  EmptyHistoryErrorStyled
} from './EmptyHistoryError.styled'

export const EmptyHistoryError = () => {
  const theme = useTheme()
  const [t] = useTranslation()

  return (
    <EmptyHistoryErrorStyled>
      <EmptyHistoryErrorIconStyled>
        <IconMagnifierWithList
          isDark={theme.typeLayoutTheme === LayoutTheme.Dark}
          size={50}
        />
      </EmptyHistoryErrorIconStyled>

      <EHENoBetsStyled>{t('no bets')}</EHENoBetsStyled>

      <EHEEmptyHistoryStyled>
        {t('history bets is empty')}
      </EHEEmptyHistoryStyled>
    </EmptyHistoryErrorStyled>
  )
}
