import React, { FC, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Formik } from 'formik'
import {
  selectPaymentFormData,
  selectWithdrawalFormData,
  balanceProviderActions,
  selectCurrentCurrencyListWithdrawalMethod,
  selectCurrentCurrencyListPaymentMethod,
  selectCurrentMethod,
  selectPaymentFetchInfoLoading,
  selectWithdrawalFetchInfoLoading,
  selectIsWithdrawalButtonDisabled,
  selectCurrentWithdrawalMethodFields
} from 'astra-core/containers/BalanceProvider'
import { useGetCurrencyIcon } from 'astra-core/hooks'
import { formatAmount } from 'astra-core'
import { selectUserSettingLayoutTheme } from 'astra-core/containers/SettingsProvider'

import { Button } from 'shared/ui/Button'
import { LoaderPoints } from 'shared/ui/LoaderPoints'

import { WithdrawalFormMethods } from '../WithdrawalFormMethods'

import {
  StyledFormContainer,
  StyledFormTitle,
  StyledFormGroup,
  StyledFormHelper,
  StyledInput,
  StyledLoaderPointsWrapper
} from './Form.styled'
import {
  formikInitialValues,
  FORM_PAYMENT_FORMIK_SCHEMA,
  FORM_WITHDRAW_FORMIK_SCHEMA,
  LOADER_POINTS_COLORS,
  PAYMENT_TITLE_TEXT
} from './constants'
import { handleChangeTypeValue } from './Form.types'

interface Props {
  requireTransaction: boolean
}

export const PaymentForm: FC<Props> = ({ requireTransaction }) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const formData = useSelector(selectPaymentFormData)
  const currencyIcon = useGetCurrencyIcon()
  const { minPayment, maxPayment } = useSelector(
    selectCurrentCurrencyListPaymentMethod
  )
  const isPaymentLoading = useSelector(selectPaymentFetchInfoLoading)
  const currentMethod = useSelector(selectCurrentMethod)
  const typeTheme = useSelector(selectUserSettingLayoutTheme)

  const loaderPointsColors = useMemo(() => {
    return (typeTheme && LOADER_POINTS_COLORS[typeTheme]) ?? undefined
  }, [typeTheme])

  const handleSubmit = useCallback(() => {
    dispatch(balanceProviderActions.createPayment())
  }, [dispatch])

  const handleChange = useCallback(
    (type: handleChangeTypeValue) =>
      ({ target }) => {
        dispatch(
          balanceProviderActions.setPaymentFormData({ [type]: target.value })
        )
      },
    [dispatch]
  )

  const availablePaymentAmount = useMemo(
    () =>
      `${t('from')} ${formatAmount({
        value: minPayment,
        currency: currencyIcon
      })} ${t('to')} ${formatAmount({
        value: maxPayment,
        currency: currencyIcon
      })}`,
    [t, minPayment, maxPayment, currencyIcon]
  )

  const minAmountError = useMemo(() => {
    return t('minimum amount', { amount: minPayment, currency: currencyIcon })
  }, [t, minPayment, currencyIcon])

  const maxAmountError = useMemo(() => {
    return t('max payment sum errors', {
      amount: maxPayment,
      currency: currencyIcon
    })
  }, [t, maxPayment, currencyIcon])

  return (
    <Formik
      validationSchema={FORM_PAYMENT_FORMIK_SCHEMA(
        minPayment,
        maxPayment,
        currentMethod,
        minAmountError,
        maxAmountError,
        requireTransaction
      )}
      initialValues={formikInitialValues(formData)}
      onSubmit={handleSubmit}
    >
      <Form>
        <StyledFormContainer>
          {requireTransaction && (
            <StyledFormTitle>
              {t(PAYMENT_TITLE_TEXT[currentMethod])}
            </StyledFormTitle>
          )}
          <StyledFormGroup>
            {requireTransaction && (
              <StyledInput
                name="amount"
                placeholder={minPayment.toString()}
                type="number"
                onBlur={handleChange('amount')}
              />
            )}
            <Button disabled={isPaymentLoading} type="submit">
              <StyledLoaderPointsWrapper>
                {isPaymentLoading ? (
                  <LoaderPoints
                    colors={loaderPointsColors}
                    margin={1}
                    size={5}
                  />
                ) : (
                  t('top up')
                )}
              </StyledLoaderPointsWrapper>
            </Button>
          </StyledFormGroup>
          {requireTransaction && (
            <StyledFormHelper>{availablePaymentAmount}</StyledFormHelper>
          )}
        </StyledFormContainer>
      </Form>
    </Formik>
  )
}

export const WithdrawalForm: FC = () => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const formData = useSelector(selectWithdrawalFormData)
  const currencyIcon = useGetCurrencyIcon()
  const { minWithdraw, maxWithdraw } = useSelector(
    selectCurrentCurrencyListWithdrawalMethod
  )
  const isWithdrawLoading = useSelector(selectWithdrawalFetchInfoLoading)
  const typeTheme = useSelector(selectUserSettingLayoutTheme)
  const isButtonDisabled = useSelector(selectIsWithdrawalButtonDisabled)
  const currentWithdrawalFields = useSelector(
    selectCurrentWithdrawalMethodFields
  )

  const loaderPointsColors = useMemo(() => {
    return (typeTheme && LOADER_POINTS_COLORS[typeTheme]) ?? undefined
  }, [typeTheme])

  const handleSubmit = useCallback(
    (_, actions) => {
      dispatch(balanceProviderActions.fetchVerificationIdentifier())
      actions.setSubmitting(false)
      actions.resetForm(formikInitialValues(formData))
    },
    [dispatch, formData]
  )

  const handleChange = useCallback(
    (type: handleChangeTypeValue) =>
      ({ target }: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(
          balanceProviderActions.setWithdrawalFormData({ [type]: target.value })
        )
      },
    [dispatch]
  )

  const withdrawalRange = useMemo(() => {
    return `${t(
      'withdrawal range'
    )}: ${minWithdraw} - ${maxWithdraw} ${currencyIcon}`
  }, [t, minWithdraw, maxWithdraw, currencyIcon])

  const minAmountError = useMemo(() => {
    return t('minimum amount', { amount: minWithdraw, currency: currencyIcon })
  }, [t, minWithdraw, currencyIcon])

  const maxAmountError = useMemo(() => {
    return t('max withdraw sum errors', {
      amount: maxWithdraw,
      currency: currencyIcon
    })
  }, [t, maxWithdraw, currencyIcon])

  // TODO Ержан
  // if (withdrawalData.fetchInfo.loading) {
  //   return (
  //     <LoadingIndicatorContainer>
  //       <LoaderSpinner />
  //     </LoadingIndicatorContainer>
  //   )
  // }

  return (
    <>
      <Formik
        validationSchema={FORM_WITHDRAW_FORMIK_SCHEMA(
          minWithdraw,
          maxWithdraw,
          currentWithdrawalFields,
          minAmountError,
          maxAmountError
        )}
        initialValues={formikInitialValues(formData)}
        isInitialValid={false}
        onSubmit={handleSubmit}
      >
        {({ isValid }) => (
          <Form>
            <StyledFormContainer>
              <WithdrawalFormMethods handleChange={handleChange} />
              <StyledFormTitle>{t('withdrawal amount')}</StyledFormTitle>
              <StyledFormGroup>
                <StyledInput
                  name="amount"
                  placeholder={minWithdraw.toString()}
                  type="number"
                  onChange={handleChange('amount')}
                />
                <Button disabled={!isValid || isButtonDisabled} type="submit">
                  <StyledLoaderPointsWrapper>
                    {isWithdrawLoading ? (
                      <LoaderPoints
                        colors={loaderPointsColors}
                        margin={1}
                        size={5}
                      />
                    ) : (
                      t('withdraw')
                    )}
                  </StyledLoaderPointsWrapper>
                </Button>
              </StyledFormGroup>
              <StyledFormHelper>{withdrawalRange}</StyledFormHelper>
            </StyledFormContainer>
          </Form>
        )}
      </Formik>
    </>
  )
}
