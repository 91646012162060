import styled from 'styled-components'

export const StyledWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.custom.primary[27]};
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`

export const StyledItemWrapper = styled.div`
  line-height: 16px;
  padding: 16px 28px;
  position: relative;
`

export const StyledItemTitle = styled.h3`
  margin: 0;
  padding: 0 0 5px;
  ${({ theme }) =>
    `
    font-size: ${theme.fonts.size.s};
    font-weight: ${theme.fonts.weight.regular};
    color: ${theme.colors.custom.primary[28]};
  `}
`

export const StyledItemBottomWrapper = styled.div`
  align-items: flex-end;
  color: ${(props) => props.theme.colors.default.primary[30]};
  display: flex;
  font-size: ${(props) => props.theme.fonts.size.s};
`

export const StyledItemValue = styled.div`
  line-height: 20px;
  padding-right: 10px;
  ${({ theme }) =>
    `
    font-size: ${theme.fonts.size.l};
    font-weight: ${theme.fonts.weight.normal};
    color: ${theme.colors.default.primary[90]};
  `}
`
