import { EChangePassword } from 'astra-core/containers/AccountProvider'

import { ISectionData } from './SectionPassword.types'

export const SECTION_PASSWORD_DATA: Array<ISectionData> = [
  {
    customType: EChangePassword.OLD,
    input: { placeholder: 'enter old password' },
    title: 'old password'
  },
  {
    customType: EChangePassword.RENEWED,
    input: { placeholder: 'enter new password' },
    title: 'renewed password'
  },
  {
    customType: EChangePassword.CONFIRM,
    input: {
      placeholder: 'repeat new password'
    },
    title: 'confirm password'
  }
]

export const REG_PASSWORD_SEPARATED = {
  symbolsCounts: /.{6}/,
  latinLetter: /^[a-zA-Z0-9()*_\-!;:#$%^&*,."'\][]+$/
}
