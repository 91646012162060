import { PaymentStatus } from 'betweb-openapi-axios'
import styled from 'styled-components'

import { StyledStatusProps } from './Table.types'

export const StyledTable = styled.table`
  border-spacing: 0;
  width: 100%;
`

export const StyledHeaderCell = styled.th`
  line-height: 16px;
  padding: 4px 8px;
  text-align: left;
  ${({ theme }) =>
    `
    font-size: ${theme.fonts.size.xs};
    font-weight: ${theme.fonts.weight.normal};
    color: ${theme.colors.default.primary[30]};
    border-bottom: 1px solid ${({ theme }) => theme.colors.default.primary[10]};
  `}
`

export const StyledRow = styled.tr`
  &:hover {
    background-color: ${({ theme }) => theme.colors.default.primary[5]};
  }
`

export const StyledRowCell = styled.td`
  line-height: 14px;
  padding: 5px 8px;
  ${({ theme }) =>
    `
    font-size: ${theme.fonts.size.s};
    font-weight: ${theme.fonts.weight.regular};
    color: ${theme.colors.default.primary[90]};
  `}
`

export const StyledStatus = styled.span<StyledStatusProps>`
  color: ${({ theme, status }) =>
    status === PaymentStatus.Success
      ? theme.colors.default.green[50]
      : status === PaymentStatus.Failed
      ? theme.colors.default.red[50]
      : theme.colors.default.primary[90]};
  font-size: ${({ theme }) => theme.fonts.size.s};
`
