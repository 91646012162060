import React, { FC, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  accountProviderActions,
  selectAccountId,
  selectAccountUser,
  selectFetchLiveGamesTokenRequest,
  selectFetchUserRequest,
  selectLiveGamesToken
} from 'astra-core/containers/AccountProvider'
import { useTranslation } from 'react-i18next'
import { LayoutTheme } from 'betweb-openapi-axios'
import { selectUserSettingLayoutTheme } from 'astra-core/containers/SettingsProvider'

import { LoaderPoints } from 'shared/ui/LoaderPoints'

import { StyledLiveGames, StyledLoaderPointsWrapper } from './LiveGames.styled'

export const LiveGames: FC = () => {
  const dispatch = useDispatch()
  const user = useSelector(selectAccountUser)
  const accountId = useSelector(selectAccountId)
  const { loading: fetchUserLoading } = useSelector(selectFetchUserRequest)
  const { loading: fetchLiveGamesTokenLoading } = useSelector(
    selectFetchLiveGamesTokenRequest
  )
  const { token: liveGamesToken } = useSelector(selectLiveGamesToken) || {}

  useEffect(() => {
    if (accountId && !liveGamesToken) {
      dispatch(
        accountProviderActions.getLiveGamesToken({
          accountId
        })
      )
    }
  }, [accountId, dispatch, liveGamesToken])

  const loaded =
    fetchUserLoading === false &&
    (!user || fetchLiveGamesTokenLoading === false)

  return loaded ? (
    <LiveGamesContainer />
  ) : (
    <StyledLoaderPointsWrapper>
      <LoaderPoints />
    </StyledLoaderPointsWrapper>
  )
}

const LIVE_GAMES_CONTAINER_ID = 'magicwin_container'

const LiveGamesContainer = () => {
  const { token: liveGamesToken } = useSelector(selectLiveGamesToken) || {}
  const { i18n } = useTranslation()
  const layoutTheme = useSelector(selectUserSettingLayoutTheme)

  const setup = useMemo(
    () => ({
      container: LIVE_GAMES_CONTAINER_ID,
      lang: i18n.languages[0] || 'en',
      runFrom: 2,
      options: {
        defaultCurrency: 'RUB',
        skin: layoutTheme === LayoutTheme.Light ? 1 : 2,
        oddCulture: 0
      },
      UserToken: liveGamesToken!
    }),
    [i18n.languages, layoutTheme, liveGamesToken]
  )

  useEffect(() => {
    window.MagicGames.addWidget(setup)
    return () => {
      window.MagicGames.Loaded = 0
    }
  }, [setup])

  return (
    <StyledLiveGames
      id={LIVE_GAMES_CONTAINER_ID}
      isDarkMode={layoutTheme === LayoutTheme.Dark}
    />
  )
}
