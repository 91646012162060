import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  EBalanceWithdrawModalWindowType,
  selectWithdrawModalType
} from 'astra-core/containers/BalanceProvider'

import { BalanceWithdrawModal } from 'pages/page-balance/Balance/components/BalanceModal/BalanceModal'

export const ModalWithdrawal = () => {
  const withdrawModalType = useSelector(selectWithdrawModalType)

  const isShowWithdrawModal = useMemo(
    () => withdrawModalType !== EBalanceWithdrawModalWindowType.OFF,
    [withdrawModalType]
  )

  return isShowWithdrawModal ? <BalanceWithdrawModal /> : null
}
