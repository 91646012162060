import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetCurrencyIcon } from 'astra-core/hooks'
import {
  EBetStatusText,
  getBetAmountFormatted,
  getIsBetWithCurrentSystem
} from 'astra-core'
import { useSelector } from 'react-redux'
import {
  selectLoyaltyById,
  selectLoyaltyCurrencyRestrictionById
} from 'astra-core/containers/AccountProvider'

import { RootState } from 'shared/types/store'

import { StyledCell } from '../Cell.styled'
import { ITableCellBet } from '../../../Table.types'

import { StyledCellResultText } from './Result.styled'

export const CellResultText: FC<ITableCellBet> = ({ bet }) => {
  const [t] = useTranslation()
  const isDisplayText = !getIsBetWithCurrentSystem(bet)

  return (
    <StyledCellResultText betStatus={bet.status}>
      {isDisplayText && t(EBetStatusText[bet.status])}
    </StyledCellResultText>
  )
}

export const CellResultAmount: FC<ITableCellBet> = ({ bet }) => {
  const currencyIcon = useGetCurrencyIcon()
  const couponLoyalty = useSelector((state: RootState) =>
    selectLoyaltyById(state, bet.appliedCouponId)
  )
  const loyaltyCurrencyRestriction = useSelector((state: RootState) =>
    selectLoyaltyCurrencyRestrictionById(state, bet.appliedCouponId)
  )

  const betAmountFormatted = getBetAmountFormatted({
    bet,
    couponLoyalty,
    currencyIcon,
    loyaltyCurrencyRestriction
  })

  return <StyledCell alignData="end">{betAmountFormatted}</StyledCell>
}
