import React, { useCallback } from 'react'
import {
  accountProviderActions,
  EAuthenticationSteps,
  selectAccountUser
} from 'astra-core/containers/AccountProvider'
import { useDispatch, useSelector } from 'react-redux'
import { UserVerificationStatusEnum } from 'betweb-openapi-axios'

import { NotificationAbleWithdrawStyled } from './Styled'

export const NotificationAbleWithdrawal = () => {
  const dispatch = useDispatch()
  const user = useSelector(selectAccountUser)

  const showAuthenticationModal = useCallback(() => {
    dispatch(
      accountProviderActions.changeAuthenticationCurrentStep({
        step: EAuthenticationSteps.ONE
      })
    )
  }, [dispatch])

  return user?.verification_status !== UserVerificationStatusEnum.Verified ? (
    <NotificationAbleWithdrawStyled
      linkText="to verification"
      modalWindowAction={showAuthenticationModal}
      text="to be able to withdraw"
    />
  ) : null
}
