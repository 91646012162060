import { Props as SelectProps } from 'react-select'
import styled from 'styled-components'

import { Input } from 'shared/ui/Input'
import { Select } from 'shared/ui/Select'

export const StyledResultsWrapper = styled.div``

export const StyledSelect = styled(Select)<SelectProps>`
  margin-right: 8px;
  width: 140px;
`

export const StyledSelectWrapper = styled.div`
  align-items: flex-start;
  display: flex;
`

export const StyledHeaderWrapper = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.colors.custom.primary[2]};
  display: flex;
  justify-content: space-around;
`

export const StyledCheckboxWrapper = styled.div`
  display: flex;
  margin: 0 16px;
`

export const StyledCheckboxText = styled.span`
  line-height: 14px;
  margin-left: 8px;
  ${({ theme }) =>
    `
      font-size: ${theme.fonts.size.s};
      font-weight: ${theme.fonts.weight.regular};
      color: ${theme.colors.default.primary[50]};
    `}
`

export const StyledSearchInput = styled(Input)`
  width: 122px;
`

// export const StyledCollapseAllText = styled.div`
//   line-height: 14px;
//   margin-left: 16px;
//   cursor: pointer;
//   ${({ theme }) =>
//     `
//       font-size: ${theme.fonts.size.s};
//       font-weight: ${theme.fonts.weight.regular};
//       color: ${theme.colors.default.primary[50]};
//     `}
// `

export const StyledLive = styled.span`
  ${({ theme }) => `
    font-size: ${theme.fonts.size.xs};
    font-weight: ${theme.fonts.weight.normal};
    color: ${theme.colors.fixed.red[0]};
    text-transform: uppercase;
    margin-left: 4px;
  `}
`
