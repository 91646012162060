import { BetType } from 'betweb-openapi-axios'

import { BETS_STATISTICS_EXTRA_KEYS } from './utils'

export const BETS_STATISTICS_LIST_ITEMS = [
  {
    title: 'total amount in game',
    key: BETS_STATISTICS_EXTRA_KEYS.TOTAL
  },
  {
    title: 'ordinar',
    key: BetType.Ordinar
  },
  {
    title: 'express',
    key: BetType.Express
  },
  {
    title: 'system',
    key: BetType.System
  }
]
