import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

import {
  StyledErrorBtn,
  StyledErrorContainer,
  StyledErrorDescr,
  StyledErrorTitle,
  StyledIconError404
} from './Error404Page.styled'

export const Error404Page = memo(() => {
  const [t] = useTranslation()

  return (
    <StyledErrorContainer>
      <StyledIconError404 size={64} />
      <StyledErrorTitle>{t('error 404')}</StyledErrorTitle>
      <StyledErrorDescr>{t('sorry, page was not found')}</StyledErrorDescr>
      <StyledErrorBtn to="/">{t('go back to the main page')}</StyledErrorBtn>
    </StyledErrorContainer>
  )
})
