import styled from 'styled-components'

export const StyledTable = styled.table`
  border-spacing: 0;
  width: 100%;
`

export const StyledHeaderCell = styled.th`
  line-height: 16px;
  padding: 4px 8px;
  text-align: left;
  ${({ theme }) =>
    `
    font-size: ${theme.fonts.size.xs};
    font-weight: ${theme.fonts.weight.normal};
    color: ${theme.colors.fixed.primary[50]};
    border-bottom: 1px solid ${theme.colors.default.primary[10]};
  `}
`

export const StyledRow = styled.tr`
  &:hover {
    background-color: ${({ theme }) => theme.colors.default.primary[5]};
  }
`

export const StyledRowCell = styled.td`
  line-height: 16px;
  padding: 8px;
  ${({ theme }) =>
    `
    font-size: ${theme.fonts.size.s};
    font-weight: ${theme.fonts.weight.regular};
    color: ${theme.colors.default.primary[90]};
    border-bottom: 1px solid ${theme.colors.custom.primary[18]};
  `}
`

export const StyledGapRow = styled.div<{ gap: number }>`
  align-items: center;
  display: flex;
  gap: ${(props) => props.gap}px;
`

export const StyledApplied = styled.span<{ gap: number }>`
  align-items: center;
  display: flex;
  gap: ${(props) => props.gap}px;

  ${({ theme }) =>
    ` 
      font-weight: ${theme.fonts.weight.normal};
      color: ${theme.colors.default.blue[0]};
  `}
`

export const StyledExpired = styled.span`
  ${({ theme }) =>
    `
      font-weight: ${theme.fonts.weight.normal};
      color: ${theme.colors.default.red[50]};
  `}
`

export const StyledUsed = styled.span<{ gap?: number }>`
  align-items: center;
  display: flex;
  gap: ${(props) => props.gap}px;
  ${({ theme }) =>
    `
      font-weight: ${theme.fonts.weight.normal};
      color: ${theme.colors.custom.green[5]};
  `}
`

export const StyledRemaining = styled.span`
  border-radius: 4px;
  line-height: 16px;
  padding: 0 3px;
  text-transform: uppercase;
  ${({ theme }) =>
    `
      font-size: ${theme.fonts.size.xxxxs};
      font-weight: ${theme.fonts.weight.bold};
      color: ${theme.colors.custom.primary[60]};
      background-color: ${theme.colors.default.primary[10]};
  `}
`
