import styled from 'styled-components'

import { DataListWithReduxPagination } from 'shared/ui/DataList'

export const StyledNotifications = styled.main`
  padding: 10px 0 18px 16px;
`

export const StyledDataList = styled(DataListWithReduxPagination)`
  height: 100%;
  overflow: scroll;
`
