import {
  getMessagesPageFetchItems,
  getMessagesPageHasMore,
  getMessagesPagePagination,
  messagesProviderActions
} from 'astra-core/containers/MessagesProvider'

import { DataListWithReduxPaginationProps } from 'shared/ui/DataList'

export const PAGINATION_SETTINGS: DataListWithReduxPaginationProps['pagination'] =
  {
    setPaginationAction: messagesProviderActions.setPaginationPage,
    selectorHasMore: getMessagesPageHasMore,
    selectorPagination: getMessagesPagePagination,
    selectorRequestStatus: getMessagesPageFetchItems
  }
