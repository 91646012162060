import styled from 'styled-components'

export const StyledAboutPageWrapper = styled.main`
  display: grid;
  grid-gap: 16px;
  grid-template-rows: auto auto minmax(0, 1fr);
  max-width: 960px;
  padding: 8px 16px 24px;
  width: 100%;
`

export const StyledAboutPageSubtitle = styled.h3`
  color: ${(props) => props.theme.colors.default.primary[90]};
  font-size: ${(props) => props.theme.fonts.size.xl};
  font-weight: ${(props) => props.theme.fonts.weight.bold};
  margin: 0;
  padding: 10px 0;
`

type StyledAboutPageLogoProps = {
  logoUrl: string
}

export const StyledAboutPageLogo = styled.div<StyledAboutPageLogoProps>`
  background-image: ${(p) => `url(${p.logoUrl})`};
  background-repeat: no-repeat;
  background-size: contain;
  padding-top: 25%;
`

export const StyledAboutPageBlocks = styled.div`
  /* display: grid; */
  display: none;
  grid-auto-rows: min-content;

  /* TODO remove */
  grid-gap: 24px;
`

export const StyledAboutPageBlock = styled.p`
  color: ${(props) => props.theme.colors.default.primary[90]};
  font-size: ${(props) => props.theme.fonts.size.m};
  font-weight: ${(props) => props.theme.fonts.weight.regular};
  line-height: 20px;
  margin: 0;
`

export const StyledAboutPageDocumentsWrapper = styled.div`
  margin-top: 48px;
`

export const StyledAboutPageDocuments = styled.div`
  display: grid;
  grid-auto-rows: min-content;
  grid-gap: 16px;
  margin-top: 16px;
`

export const StyledAboutPageDocument = styled.a`
  color: ${(props) => props.theme.colors.default.primary[90]};
  cursor: pointer;
  font-size: ${(props) => props.theme.fonts.size.m};
  font-weight: ${(props) => props.theme.fonts.weight.normal};
  line-height: 20px;
  text-decoration: underline;
  text-underline-offset: 1px;
`
