import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { selectConfig } from 'astra-core/containers/ConfigProvider'

import { ELayoutsList, Layout } from 'widgets/Layout'

import {
  StyledDocumentsGeneralPageBlocks,
  StyledDocumentsGeneralPageWrapper
} from './DocumentsGeneralPage.styled'

type DocumentsGeneralPageProps = {
  titleLocale: string
  contentLocale: string
}

export const DocumentsGeneralDisplay: FC<DocumentsGeneralPageProps> = ({
  titleLocale,
  contentLocale
}) => {
  const { t } = useTranslation()

  const config = useSelector(selectConfig)

  return (
    <Layout pageTitle={{ text: titleLocale }} type={ELayoutsList.ABOUT}>
      <StyledDocumentsGeneralPageWrapper>
        <StyledDocumentsGeneralPageBlocks
          dangerouslySetInnerHTML={{
            __html: t(`${contentLocale}__${config.RULES_CDN_FOLDER}`) // to separate astrabet docs from olimp-games ones, this "locale-based" will be replaced after CMS implementation
          }}
        />
      </StyledDocumentsGeneralPageWrapper>
    </Layout>
  )
}
