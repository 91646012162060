import React from 'react'
import { useSelector } from 'react-redux'
import {
  selectBonuses,
  selectBonusesLoading,
  useGetBonuses
} from 'astra-core/containers/AccountProvider'

import { LoaderPoints } from 'shared/ui/LoaderPoints'

import { StyledHistoryWrapper } from './Bonus.styled'
import { Table } from './components/Table'

export const Bonus = () => {
  useGetBonuses()
  const bonus = useSelector(selectBonuses)
  const isLoading = useSelector(selectBonusesLoading)

  return (
    <StyledHistoryWrapper>
      {isLoading ? <LoaderPoints /> : <Table data={bonus || []} />}
    </StyledHistoryWrapper>
  )
}
