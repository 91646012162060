import React, { FC, useCallback, useState } from 'react'

import { IconChevronDown } from 'shared/ui/Icon/General/IconChevronDown'
import { EColorsNames, EColorsTypes } from 'shared/types/theme'

import {
  StyledMenu,
  StyledMenuGroupTitleWrapper,
  StyledMenuWrapper
} from './Menu.styled'
import { MenuProps, IMenuGroupItem } from './Menu.types'
import { MenuItem } from './components/MenuItem'

export const Menu: FC<MenuProps> = ({ groups, selectedId, onChange }) => (
  <StyledMenu>
    {groups.map((group) => (
      <MenuGroupItem
        group={group}
        key={group.id}
        selectedId={selectedId}
        onChange={onChange}
      />
    ))}
  </StyledMenu>
)

const MenuGroupItem: FC<IMenuGroupItem> = ({
  group: { name, methods },
  selectedId,
  onChange
}) => {
  const [isGroupShown, setIsGroupShown] = useState(true)

  const toggleGroupWindow = useCallback(() => {
    setIsGroupShown((prev) => !prev)
  }, [])

  return (
    <StyledMenuWrapper>
      <StyledMenuGroupTitleWrapper onClick={toggleGroupWindow}>
        {name}
        <IconChevronDown
          colorProps={{
            name: EColorsNames.Primary,
            value: 9,
            type: EColorsTypes.CUSTOM
          }}
          size={12}
          twist={isGroupShown ? 180 : 0}
        />
      </StyledMenuGroupTitleWrapper>
      {isGroupShown &&
        methods.map((method) => (
          <MenuItem
            key={method.id}
            method={method}
            selectedId={selectedId}
            onChange={onChange}
          />
        ))}
    </StyledMenuWrapper>
  )
}
