import React, { memo, useCallback, useEffect, useState } from 'react'
import { ETabTypes, TabTypes } from 'astra-core/containers/BalanceProvider'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'

import { ERoutes } from 'shared/types/routes'

import {
  BalanceReport,
  Payment,
  Withdrawal,
  SectionSelect,
  History,
  Bonus
} from './components'
import { StyledWrapper } from './Balance.styled'

const BalanceTabs: TabTypes = {
  [ETabTypes.Payment]: <Payment />,
  [ETabTypes.Withdraw]: <Withdrawal />,
  [ETabTypes.History]: <History />,
  [ETabTypes.Bonus]: <Bonus />
}

export const Balance = memo(() => {
  const location = useLocation()
  const [currentTab, setCurrentTab] = useState(location?.pathname)
  const history = useHistory()

  const handleChange = useCallback(
    (item) => {
      history.push(`${ERoutes.ProfileBalance}${item}`)
    },
    [history]
  )

  useEffect(() => {
    setCurrentTab(location?.pathname)
    if (location.pathname === ERoutes.ProfileBalance) {
      handleChange(ETabTypes.Payment)
    }
  }, [handleChange, history, location.pathname])

  return (
    <StyledWrapper>
      <BalanceReport />
      <SectionSelect current={currentTab} onChange={handleChange} />
      <Switch>
        {Object.keys(BalanceTabs).map((tabs) => (
          <Route
            component={() => BalanceTabs[tabs]}
            key={tabs}
            path={`${ERoutes.ProfileBalance}${tabs}`}
          />
        ))}
      </Switch>
    </StyledWrapper>
  )
})
