import React, { FC, memo, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { LayoutTheme } from 'betweb-openapi-axios'
import { useTheme } from 'styled-components'
import { getColor } from 'astra-core/utils/sportColors'
import {
  selectSport,
  ESportsCodes
} from 'astra-core/containers/CommonDataProvider'

import { RootState } from 'shared/types/store'
import { SportIcon } from 'shared/ui/Icon/SportIcon'

import { SportItemProps, SportListProps } from './SearchSportList.types'
import { StyledSportList, StyledSportsItem } from './SearchSportList.styled'

const SportItem: FC<SportItemProps> = memo(
  ({ sportId, onClickItem, isActive }) => {
    const code = useSelector((state: RootState) => selectSport(state, +sportId))
    const theme = useTheme()

    const handleClick = useCallback(
      () => onClickItem?.(sportId),
      [onClickItem, sportId]
    )

    const { main, background } = getColor(
      code?.code as ESportsCodes,
      theme.typeLayoutTheme
    )

    const mainBackgroundColor = useMemo(() => {
      if (theme.typeLayoutTheme === LayoutTheme.Dark) {
        return isActive ? theme.colors.fixed.primary[80] : 'none'
      } else {
        return isActive ? background : 'none'
      }
    }, [
      isActive,
      background,
      theme.colors.fixed.primary,
      theme.typeLayoutTheme
    ])

    return (
      <StyledSportsItem
        $active={isActive}
        backgroundColor={mainBackgroundColor}
        color={main}
        onClick={handleClick}
      >
        <SportIcon isActive={isActive} sport={code?.code as ESportsCodes} />
        {code?.name}
      </StyledSportsItem>
    )
  }
)

export const SearchSportList: FC<SportListProps> = memo(
  ({ sportList, sportId, setSportId }) => {
    const [t] = useTranslation()
    const theme = useTheme()

    const isActiveAllSport = useMemo(() => sportId === 0, [sportId])

    return (
      <StyledSportList>
        <StyledSportsItem
          backgroundColor={
            isActiveAllSport ? theme.colors.custom.primary[64] : ''
          }
          $active={isActiveAllSport}
          onClick={() => setSportId(0)}
        >
          <SportIcon isActive={isActiveAllSport} />
          {t('all sports')}
        </StyledSportsItem>
        {sportList.map((sport) => (
          <SportItem
            isActive={sportId === sport}
            key={sport}
            sportId={sport}
            onClickItem={setSportId}
          />
        ))}
      </StyledSportList>
    )
  }
)
