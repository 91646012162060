import React from 'react'
import { useGameVendorSettings } from 'astra-core'
import { VirtualGameVendor } from 'betweb-openapi-axios'

import { Layout } from 'widgets/Layout'
import { ELayoutsList } from 'widgets/Layout/Layout.types'
import { AvailablePanel } from 'shared/ui/AvailablePanel'

import { LiveGames } from './LiveGames'

export const PageGamesLive = () => {
  const isAvailable = useGameVendorSettings(VirtualGameVendor.LiveGames)
  return (
    <Layout type={ELayoutsList.GAME}>
      {isAvailable ? <LiveGames /> : <AvailablePanel />}
    </Layout>
  )
}
