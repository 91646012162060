import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  StyledSuccessPageModalHeaderWrapper,
  StyledSuccessPageModalHeaderExit,
  StyledSuccessPageTitle
} from 'widgets/Auth/components/SuccessPages/SuccessPage.styled'
import {
  StyledSuccessSignUpComponentsWrapper,
  StyledSuccessSignUp,
  StyledSuccessSignUpSubtitle
} from 'widgets/Auth/components/SuccessPages/components/SuccessSignUpComponents/SuccessSignUpComponents.styled'
import { IconSuccess } from 'shared/ui/Icon/General/IconSuccess'
import { EColorsNames, EColorsTypes } from 'shared/types/theme'
import { Modal } from 'shared/ui/modal'

import { IModalFullRegistrationStepProps } from './types'

export const ModalFullRegistrationStep = ({
  open,
  setOpen,
  text,
  description
}: IModalFullRegistrationStepProps) => {
  const [t] = useTranslation()

  return (
    <Modal
      styleContainer={{
        width: '400px',
        height: '240px',
        position: 'relative',
        padding: 0
      }}
      isOpen={open}
      showFooter
      onModalClose={() => setOpen(false)}
    >
      <StyledSuccessPageModalHeaderWrapper>
        <StyledSuccessPageModalHeaderExit
          colorProps={{
            name: EColorsNames.Primary,
            value: 30,
            type: EColorsTypes.CUSTOM
          }}
          size={10}
          onClick={() => setOpen(!open)}
        />
      </StyledSuccessPageModalHeaderWrapper>
      <StyledSuccessSignUpComponentsWrapper>
        <StyledSuccessSignUp>
          <IconSuccess size={40} />
          <StyledSuccessPageTitle>{t(text)}</StyledSuccessPageTitle>
          <StyledSuccessSignUpSubtitle>
            {t(description ?? '')}
          </StyledSuccessSignUpSubtitle>
        </StyledSuccessSignUp>
      </StyledSuccessSignUpComponentsWrapper>
    </Modal>
  )
}
