import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { columnGapPrefixes, rowGapPrefixes } from 'shared/lib/styled'

const TURBO_GAME_CARD_WIDTH = '212px'

export const StyledTurboGames = styled.div`
  column-gap: 40px;
  display: flex;
  flex-wrap: wrap;
  height: fit-content;
  margin: 0 auto;
  max-width: calc(${TURBO_GAME_CARD_WIDTH} * 4 + 145px);
  padding: 20px;
  ${columnGapPrefixes('35px')}
  ${rowGapPrefixes('40px')}
`

export const StyledTurboGamesCard = styled(Link)`
  background: ${(props) => props.theme.colors.default.primary[90]};
  border: 1px solid ${(props) => props.theme.colors.default.primary[10]};
  border-radius: 8px;
  box-shadow: 0 2px 8px ${(props) => props.theme.colors.default.primary[15]};
  height: 251px;
  overflow: hidden;
  position: relative;
  width: ${TURBO_GAME_CARD_WIDTH};
`

export const StyledTurboGamesCardTitle = styled.h3`
  background: ${(props) => props.theme.colors.default.primary[0]};
  bottom: 0;
  color: ${(props) => props.theme.colors.default.primary[90]};
  font-size: ${(props) => props.theme.fonts.size.m};
  font-weight: ${(props) => props.theme.fonts.weight.normal};
  padding: 10px 18px;
  position: absolute;
  width: 100%;
`

export const StyledTurboGamesCardImg = styled.img`
  height: calc(100% - 37px);
  left: 0;
  object-fit: cover;
  object-position: top;
  position: absolute;
  top: 0;
  width: 100%;
`
