import styled, { css } from 'styled-components'

import { customMedia } from 'shared/lib/styled'

import { IStyledCell } from './Header.types'

export const StyledHeader = styled.thead``

export const StyledHeaderRow = styled.tr``

export const StyledHeaderCell = styled.th<IStyledCell>`
  ${(props) => css`
    background-color: ${(props) => props.theme.colors.default.primary[0]};
    color: ${(props) => props.theme.colors.fixed.primary[50]};
    font-size: ${(props) => props.theme.fonts.size.s};
    font-weight: ${(props) => props.theme.fonts.weight.regular};
    padding: 10px 8px 5px;
    position: sticky;
    text-align: left;
    top: 0;
    z-index: 1;

    ${props.columnNum === 0 &&
    css`
      width: 20px;
    `}

    ${props.columnNum === 1 &&
    css`
      width: 105px;
    `}
    
    ${props.columnNum === 2 &&
    css`
      ${props.rowsLength === 0
        ? `
        min-width: 258px;
        max-width: 258px;
        ${customMedia.large} {
          min-width: 35%;
          max-width: 43%;
        }
        ${customMedia.extraLarge} {
          width: 46.5%;
        }
      `
        : `
      min-width: 258px;
      max-width: 258px;
      ${customMedia.large} {
        max-width: 514px;
      }
      ${customMedia.extraLarge} {
        min-width: 514px;
        max-width: 695px;
      }`}
    `}
    
    ${props.columnNum === 3 &&
    css`
      ${props.rowsLength === 0
        ? `
        min-width: 200px;
        ${customMedia.large} {
          width: 19%;
        }
        ${customMedia.extraLarge} {
          width: 21%;
        }
      `
        : `
      min-width: 180px;
      ${customMedia.large} {
        min-width: 200px;
      }
      ${customMedia.extraLarge} {
        min-width: 260px;
      }`}
    `}
    
    ${props.columnNum === 4 &&
    css`
      ${props.rowsLength === 0
        ? `
      width: 20%;
      ${customMedia.large} {
        width: 21.5%;
      }
      ${customMedia.extraLarge} {
        width: 21.5%;
      }
      `
        : `
      min-width: 160px;
      ${customMedia.extraLarge} {
        min-width: 252px;
      }`}
    `}
    
    ${props.columnNum === 5 &&
    css`
      width: 24px;
    `}
  `}
`
