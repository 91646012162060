import React, { FC, memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { IHistoryHeader } from './Header.types'
import {
  StyledHeaderCell,
  StyledHeader,
  StyledHeaderRow
} from './Header.styled'

export const HistoryHeader: FC<IHistoryHeader> = memo(
  ({ headerGroups, rows }) => {
    const [t] = useTranslation()
    const columnHeaderTitles = headerGroups[0]

    const filteredHeadersList = useMemo(
      () => columnHeaderTitles.headers.filter((column) => column.depth === 0),
      [columnHeaderTitles.headers]
    )
    return (
      <StyledHeader>
        <StyledHeaderRow {...columnHeaderTitles.getHeaderGroupProps()}>
          {filteredHeadersList.map((column, index) => (
            <StyledHeaderCell
              {...column.getHeaderProps()}
              columnNum={index}
              rowsLength={rows?.length || 0}
            >
              {t(`${column.render('Header')}`)}
            </StyledHeaderCell>
          ))}
        </StyledHeaderRow>
      </StyledHeader>
    )
  }
)
