import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectBetsHistoryDates,
  selectBetsHistoryBetsStatus,
  selectBetsHistoryGameVendor,
  selectBetsHistoryBetsTypes,
  betsHistoryProviderActions
} from 'astra-core/containers/BetsHistoryProvider'

export const useFetchBetsHistoryPage = () => {
  const dispatch = useDispatch()

  /*
  Refetch bets history page data on any filters change
   */

  const dates = useSelector(selectBetsHistoryDates)
  const betsStatus = useSelector(selectBetsHistoryBetsStatus)
  const gameVendor = useSelector(selectBetsHistoryGameVendor)
  const betsTypes = useSelector(selectBetsHistoryBetsTypes)

  useEffect(() => {
    dispatch(betsHistoryProviderActions.fetchHistoryBetsPage())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [betsStatus, betsTypes, dates, gameVendor, dispatch])
}
