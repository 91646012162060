import React, { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  SearchTabsType,
  selectSearchTabType
} from 'astra-core/containers/SearchProvider'

import { SearchEvents } from '../SearchEvents'
import { SearchTournaments } from '../SearchTournaments'

import { StyledSearchList } from './SearchList.styled'

const componentList = {
  [SearchTabsType.Events]: SearchEvents,
  [SearchTabsType.Tournaments]: SearchTournaments
}

export const SearchList: FC = () => {
  const type = useSelector(selectSearchTabType)
  const Component = useMemo(() => componentList[type], [type])

  return (
    <StyledSearchList>
      <Component />
    </StyledSearchList>
  )
}
