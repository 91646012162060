import {
  ETurboGamesIds,
  TTurboGamesListItem
} from 'pages/page-games-turbo/TurboGames/types'

export const TURBO_GAMES_LIST: TTurboGamesListItem[] = [
  {
    gameId: ETurboGamesIds.AVIATOR,
    iconSrc: require('assets/images/turboGames/aviator.png')
  },
  {
    gameId: ETurboGamesIds.DICE,
    iconSrc: require('assets/images/turboGames/dice.png')
  },
  {
    gameId: ETurboGamesIds.GOAL,
    iconSrc: require('assets/images/turboGames/goal.png')
  },
  {
    gameId: ETurboGamesIds.HI_LO,
    iconSrc: require('assets/images/turboGames/hilo.png')
  },
  {
    gameId: ETurboGamesIds.KENO,
    iconSrc: require('assets/images/turboGames/keno.png')
  },
  {
    gameId: ETurboGamesIds.MINE_ROULETTE,
    iconSrc: require('assets/images/turboGames/miniRoulette.png')
  },
  {
    gameId: ETurboGamesIds.MINES,
    iconSrc: require('assets/images/turboGames/mines.png')
  },
  {
    gameId: ETurboGamesIds.PLINKO,
    iconSrc: require('assets/images/turboGames/plinko.png')
  }
]
