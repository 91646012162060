import styled from 'styled-components'

import { Button } from 'shared/ui/Button'

import { SlideControl } from './SlideControl/SlideControl'

export const StyledFullRegistrationButton = styled(Button)`
  display: flex;
  font-weight: ${(props) => `${props.theme.fonts.weight.normal};`};
  justify-content: center;
  width: 100%;
`

export const StyledSlideControl = styled(SlideControl)`
  margin-bottom: 24px;
`
