import styled from 'styled-components'

import { TStyledMenuItemWrapper } from './MenuItem.types'

export const StyledMenuItemWrapper = styled.div<TStyledMenuItemWrapper>`
  align-items: center;
  background-color: ${(props) =>
    props.active ? props.theme.colors.default.primary[5] : 'transparent'};
  border-radius: 4px;
  cursor: pointer;
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: 24px 1fr;
  line-height: 16px;
  padding: 8px;
`

export const StyledMenuItemImg = styled.div``

export const StyledMenuItemDesc = styled.div`
  display: flex;
  flex-direction: column;
`
export const StyledMenuItemTitle = styled.span`
  color: ${({ theme }) => theme.colors.default.primary[90]};
  font-size: ${({ theme }) => theme.fonts.size.s};
  font-weight: ${({ theme }) => theme.fonts.weight.semiBold};
`

export const StyledMenuItemAdditionalText = styled.span`
  ${({ theme }) =>
    `
    font-size: ${theme.fonts.size.xs};
    font-weight: ${theme.fonts.weight.normal};
    color: ${theme.colors.default.primary[50]};
  `}
`
