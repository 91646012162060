import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconErrorOffline: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      fill="none"
      height="65"
      viewBox="0 0 65 65"
      width="65"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.5 8.5C0.5 4.08172 4.08172 0.5 8.5 0.5H56.5C60.9183 0.5 64.5 4.08172 64.5 8.5V56.5C64.5 60.9183 60.9183 64.5 56.5 64.5H8.5C4.08172 64.5 0.5 60.9183 0.5 56.5V8.5Z" />
      <path
        d="M47.9 15.5L40 23.4C38.2 21.8 35.7 20.8 33 20.8C28.4 20.8 24.6 23.6 23.2 27.5C19 28 15.7 31.5 15.7 35.6C15.7 38.8 17.6 41.6 20.5 42.9L14.5 48.9L15.9 50.3L49.3 16.9L47.9 15.5Z"
        fill="currentColor"
      />
      <path
        d="M43.3 30.7C43.3 29.5 43.1 28.3 42.7 27.3L26 43.9H43.3C47.1 43.9 50.2 41 50.2 37.3C50.2 33.6 47.1 30.7 43.3 30.7Z"
        fill="currentColor"
      />
    </svg>
  </Icon>
)
