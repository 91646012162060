import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { ERoutes } from 'shared/types/routes'
import { PROMOTIONS } from 'pages/page-promotion-and-bonuses/PromotionsAndBonuses/constants'

import {
  StyledPromotionBody,
  StyledPromotionBodyBannerImg,
  StyledPromotionBodyTitle,
  StyledPromotionBodyWrapper,
  StyledPromotionFooter,
  StyledPromotionFooterText,
  StyledPromotionLink
} from './PromotionsAndBonuses.styled'

export const PromotionsAndBonuses = () => {
  const [t] = useTranslation()
  const params = useParams<{ id?: string }>()

  const promo = PROMOTIONS.filter((item) => item?.id === params?.id)[0]

  return (
    <StyledPromotionBody>
      <StyledPromotionBodyTitle>{promo.label}</StyledPromotionBodyTitle>
      <StyledPromotionBodyWrapper>
        <StyledPromotionBodyBannerImg alt={promo.label} src={promo.src.large} />
      </StyledPromotionBodyWrapper>
      <StyledPromotionFooter>
        <StyledPromotionFooterText
          dangerouslySetInnerHTML={{ __html: promo.info }}
        />
      </StyledPromotionFooter>
      <StyledPromotionLink to={ERoutes.Promotions}>
        {t('promotion page')}
      </StyledPromotionLink>
    </StyledPromotionBody>
  )
}
