import React, { useCallback, useMemo, useEffect } from 'react'
import {
  searchProviderActions,
  selectFetchItemsLoading,
  selectInputSearch
} from 'astra-core/containers/SearchProvider'
import { debounce } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { EInputSize } from 'shared/ui/Input'
import { IconClose } from 'shared/ui/Icon/General/IconClose'
import { IconSearch } from 'shared/ui/Icon/General/IconSearch'
import { LoaderSpinner } from 'shared/ui/LoaderSpinner'
import { EColorsNames } from 'shared/types/theme'

import {
  StyledSearch,
  StyledSearchInput,
  StyledIconRightButton
} from '../Search.styled'

export const SearchInput = () => {
  const history = useHistory()
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const valueSearch = useSelector(selectInputSearch) || ''
  const loading = useSelector(selectFetchItemsLoading)

  useEffect(() => {
    return () => {
      history.replace({})
      dispatch(searchProviderActions.setSearch({ valueSearch: '' }))
    }
  }, [dispatch, history])

  const handlerInputSearch = useMemo(
    () =>
      debounce(
        (value) =>
          dispatch(searchProviderActions.setSearch({ valueSearch: value })),
        10
      ),
    [dispatch]
  )

  const debouncedChangeInputSearch = useCallback(
    (event) => handlerInputSearch(event.target.value),
    [handlerInputSearch]
  )

  const clearInputSearch = useCallback(
    () => dispatch(searchProviderActions.setSearch({ valueSearch: '' })),
    [dispatch]
  )

  return (
    <StyledSearch>
      <StyledSearchInput
        customSize={EInputSize.L}
        icon={IconSearch}
        placeholder={t('mainPage search placeholder')}
        rightPadding={28}
        value={valueSearch}
        onChange={debouncedChangeInputSearch}
      />
      <StyledIconRightButton onClick={clearInputSearch}>
        {!!valueSearch.length &&
          (loading ? (
            <LoaderSpinner small />
          ) : (
            <IconClose
              colorProps={{ name: EColorsNames.Primary, value: 50 }}
              size={8}
            />
          ))}
      </StyledIconRightButton>
    </StyledSearch>
  )
}
