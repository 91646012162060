import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const StyledPromotionsAndBonusesBody = styled.div`
  display: grid;
  gap: 16px 16px;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 24px;
  overflow-y: auto;
`

export const StyledItem = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr 36px;
  width: 369px;
`

export const StyledItemBanner = styled.div`
  /* background-color: ${({ theme }) => theme.colors.custom.primary[62]}; */
  align-items: center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  display: flex;
  flex-direction: column;
  height: 144px;
  justify-content: center;
  overflow: hidden;
`

export const StyledItemBannerImg = styled.img`
  height: 100%;
  object-fit: contain;
  width: 100%;
`
export const StyledItemDescription = styled.div`
  align-items: flex-start;
  border: 1px solid ${({ theme }) => theme.colors.custom.primary[8]};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
`
export const StyledItemDescriptionText = styled.div``
export const StyledItemDescriptionLabel = styled.div`
  line-height: 20px;
  margin-bottom: 8px;
  ${({ theme }) => `
    color: ${theme.colors.default.primary[90]};
    font-size: ${theme.fonts.size.l};
    font-weight: ${theme.fonts.weight.normal};
  `};
`
export const StyledItemDescriptionValue = styled.div`
  line-height: 20px;
  margin-bottom: 16px;
  min-height: 80px;
  overflow: hidden;
  ${({ theme }) => `
    color: ${theme.colors.fixed.primary[50]};
    font-size: ${theme.fonts.size.m};
    font-weight: ${theme.fonts.weight.regular};
  `};
`
export const StyledItemDescriptionMore = styled(Link)`
  border: none;
  cursor: pointer;
  outline: none;
  padding: 8px;
  text-decoration: none;

  ${({ theme }) => `
    background-color: ${theme.colors.custom.primary[4]};
    color: ${theme.colors.custom.primary[7]};
    font-size: ${theme.fonts.size.m};
    font-weight: ${theme.fonts.weight.normal};
  `};
`
