import styled from 'styled-components'

import { Tabs, TabsItem } from 'shared/ui/Tabs'

export const StyledSearchInputTabs = styled(Tabs)`
  margin-top: 12px;
`

export const StyledSearchInputTabsItem = styled(TabsItem)`
  padding: 5px 16px;

  span {
    color: ${({ theme }) => theme.colors.fixed.primary[30]};
    padding-left: 4px;
  }
`
