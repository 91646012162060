import React from 'react'
import { dayjsCore as dayjs } from 'astra-core/utils/dayjs'
import { useTranslation } from 'react-i18next'
import { ApproveStatus, PaymentStatus } from 'betweb-openapi-axios'
import { Column } from 'react-table'
import { useSelector } from 'react-redux'
import {
  selectPaymentMethods,
  selectWithdrawalMethods
} from 'astra-core/containers/BalanceProvider'

import { StyledStatus } from './Table.styled'
import { TableData } from './Table.types'

export const TABLE_COLUMNS: Column<TableData>[] = [
  {
    Header: 'date',
    accessor: 'createdAt',
    Cell: ({ value }) => <>{dayjs(value).format(DATE_CELL)}</>
  },
  {
    Header: 'identifier',
    accessor: 'transactionId'
  },
  {
    Header: 'method',
    accessor: 'methodId',
    Cell: ({ value }) => {
      const paymentMethods = useSelector(selectPaymentMethods)
      const withdrawalMethods = useSelector(selectWithdrawalMethods)
      const method = [...paymentMethods.items, ...withdrawalMethods.items].find(
        (m) => m.id === value
      )
      return <>{method ? method.name : value}</>
    }
  },
  {
    Header: 'amount',
    accessor: 'amount'
  },
  {
    Header: 'status',
    accessor: 'status',
    Cell: ({ value }) => {
      const [t] = useTranslation()
      return (
        <StyledStatus status={value!}>
          {t(`paymentStatus ${value!.toLowerCase()}`)}
        </StyledStatus>
      )
    }
  }
]

export const TABLE_DATA = [
  {
    operationType: '',
    transactionId: '123',
    userId: '123',
    accountId: '123',
    amount: 100,
    providerId: 1,
    methodId: 1,
    currencyId: 1,
    approve: ApproveStatus.Positive,
    status: PaymentStatus.Failed,
    createdAt: '2021-08-16T12:13:55.450Z',
    updatedAt: '2021-08-16T12:13:55.450Z',
    target: '123'
  },
  {
    operationType: '',
    transactionId: '123',
    userId: '123',
    accountId: '123',
    amount: 100,
    providerId: 1,
    methodId: 1,
    currencyId: 1,
    status: PaymentStatus.InProgress,
    createdAt: '2021-08-16T12:13:55.450Z',
    updatedAt: '2021-08-16T12:13:55.450Z',
    target: '123'
  },
  {
    operationType: '',
    transactionId: '123',
    userId: '123',
    accountId: '123',
    amount: 100,
    providerId: 1,
    methodId: 1,
    currencyId: 1,
    status: PaymentStatus.Success,
    createdAt: '2021-08-16T12:13:55.450Z',
    updatedAt: '2021-08-16T12:13:55.450Z',
    target: '123'
  }
]

export const DATE_CELL = 'DD.MM.YYYY HH:mm'
