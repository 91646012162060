import React, { FC } from 'react'

import { Icon } from '../Icon'
import { IconProps } from '../types'

export const IconBonuses: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg fill="none" height="18" viewBox="0 0 18 18" width="18">
      <path
        clipRule="evenodd"
        d="M14.9048 0.560264C15.1565 0.825264 15.3169 1.15803 15.405 1.52722C15.4971 1.91313 15.505 2.29718 15.3947 2.6591C15.2828 3.02663 15.0655 3.31628 14.7973 3.53189C14.2912 3.93888 13.6097 4.08089 13.0202 4.12395C12.4052 4.16887 11.7542 4.11497 11.1933 4.02068C10.6429 3.92814 10.1163 3.78608 9.76313 3.62187C9.25455 3.38541 9.09916 2.74555 9.43125 2.30195C9.96458 1.58954 11.1072 0.712695 12.2002 0.281399C12.7429 0.0672607 13.3988 -0.0882908 14.0143 0.0561251C14.3387 0.132221 14.65 0.292048 14.9048 0.560264ZM11.5105 2.47653C11.9689 2.54994 12.4664 2.58506 12.9054 2.553C13.3879 2.51776 13.6791 2.40987 13.8103 2.30436C13.8609 2.26371 13.8784 2.23145 13.888 2.20005C13.8993 2.16305 13.9154 2.07114 13.8729 1.8927C13.8352 1.73491 13.7857 1.66923 13.7626 1.64498C13.7427 1.62395 13.7147 1.60373 13.6545 1.58962C13.5011 1.55363 13.208 1.57707 12.7784 1.74659C12.34 1.91958 11.8902 2.19146 11.5105 2.47653Z"
        fill="currentColor"
        fillRule="evenodd"
      />
      <path
        d="M7.57253 5.07471C7.84867 5.07471 8.07253 5.29856 8.07253 5.57471V8.51255C8.07253 8.78869 7.84867 9.01255 7.57253 9.01255H0.984461C0.440796 9.01255 0 8.57175 0 8.02809V6.05917C0 5.5155 0.440796 5.07471 0.984461 5.07471H7.57253Z"
        fill="currentColor"
      />
      <path
        d="M10.1476 9.01255C9.8715 9.01255 9.64764 8.78869 9.64764 8.51255V5.57471C9.64764 5.29856 9.8715 5.07471 10.1476 5.07471H16.7358C17.2795 5.07471 17.7203 5.5155 17.7203 6.05917V8.02809C17.7203 8.57175 17.2795 9.01255 16.7358 9.01255H10.1476Z"
        fill="currentColor"
      />
      <path
        d="M7.57245 9.99707C7.84859 9.99707 8.07245 10.2209 8.07245 10.4971V17.3728C8.07245 17.6489 7.84859 17.8728 7.57245 17.8728H1.96884C1.42513 17.8728 0.984375 17.432 0.984375 16.8883V10.9815C0.984375 10.4378 1.42513 9.99707 1.96884 9.99707H7.57245Z"
        fill="currentColor"
      />
      <path
        d="M10.1476 17.8728C9.87144 17.8728 9.64758 17.6489 9.64758 17.3728V10.4971C9.64758 10.2209 9.87144 9.99707 10.1476 9.99707H15.7513C16.295 9.99707 16.7358 10.4378 16.7358 10.9815V16.8883C16.7358 17.432 16.295 17.8728 15.7513 17.8728H10.1476Z"
        fill="currentColor"
      />
      <path
        clipRule="evenodd"
        d="M2.31765 1.52722C2.40572 1.15803 2.56621 0.825264 2.81789 0.560264C3.07262 0.292048 3.384 0.132221 3.70833 0.0561251C4.32384 -0.0882908 4.97978 0.0672607 5.52245 0.281399C6.61547 0.712695 7.75807 1.58954 8.2914 2.30195C8.62349 2.74555 8.46811 3.38541 7.95953 3.62187C7.60636 3.78608 7.07978 3.92814 6.52933 4.02068C5.96842 4.11497 5.3175 4.16887 4.7025 4.12395C4.11301 4.08089 3.43149 3.93888 2.92533 3.53189C2.65718 3.31628 2.43988 3.02663 2.32791 2.6591C2.21765 2.29718 2.2256 1.91313 2.31765 1.52722ZM4.81724 2.553C5.25627 2.58506 5.75379 2.54994 6.21217 2.47653C5.8325 2.19146 5.3827 1.91958 4.9443 1.74659C4.5147 1.57707 4.22152 1.55363 4.06813 1.58962C4.008 1.60373 3.97998 1.62395 3.96001 1.64498C3.93698 1.66923 3.88744 1.73491 3.8498 1.8927C3.80724 2.07114 3.8234 2.16305 3.83467 2.20005C3.84424 2.23145 3.8618 2.26371 3.91235 2.30436C4.04357 2.40987 4.33475 2.51776 4.81724 2.553Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </Icon>
)
