import React, { useCallback, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Form, Formik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import {
  accountProviderActions,
  EAuthenticationSteps,
  selectAuthenticationDataStepTwo,
  selectCurrentError
} from 'astra-core/containers/AccountProvider'

import { EColorsNames, EColorsTypes } from 'shared/types/theme'
import { ERoutes } from 'shared/types/routes'

import {
  StyledFullRegistrationButton,
  StyledSlideControl
} from '../FullRegistration.styled'

import {
  StyledFullRegistrationConfirmRulesControl,
  StyledFullRegistrationConfirmRulesField,
  StyledFullRegistrationConfirmRulesText,
  StyledFullRegistrationConfirmRulesWrapper,
  StyledFullRegistrationHeaderExit,
  StyledFullRegistrationHeaderTitle,
  StyledFullRegistrationHeaderWrapper,
  StyledFullRegistrationLocaleLink,
  StyledFullRegistrationWrapper,
  StyledUserDataTitle
} from './FullRegistrationStepTwo.styled'
import { SLIDE_TWO_FORMIK_SCHEMA } from './constants/constants'

export const FullRegistrationStepTwo = () => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const authenticationDataStepTwo = useSelector(selectAuthenticationDataStepTwo)
  const currentError = useSelector(selectCurrentError)

  const closeAuthenticationModal = useCallback(() => {
    dispatch(
      accountProviderActions.changeAuthenticationCurrentStep({
        step: null
      })
    )
  }, [dispatch])

  // TODO get data fom store
  const mockSelectData = [
    {
      value: 'maiden name of mother',
      label: t('maiden name of mother')
    }
  ]

  const changeAuthenticationCurrentStep = useCallback(() => {
    dispatch(
      accountProviderActions.changeAuthenticationCurrentStep({
        step: EAuthenticationSteps.ONE
      })
    )
  }, [dispatch])

  const handleFormikSubmit = useCallback(
    (data) => {
      dispatch(accountProviderActions.updateAuthenticationDataStepTwo(data))
      dispatch(accountProviderActions.updateCurrentUser())
    },
    [dispatch]
  )

  const handleReCaptcha = useCallback(
    (reCaptchaToken) =>
      dispatch(
        accountProviderActions.setAuthenticationReCaptchaToken(reCaptchaToken)
      ),
    [dispatch]
  )
  useEffect(() => {
    if (currentError) {
      dispatch(
        accountProviderActions.changeAuthenticationCurrentStep({
          step: EAuthenticationSteps.ONE
        })
      )
    }
  }, [currentError, dispatch])

  return (
    <StyledFullRegistrationWrapper>
      <StyledFullRegistrationHeaderWrapper>
        <StyledFullRegistrationHeaderTitle>
          {t('verification')}
        </StyledFullRegistrationHeaderTitle>
        <StyledFullRegistrationHeaderExit
          colorProps={{
            name: EColorsNames.Primary,
            value: 63,
            type: EColorsTypes.CUSTOM
          }}
          size={10}
          onClick={closeAuthenticationModal}
        />
      </StyledFullRegistrationHeaderWrapper>

      <StyledUserDataTitle>{t('safety')}</StyledUserDataTitle>

      <Formik
        initialValues={authenticationDataStepTwo}
        isInitialValid={false}
        validationSchema={SLIDE_TWO_FORMIK_SCHEMA}
        onSubmit={handleFormikSubmit}
      >
        {({ values }) => (
          <Form>
            {/* <StyledFullRegistrationSelect
              value={mockSelectData[0]}
              onChange={() => {}}
              options={mockSelectData}
              isSearchable={false}
              view={ESelectView.Default}
              name="securityQuestion"
            />
            <StyledInput
              label="answer to the question"
              name="securityQuestionAnswer"
              placeholder="enter your answer"
            /> */}
            {/* <StyledFullRegistrationRefreshPicture>
              {t('refresh picture')}
            </StyledFullRegistrationRefreshPicture> */}

            <StyledFullRegistrationConfirmRulesWrapper>
              <StyledFullRegistrationConfirmRulesText>
                <Trans
                  components={[
                    <StyledFullRegistrationLocaleLink
                      target="_blank"
                      to={ERoutes.Rules}
                    />
                  ]}
                  i18nKey="confirm that read rules"
                  t={t}
                />
              </StyledFullRegistrationConfirmRulesText>
              <StyledFullRegistrationConfirmRulesField
                name="isRuleAgreement"
                type="checkbox"
              />
              <StyledFullRegistrationConfirmRulesControl />
            </StyledFullRegistrationConfirmRulesWrapper>

            <StyledSlideControl
              activeItem={1}
              pointCount={2}
              onChange={changeAuthenticationCurrentStep}
            />

            <StyledFullRegistrationButton
              disabled={!values.isRuleAgreement}
              type="submit"
            >
              {t('register')}
            </StyledFullRegistrationButton>
          </Form>
        )}
      </Formik>
    </StyledFullRegistrationWrapper>
  )
}
