import React from 'react'

import { useInjectReducer } from 'shared/lib/@reduxjs'

import { FavouriteEvents } from '../FavouriteEvents'

import { reducer, sliceKey } from './slice'

export const FavouriteEventsContainer = () => {
  useInjectReducer({ key: sliceKey, reducer })

  return <FavouriteEvents />
}
