import React, { useMemo } from 'react'
import { CompletionStatus } from 'betweb-openapi-axios'
import { useTranslation } from 'react-i18next'
import { dayjsCore as dayjs } from 'astra-core/utils/dayjs'
import { getEventTitle } from 'astra-core'

import {
  getEventStatisticScoreByPeriod,
  getEventStatisticScoreCurrent
} from 'shared/lib/statistics'
import {
  EEventStatisticScoreTypes,
  EventStatisticScore
} from 'widgets/event-statistics'
import { StyledEventStatisticScore } from 'pages/page-bets-history/BetsHistoryPageContainer/components/BetsHistory/components/History/components/Table/components/Outcome/components/BetHistoryStatistic/BetHistoryStatistic.styled'

import { RESULTS_EVENT_DATE_FORMAT } from '../constants'

// import {
//   resultsProviderActions,
//   selectIsEventInExpandedEventsStat
// } from 'astra-core/containers/ResultsProvider'

// import { RootState } from 'shared/types/store'

// import { EColorsNames } from 'shared/types/theme'

import {
  StyledEventCompetitors,
  StyledEventDate,
  StyledEventScoreWrapper,
  StyledEventStatisticsWrapper,
  StyledEventStatus,
  StyledEventWrapper,
  // StyledIconChevronDown,
  StyledLeftSide,
  StyledRightSide
} from './Events.styled'
import { TEventProps } from './Events.types'

export const Event = ({ event }: TEventProps) => {
  const [t] = useTranslation()

  const { basicStatistics, eventDate, completionStatus } = event

  // const isEventInExpandedEventsStat = useSelector((state: RootState) =>
  //   selectIsEventInExpandedEventsStat(state, id)
  // )

  const date = useMemo(
    () => dayjs(eventDate).format(RESULTS_EVENT_DATE_FORMAT(t('at'))),
    [eventDate, t]
  )

  // const toggleEventOpen = useCallback(
  //   () =>
  //     dispatch(
  //       resultsProviderActions.toggleExpandedEventStatFilter({
  //         id
  //       })
  //     ),
  //   [id, dispatch]
  // )

  const currentScore = getEventStatisticScoreCurrent(
    event?.basicStatistics?.currentScore
  )

  const scoresByPeriod = useMemo(
    () => getEventStatisticScoreByPeriod(basicStatistics?.scoresByPeriod),
    [basicStatistics?.scoresByPeriod]
  )

  const liveEventStatus = useMemo(
    () => CompletionStatus.Live === completionStatus && completionStatus,
    [completionStatus]
  )

  // const iconChevronDownColorProps = useMemo(
  //   () => ({
  //     name: EColorsNames.Primary,
  //     value: isEventInExpandedEventsStat ? 90 : 30
  //   }),
  //   [isEventInExpandedEventsStat]
  // )

  const competitors = useMemo(() => getEventTitle(event), [event])

  return (
    // <StyledEventWrapper onClick={toggleEventOpen}>
    <StyledEventWrapper>
      <StyledLeftSide>
        <StyledEventDate>{date}</StyledEventDate>
        <StyledEventCompetitors>{competitors}</StyledEventCompetitors>
      </StyledLeftSide>
      <StyledRightSide>
        <StyledEventStatisticsWrapper>
          <StyledEventStatus>{liveEventStatus}</StyledEventStatus>
          <StyledEventScoreWrapper>
            {currentScore && (
              <StyledEventStatisticScore
                type={EEventStatisticScoreTypes.DEFAULT}
                value={currentScore}
              />
            )}
            {scoresByPeriod && (
              <EventStatisticScore
                separator="&#44;&nbsp;"
                type={EEventStatisticScoreTypes.BY_PERIOD}
                value={scoresByPeriod}
              />
            )}
            {/* {isEventInExpandedEventsStat && null} */}
          </StyledEventScoreWrapper>
        </StyledEventStatisticsWrapper>

        {/* <StyledIconChevronDown
          colorProps={iconChevronDownColorProps}
          size={10}
          twist={isEventInExpandedEventsStat ? 180 : 0}
        /> */}
      </StyledRightSide>
    </StyledEventWrapper>
  )
}
