import React, { FC } from 'react'
import { Column, useTable } from 'react-table'
import { getSystemTypeForDataBetsHistory } from 'astra-core/utils/history'
import { ETestIds } from 'astra-core/utils/testIds'

import { EmptyHistoryError } from 'pages/page-bets-history/BetsHistoryPageContainer/components/BetsHistory/components/History/components/Table/components/EmptyHistoryError'

import { ITableData } from '../../History.types'

import { HistoryHeader, Rows } from './components'
import { TableMethodsContext } from './context'
import { HistoryTableRowData, ITable } from './Table.types'
import { TABLE_COLUMNS } from './constants'
import {
  StyledTable,
  StyledTableBody,
  StyledTableWrapper
} from './Table.styled'

export const Table: FC<ITable> = ({ onRowCollapse, collapsedRows, data }) => {
  const columns: Column<ITableData>[] = TABLE_COLUMNS
  const newDataAddedSystemType = getSystemTypeForDataBetsHistory(data)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns
  } = useTable<HistoryTableRowData>({ columns, data: newDataAddedSystemType })
  const isData = data.length

  return (
    <TableMethodsContext.Provider value={{ collapsedRows, onRowCollapse }}>
      <StyledTableWrapper isData={isData}>
        {isData ? (
          <StyledTable {...getTableProps()} cellSpacing="0">
            <HistoryHeader headerGroups={headerGroups} rows={rows} />

            <StyledTableBody
              {...getTableBodyProps()}
              id={ETestIds.TestBetsHistoryTableBody}
            >
              <Rows
                prepareRow={prepareRow}
                rows={rows}
                visibleColumns={visibleColumns}
              />
            </StyledTableBody>
          </StyledTable>
        ) : (
          <EmptyHistoryError />
        )}
      </StyledTableWrapper>
    </TableMethodsContext.Provider>
  )
}
