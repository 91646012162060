import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCurrentWithdrawalMethod,
  selectWithdrawalMethods,
  balanceProviderActions,
  selectWithdrawalMethodsByGroupId,
  selectWithdrawalMethodsLength,
  selectWithdrawalMethodsFetchItemsLoading,
  selectPaymentAccountsById
} from 'astra-core/containers/BalanceProvider'
import { selectIsUserWithdrawalBlocked } from 'astra-core/containers/AccountProvider'
import { MethodParameterType } from 'betweb-openapi-axios'

import { LoaderSpinner } from 'shared/ui/LoaderSpinner'
import { Notification } from 'widgets/notification'
import { useCheckActiveWithdrawalParam } from 'pages/page-balance/Balance/components/Withdrawal/hooks'
import {
  ModalFullRegistration,
  ModalSmsConfirmation,
  ModalWithdrawal,
  NotificationAbleWithdrawal
} from 'pages/page-balance/Balance/components/Withdrawal/components'

import {
  LoadingIndicatorContainer,
  StyledFinanceContent,
  StyledFinanceTitle,
  StyledFinanceWrapper
} from '../../Balance.styled'
import { CardInput } from '../CardInput'
import {
  BalancePageNotification,
  EBalanceNotification
} from '../BalancePageNotification'
import { WithdrawalForm } from '../Form'
import { Menu } from '../Menu'
import { BankCardsMethod } from '../BankCardsMethod'
import { EmptyPaymentList } from '../Payment/components/EmptyPaymentList'

export const Withdrawal = () => {
  const dispatch = useDispatch()
  const methods = useSelector(selectWithdrawalMethods)
  const withdrawalMethodsByGroupId = useSelector(
    selectWithdrawalMethodsByGroupId
  )
  const currentWithdrawal = useSelector(selectCurrentWithdrawalMethod)

  const isUserWithdrawalBlocked = useSelector(selectIsUserWithdrawalBlocked)
  const withdrawalMethodsLength = useSelector(selectWithdrawalMethodsLength)
  const isLoadingWithdrawMethods = useSelector(
    selectWithdrawalMethodsFetchItemsLoading
  )

  const paymentAccounts = useSelector(selectPaymentAccountsById)

  const handleChangeMethod = useCallback(
    (id) => {
      dispatch(balanceProviderActions.handleChangeWithdrawalMethod(id))
    },
    [dispatch]
  )

  const isActiveParamAccountKey = useCheckActiveWithdrawalParam(
    MethodParameterType.AccountKey
  )
  const isActiveParamCardNumber = useCheckActiveWithdrawalParam(
    MethodParameterType.CardNumber
  )

  if (isLoadingWithdrawMethods) {
    return (
      <LoadingIndicatorContainer>
        <LoaderSpinner />
      </LoadingIndicatorContainer>
    )
  }

  if (isUserWithdrawalBlocked) {
    return (
      <BalancePageNotification type={EBalanceNotification.ACCOUNT_BLOCKED} />
    )
  }

  const isDisplayWithdrawInfo =
    paymentAccounts && !!paymentAccounts.length && isActiveParamAccountKey

  return (
    <>
      <NotificationAbleWithdrawal />

      <ModalFullRegistration />
      <ModalWithdrawal />
      <ModalSmsConfirmation />

      {withdrawalMethodsLength ? (
        <>
          <BalancePageNotification
            type={EBalanceNotification.FULL_REGISTRATION}
          />

          <StyledFinanceWrapper>
            <Menu
              groups={withdrawalMethodsByGroupId}
              selectedId={methods.selectedId}
              onChange={handleChangeMethod}
            />

            <StyledFinanceContent>
              <StyledFinanceTitle>{currentWithdrawal?.name}</StyledFinanceTitle>

              {isDisplayWithdrawInfo && <Notification text="withdraw info" />}

              {isActiveParamCardNumber && <BankCardsMethod />}

              <CardInput />

              <WithdrawalForm />
            </StyledFinanceContent>
          </StyledFinanceWrapper>
        </>
      ) : (
        <EmptyPaymentList text="there are no available withdrawal" />
      )}
      {/* TODO use it when backend will be ready and fix dark theme */}
    </>
  )
}
