import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const StyledPromotionBody = styled.div`
  overflow-y: auto;
  padding: 12px 0;
`

export const StyledPromotionBodyBannerImg = styled.img`
  height: 100%;
  width: 100%;
`

export const StyledPromotionBodyTitle = styled.div`
  margin: 24px 0;
  ${({ theme }) => css`
    font-size: ${theme.fonts.size.xxl};
    font-weight: ${theme.fonts.weight.bold};
  `};
`

export const StyledPromotionBodyWrapper = styled.div`
  align-items: center;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  ${({ theme }) => css`
    background-color: ${theme.colors.default.primary[5]};
  `};
  overflow: hidden;
`

export const StyledPromotionFooter = styled.div`
  margin: 24px 0;
`

export const StyledPromotionFooterText = styled.div`
  line-height: 20px;

  p {
    text-align: left;
  }

  ol {
    margin-top: 10px;
  }

  li {
    list-style-type: decimal;
    margin-left: 25px;
  }
  ${({ theme }) => css`
    color: ${theme.colors.custom.primary[56]};
    font-size: ${theme.fonts.size.m};
    font-weight: ${theme.fonts.weight.regular};
  `};
`

export const StyledPromotionLink = styled(Link)`
  text-decoration: underline;
  ${({ theme }) => css`
    color: ${theme.colors.default.primary[90]};
    font-size: ${theme.fonts.size.m};
    font-weight: ${theme.fonts.weight.regular};
  `};
`
