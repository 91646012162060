import React, { FC, ReactElement, useMemo } from 'react'
import times from 'lodash/times'

import { ISlideControl } from './SlideControl.types'
import {
  StyledSlideControl,
  StyledSlideControlItem
} from './SlideControl.styled'

export const SlideControl: FC<ISlideControl> = ({
  pointCount,
  onChange,
  activeItem,
  className,
  disabled
}) => {
  const pointsList = useMemo(() => {
    const list: ReactElement[] = []

    times(pointCount, (index) => {
      list.push(
        <StyledSlideControlItem
          $isActiveItem={index === activeItem}
          disabled={disabled}
          key={index}
          onClick={() => onChange(index)}
        />
      )
    })

    return list
  }, [activeItem, disabled, onChange, pointCount])

  return (
    <StyledSlideControl className={className}>{pointsList}</StyledSlideControl>
  )
}
