import styled, { css } from 'styled-components'

export const StyledSection = styled.article`
  color: ${(props) => props.theme.colors.default.primary[90]};
  margin-bottom: 24px;
`

export const StyledSectionTitle = styled.h3`
  font-size: ${(props) => props.theme.fonts.size.m};
  font-weight: ${(props) => props.theme.fonts.weight.normal};
  line-height: 20px;
  margin: 0 0 14px;
`
export const StyledSectionWrapper = styled.div`
  display: grid;
  font-size: ${(props) => props.theme.fonts.size.s};
  font-weight: ${(props) => props.theme.fonts.weight.regular};
  grid-gap: 8px 26px;
  grid-template-columns: 0fr 1fr;
`

export const StyledSectionPasswordModalWrapper = styled.div`
  padding: 0 16px;
`

export const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
  width: 100%;
`

export const StyledSectionPasswordWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) =>
    css`
      font-size: ${theme.fonts.size.s};
      font-weight: ${theme.fonts.weight.regular};
    `}
`

export const StyledSectionItemTitle = styled.div`
  align-items: center;
  color: ${(props) => props.theme.colors.default.primary[50]};
  display: flex;
  line-height: 16px;
  white-space: nowrap;
`
export const StyledSectionData = styled.div`
  color: ${(props) => props.theme.colors.default.primary[90]};
  padding: 8px 0;
`
