import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { ERoutes } from 'shared/types/routes'
import {
  StyledTurboGames,
  StyledTurboGamesCard,
  StyledTurboGamesCardImg,
  StyledTurboGamesCardTitle
} from 'pages/page-games-turbo/TurboGames/TurboGames.styled'
import { TURBO_GAMES_LIST } from 'pages/page-games-turbo/TurboGames/constants'

import { TurboGamesCardProps } from './types'

export const TurboGames: FC = () => {
  return (
    <StyledTurboGames>
      {TURBO_GAMES_LIST.map((game) => (
        <TurboGamesCard data={game} key={game.gameId} />
      ))}
    </StyledTurboGames>
  )
}

export const TurboGamesCard: FC<TurboGamesCardProps> = ({ data }) => {
  const [t] = useTranslation()

  return (
    <StyledTurboGamesCard to={`${ERoutes.TurboGames}/${data.gameId}`}>
      <StyledTurboGamesCardImg src={data.iconSrc} />

      <StyledTurboGamesCardTitle>
        {t(`turbo game ${data.gameId}`)}
      </StyledTurboGamesCardTitle>
    </StyledTurboGamesCard>
  )
}
