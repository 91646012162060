import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import dayjs from 'dayjs'
import { useGetCurrencyIcon } from 'astra-core'
import { useSelector } from 'react-redux'
import { selectLoyaltyByFilter } from 'astra-core/containers/AccountProvider'

import { IconWatch } from 'shared/ui/Icon/General/IconWatch'
import { getHintAttrNameFormatted } from 'constants/styles/components/hintAttr/utils'
import { COUPON_DATE_FORMAT } from 'shared/lib/bonusCoupons'
import { getFormattedBonusCouponAmount } from 'shared/lib/basket'

import { EPBTabs } from '../../BonusCouponsBody.types'

import {
  StyledCouponsWrapper,
  StyledCouponImageWrapper,
  StyledCouponBottom,
  StyledCouponWrapper,
  StyledCouponDateWrapper,
  StyledCouponBody,
  StyledCouponDateTill,
  StyledCouponDateLeft,
  StyledCouponBodyTitle,
  StyledCouponBodyDescription,
  StyledCouponDateLeftWrapper,
  StyledCouponBottomText,
  StyledCouponLeftEllipse,
  StyledCouponRightEllipse
} from './Coupons.styled'
import { ICoupon } from './Coupons.types'
import { getLocaleByStatus } from './constants'

export const Coupons = () => {
  const loyaltyByFilter = useSelector(selectLoyaltyByFilter)

  return (
    <StyledCouponsWrapper>
      {loyaltyByFilter.map((coupon) => (
        <Coupon key={coupon.id} {...coupon} />
      ))}
    </StyledCouponsWrapper>
  )
}

export const Coupon: FC<ICoupon> = ({
  id,
  status,
  expiredAt,
  possibleAmount,
  coupon: { name, description, amount }
}) => {
  const [t] = useTranslation()
  const { pathname } = useLocation()
  const currencyIcon = useGetCurrencyIcon()

  const couponDate = dayjs(expiredAt).format(COUPON_DATE_FORMAT)
  const couponTitle = getFormattedBonusCouponAmount(
    name,
    possibleAmount ?? amount,
    currencyIcon
  )

  const localeByStatus = status
    ? getLocaleByStatus(t, couponDate)[status]
    : t('expired')

  const hintAttr = getHintAttrNameFormatted(couponDate)

  return (
    <StyledCouponWrapper>
      <StyledCouponImageWrapper />

      <StyledCouponBody>
        <StyledCouponDateWrapper>
          <StyledCouponDateTill status={status}>
            {localeByStatus}
          </StyledCouponDateTill>

          <StyledCouponDateLeftWrapper>
            <IconWatch />
            <StyledCouponDateLeft {...hintAttr}>
              {couponDate}
            </StyledCouponDateLeft>
          </StyledCouponDateLeftWrapper>
        </StyledCouponDateWrapper>

        <StyledCouponBodyTitle>{couponTitle}</StyledCouponBodyTitle>

        <StyledCouponBodyDescription>{description}</StyledCouponBodyDescription>
      </StyledCouponBody>

      <StyledCouponBottom
        to={{
          pathname: `${pathname}/${id}`,
          state: { currentTab: EPBTabs.BONUS_COUPONS }
        }}
      >
        <StyledCouponLeftEllipse />
        <StyledCouponRightEllipse />
        <StyledCouponBottomText>{t('more')}</StyledCouponBottomText>
      </StyledCouponBottom>
    </StyledCouponWrapper>
  )
}
