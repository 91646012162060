import React from 'react'
import { VirtualGameVendor } from 'betweb-openapi-axios'
import { useGameVendorSettings } from 'astra-core'

import { Layout } from 'widgets/Layout'
import { ELayoutsList } from 'widgets/Layout/Layout.types'
import { AvailablePanel } from 'shared/ui/AvailablePanel'

import { BetGames } from './BetGames'

export const PageGamesBet = () => {
  const isAvailable = useGameVendorSettings(VirtualGameVendor.BetGames)

  return (
    <Layout type={ELayoutsList.GAME}>
      {isAvailable ? <BetGames /> : <AvailablePanel />}
    </Layout>
  )
}
