import React, { memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  betsHistoryProviderActions,
  selectBetsHistoryBetsStatus
} from 'astra-core/containers/BetsHistoryProvider'
import { ETestIds } from 'astra-core/utils/testIds'

import { EButtonSizes, EButtonViews } from 'shared/ui/Button'

import { SECTION_SELECT_OPTIONS } from './constants'
import { StyledSection, StyledSectionItem } from './SectionSelect.styled'

export const SectionSelect = memo(() => {
  const [t] = useTranslation()
  const betsStatus = useSelector(selectBetsHistoryBetsStatus)
  const dispatch = useDispatch()

  const handleClick = useCallback(
    (value) => () => {
      if (betsStatus !== value) {
        dispatch(betsHistoryProviderActions.setFilterBetStatus(value))
      }
    },
    [dispatch, betsStatus]
  )

  return (
    <StyledSection>
      {SECTION_SELECT_OPTIONS.map((item) => (
        <StyledSectionItem
          icon={item.icon?.component}
          iconProps={item.icon?.iconProps}
          id={`${ETestIds.TestBetsHistoryTableTabs}-${item.value}`}
          isActiveItem={betsStatus === item.value}
          key={item.title}
          size={EButtonSizes.S}
          view={EButtonViews.NONE}
          onClick={handleClick(item.value)}
        >
          {t(item.title)}
        </StyledSectionItem>
      ))}
    </StyledSection>
  )
})
