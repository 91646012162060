import styled from 'styled-components'

import { IconBonuses } from 'shared/ui/Icon/General/IconBonuses'
import { IconPromotions } from 'shared/ui/Icon/General/IconPromotions'

export const StyledIconBonuses = styled(IconBonuses)`
  margin-right: 8px;
`
export const StyledIconPromotions = styled(IconPromotions)`
  margin-right: 8px;
`
