import React, { FC, Fragment, memo } from 'react'
import { useTranslation } from 'react-i18next'

import { IProfileSection } from './Section.types'
import {
  StyledSection,
  StyledSectionData,
  StyledSectionItemTitle,
  StyledSectionTitle,
  StyledSectionWrapper
} from './Section.styled'

export const Section: FC<IProfileSection> = memo(
  ({ title, data, children }) => {
    const [t] = useTranslation()

    return (
      <StyledSection>
        <StyledSectionTitle>{t(title)}</StyledSectionTitle>

        {children || (
          <StyledSectionWrapper>
            {data?.map((item) => (
              <Fragment key={item.title}>
                <StyledSectionItemTitle>{t(item.title)}</StyledSectionItemTitle>
                {item.component || (
                  <StyledSectionData>
                    {!!item.value && t(item.value)}
                  </StyledSectionData>
                )}
              </Fragment>
            ))}
          </StyledSectionWrapper>
        )}
      </StyledSection>
    )
  }
)
