import styled from 'styled-components'

import { InputForm } from 'shared/ui/InputForm'

export const StyledWithdrawalFormMethodsTitle = styled.div`
  line-height: 20px;
  margin-bottom: 4px;

  ${({ theme }) => `
    color: ${theme.colors.default.primary[50]};
    font-size: ${theme.fonts.size.m};
    font-weight: ${theme.fonts.weight.regular};
  `};
`

export const StyledWithdrawalMethodInput = styled(InputForm)`
  margin-bottom: 16px;
  width: 164px;
`
