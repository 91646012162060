import React, { memo } from 'react'
import { useTranslation } from 'react-i18next'

import {
  StyledReloadBtn,
  StyledErrorContainer,
  StyledErrorDescr,
  StyledErrorTitle,
  StyledIconError
} from './OfflinePage.styled'

export const OfflinePage = memo(() => {
  const [t] = useTranslation()
  const handleReload = () => {
    window.location.reload()
  }

  return (
    <StyledErrorContainer>
      <StyledIconError size={64} />
      <StyledErrorTitle>{t('no internet')}</StyledErrorTitle>
      <StyledErrorDescr>{t('check your internet')}</StyledErrorDescr>
      <StyledReloadBtn onClick={handleReload}>{t('update')}</StyledReloadBtn>
    </StyledErrorContainer>
  )
})
