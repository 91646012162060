import { ETabTypes } from 'astra-core/containers/BalanceProvider'

export const TABS = [
  {
    name: ETabTypes.Payment,
    title: 'top up balance'
  },
  {
    name: ETabTypes.Withdraw,
    title: 'withdraw funds'
  },
  {
    name: ETabTypes.History,
    title: 'operations history'
  },
  {
    name: ETabTypes.Bonus,
    title: 'bonus account'
  }
]
